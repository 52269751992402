import React, { useEffect, useState } from "react";
import {
	Box,
	FormControl,
	OutlinedInput,
	Chip,
	MenuItem,
	Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import { PropTypes } from "./MarketplaceSelector.types";

type Marketplace = {
	id: number;
	name: string;
};

const marketplaces = [
	{ id: 1, name: "United States" },
	{ id: 3, name: "United Kingdom" },
	{ id: 4, name: "Germany" },
	{ id: 35691, name: "Italy" },
	{ id: 44551, name: "Spain" },
];

export default function MarketplaceSelector({
	index,
	setSelectedElements,
	selectedElements,
	removeElement,
	selectedMarketplaces,
}: PropTypes) {
	const [firstTime, setFirstTime] = useState(true);

	const [selectedMarketplace, setSelectedMarketplace] = useState<Marketplace[]>(
		[]
	);
	useEffect(() => {
		if (
			selectedMarketplaces &&
			selectedMarketplaces.length > 0 &&
			selectedElements &&
			selectedElements.length > 0 &&
			firstTime
		) {
			setFirstTime(false);
			const initialMarketplaces = marketplaces.filter((marketplace) =>
				selectedMarketplaces?.includes(marketplace.id)
			);
			setSelectedMarketplace(initialMarketplaces);
			const auxSelectedElements = [...selectedElements];
			auxSelectedElements[index].marketplaces = initialMarketplaces;
			setSelectedElements(auxSelectedElements);
		}
	}, [
		index,
		selectedElements,
		selectedMarketplaces,
		setSelectedElements,
		firstTime,
	]);

	return (
		<FormControl
			sx={{
				width: "100%",
			}}
		>
			<Select
				displayEmpty
				multiple
				value={selectedMarketplace.map((marketplace) => marketplace.name)}
				onChange={(event) => {
					const {
						target: { value },
					} = event;
					const names = typeof value === "string" ? value.split(",") : value;

					if (names.includes("NoCertify")) {
						removeElement(index);
					} else {
						const newMarketplaces = marketplaces.filter((marketplace) =>
							names.includes(marketplace.name)
						);
						setSelectedMarketplace(newMarketplaces);
						const auxSelectedElements = [...selectedElements];
						auxSelectedElements[index].marketplaces = newMarketplaces;
						setSelectedElements(auxSelectedElements);
					}
				}}
				sx={{
					flex: 1,
					width: "100%",
					color: "#000000",
					backgroundColor: "#ffffff",
					"& .MuiSelect-select": {
						backgroundColor: "#ffffff!important",
						color: "#000000",
						padding: "7px",
					},
					"& .MuiSelect-placeholder": {
						color: "#757575",
						backgroundColor: "#ffffff",
					},
					"& fieldset": {
						border: "1px solid #E4E4E4",
						borderRadius: "8px",
					},
				}}
				input={<OutlinedInput />}
				renderValue={(selectedNames) => (
					<Box
						sx={{
							display: "flex",
							gap: 0.5,
							overflow: "auto",
							minHeight: 32,
							alignItems: "center",
						}}
					>
						{selectedNames.length > 0 ? (
							selectedNames.map((name) => (
								<Chip
									key={name}
									label={name}
									sx={{
										height: 26,
										fontSize: 12,
										borderRadius: "20px",
									}}
								/>
							))
						) : (
							<Typography
								color="textSecondary"
								sx={{ fontSize: 14, color: "#EB4B4B" }}
							>
								Select One Or More Marketplaces
							</Typography>
						)}
					</Box>
				)}
				MenuProps={{
					PaperProps: {
						style: {},
					},
				}}
			>
				{marketplaces.map((marketplace) => (
					<MenuItem key={marketplace.id} value={marketplace.name}>
						{marketplace.name}
					</MenuItem>
				))}
				<MenuItem value="NoCertify">Do Not Certify</MenuItem>
			</Select>
		</FormControl>
	);
}
