import { useContext, useState, useEffect } from "react";
import { PropTypes } from "./LCAActionBar.types";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BasicModal } from "../BasicModal";
import { DeleteConfirmation } from "../DeleteConfirmation";
import {
	ICertification,
	ICompany,
	IEstimation,
	IGetCertifiedStep,
	ILCA,
	IProduct,
} from "../../models";
import {
	product as productActions,
	company as companyActions,
	certification as certificationActions,
	estimation as estimationsActions,
} from "../../controllers";
import { useMutation, useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import ChooseProvider from "../ChooseProvider/ChooseProvider";

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

const debounce = <F extends (...args: any[]) => void>(
	func: F,
	delay: number
): ((...args: Parameters<F>) => void) => {
	let timer: NodeJS.Timeout | null = null;
	return (...args: Parameters<F>): void => {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

export function LCAActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
}: PropTypes) {
	const navigate = useNavigate();
	const [showDelete, setShowDelete] = useState(false);
	const { user } = useContext(UserContext);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");
	const [providerTypes] = useState<string[] | undefined>(undefined);

	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const debouncedSearchChange = debounce(handleSearchChange, 500);

	const handleDeleteModalClose = () => {
		setShowDelete(false);
	};

	const deleteItem = async () => {
		for (const selectedRow of selectedRows) {
			await productActions.delete(
				selectedRow as IProduct,
				!user?.is_superuser ? user?.company.id : undefined
			);
		}
		handleDeleteModalClose();
		refetch();
	};

	const decertifyItem = async () => {
		for (const selectedRow of selectedRows) {
			await productActions.update({
				id: selectedRow.id,
				companyId: user?.company.id,
				decertifying_request: new Date().toISOString(),
			});
		}
		handleDeleteModalClose();
		refetch();
	};

	const getSelectionRowsTitle = () => {
		let title = "";
		selectedRows.forEach((selectedRow) => {
			title =
				(title.length > 0
					? title + ", " + (selectedRow as ILCA).title
					: (selectedRow as ILCA).title) ?? "";
		});

		return title;
	};

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies-filter",
		() => companyActions.list(companiesSearch, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	const { refetch: refetchClimecoCertifications } = useQuery(
		"all-certifications",
		() =>
			certificationActions.list(
				false,
				user?.is_superuser ? undefined : user?.company.id,
				undefined,
				filters.companies
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { mutate: createEstimationMutate } = useMutation(
		"start-estimations",
		({
			estimation,
			certification,
		}: {
			estimation: IEstimation;
			certification: ICertification;
		}) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess: certification!.id,
				companyId: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate } = useMutation(
		"certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo:
					selectedProvider?.certification_type ===
					"CLIMECO_INTERNAL_CERTIFICATION",
				name: "New",
				certification: selectedProvider?.id,
				companyId: (selectedRows[0] as IProduct).company!.id,
			}),
		{
			retry: false,
		}
	);

	useEffect(() => {
		setFilters((prev) => ({ ...prev, certifications: [] }));
		refetchClimecoCertifications();
	}, [refetchClimecoCertifications, filters.companies, setFilters]);

	const [showProviderModal, setShowProviderModal] = useState(false);

	const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
		null
	);

	const handleProvider = () => {
		if (
			selectedProvider?.certification_type === "CLIMECO_INTERNAL_CERTIFICATION"
		) {
			mutate(null, {
				onSuccess: (res) => {
					// create estimations
					let index = 0;
					const createEstimation = (
						id: number,
						amount: number,
						//emissions: number,
						product: IProduct,
						index: number
					) => {
						createEstimationMutate(
							{
								estimation: {
									id: id,
									amount: amount,
									//emissions: emissions,
									product: product,
								},
								certification: res,
							},
							{
								onSuccess: () => {
									index += 1;
									if (index < selectedRows.length) {
										createEstimation(
											index,
											0,
											//0,
											selectedRows[index] as IProduct,
											index
										);
									} else {
										navigate("/climeco-certificationss/" + res.id);
									}
								},
								onError: (error) => {
									const errorObj = error as any;
									let message = "Something went wrong.";
									if (
										(errorObj["response"]["data"] as Array<any>).length > 0 &&
										(errorObj["response"]["data"] as Array<any>)[0] ===
											"The product is outdated"
									) {
										message =
											"One of the products can't be certified as it is outdated or with an invalid LCA document.";
									}

									enqueueSnackbar(message, {
										variant: "error",
									});
								},
							}
						);
					};

					createEstimation(
						index,
						0,
						//0,
						selectedRows[index] as IProduct,
						index
					);
				},
				onError: (error) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", {
						variant: "error",
					});
				},
			});
		} else {
			navigate("/certifications/create", {
				state: { products: selectedRows as IProduct[] },
			});
		}
	};

	return (
		<Box sx={{ alignItems: "stretch", display: "flex", width: "100%" }}>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					alignItems: "stretch",
					justifyContent: "space-between",
					flexDirection: {
						xs: "column",
						sm: "column",
						md: "column",
						lg: "column-reverse",
					},
					gap: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					marginTop: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							sm: "column",
							md: "column",
							lg: "row",
						},
						flex: 1,
						alignItems: {
							xs: "flex-start",
							sm: "flex-start",
							md: "flex-start",
							lg: "flex-end",
						},
						justifyContent: "flex-start",
						marginTop: "9px",
						alignSelf: "flex-start",
						gap: 2,
						width: "100%",
					}}
				>
					{filters.companies && (
						<Autocomplete
							disablePortal
							id="company-box"
							options={companies}
							sx={{
								width: {
									xs: 240,
									sm: 240,
									md: 240,
									lg: 240,
									xl: 300,
								},
								"& .MuiInputBase-root": {
									padding: "0px 0px 0px 8px",
									overflow: "auto",
									maxHeight: "38px",
								},
							}}
							multiple
							getOptionLabel={(company) => company?.name ?? ""}
							/*value={filters.companies!.map((company) =>
								companies.find((auxCompany) => auxCompany.id === company)
							)}*/
							onChange={(_, value) => {
								setFilters((prev) => ({
									...prev,
									companies: value.map((company) => company!.id),
								}));
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									placeholder="Company"
									sx={{
										maxHeight: 56,
										overflow: "auto!important",
										border: "1px solid #ececec",
										borderRadius: 2,
										backgroundColor: "#fff",
										"& input": {
											fontSize: 14,
										},
									}}
									onChange={(ev) => {
										// dont fire API if the user delete or not entered anything
										if (ev.target.value !== "" || ev.target.value !== null) {
											debouncedSearchChange(ev.target.value);
										}
									}}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{isLoadingCompanies ? (
													<CircularProgress color="inherit" size={20} />
												) : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					)}
					{/*<Autocomplete
						disablePortal
						id="certification-box"
						options={climecoCertifications}
						getOptionLabel={(certification) => certification?.name ?? ""}
						value={filters.certifications!.map((certification) =>
							climecoCertifications.find(
								(climecoCertification) =>
									climecoCertification.id === certification
							)
						)}
						onChange={(_, value) => {
							setFilters((prev) => ({
								...prev,
								certifications: value.map((certification) => certification!.id),
							}));
						}}
						sx={{
							width: {
								xs: 240,
								sm: 240,
								md: 240,
								lg: 240,
								xl: 300,
							},
							"& .MuiInputBase-root": {
								padding: "0px 0px 0px 8px",
								overflow: "auto",
								maxHeight: "38px",
							},
						}}
						multiple
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder="Certification"
								sx={{
									maxHeight: 56,
									overflow: "auto!important",
									border: "1px solid #ececec",
									borderRadius: 2,
									backgroundColor: "#fff",
									"& input": {
										fontSize: 14,
									},
								}}
							/>
						)}
					/>*/}
				</Box>

				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "center",
						justifyContent: "flex-end",
						flexDirection: {
							xs: "row",
							sm: "row",
							md: "row",
							lg: "row",
						},
						gap: {
							xs: 0.5,
							sm: 0.5,
							md: 0.5,
							lg: 0.5,
						},
						marginTop: {
							xs: 0,
							sm: 0,
							md: 0,
							lg: 0,
						},
						width: "100%",
					}}
				>
					{selectedRows && selectedRows.length ? (
						<>
							{user?.is_superuser ? (
								<Button
									variant="outlined"
									sx={{ marginRight: 2, maxWidth: "200px" }}
									onClick={() => {
										setShowDelete(true);
									}}
								>
									Delete
								</Button>
							) : (
								<Button
									variant="outlined"
									sx={{ marginRight: 2, maxWidth: "200px" }}
									disabled={
										selectedRows.find((row) => {
											return (
												(row as IProduct).certification_processes?.length === 0
											);
										})
											? true
											: false
									}
									onClick={() => {
										setShowDelete(true);
									}}
								>
									Decertifying
								</Button>
							)}
						</>
					) : (
						<></>
					)}

					<Button
						variant="outlined"
						sx={{ marginLeft: 1 }}
						onClick={() => {
							navigate("/lca", {
								state: {
									initialStep: IGetCertifiedStep.ADD_LCAS,
								},
							});
						}}
					>
						Add LCA(s)
					</Button>
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showDelete}
				handleClose={handleDeleteModalClose}
				children={
					<DeleteConfirmation
						title={getSelectionRowsTitle()}
						handleDeleteModalClose={handleDeleteModalClose}
						callback={!user?.is_superuser ? decertifyItem : deleteItem}
						decertifying={!user?.is_superuser}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showProviderModal}
				handleClose={() => {
					setShowProviderModal(false);
				}}
				children={
					<ChooseProvider
						selectedProvider={selectedProvider}
						setSelectedProvider={setSelectedProvider}
						mutate={handleProvider}
						providerTypes={providerTypes}
					/>
				}
			/>
		</Box>
	);
}
