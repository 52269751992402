import { useState, useEffect, useContext } from "react";
import { Typography, Box, CircularProgress, Button } from "@mui/material";
import { PropTypes } from "./Dashboard.types";
import { Heading } from "../Heading";
import { Updates } from "../Updates";
import { useMutation, useQuery } from "react-query";
import {
	//service as serviceActions,
	notification as notificationActions,
	certification as certificationActions,
	company as companyActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { ICertification, INotification, IUser } from "../../models";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

//import { CompanyAnalytics } from "../CompanyAnalytics";
import { AdminAnalytics } from "../AdminAnalytics";
import { BasicModal } from "../BasicModal";
import ConfettiExplosion from "react-confetti-explosion";
import InitialStep from "../InitialStep/InitialStep";
import { QuickActions } from "../QuickActions";
import { TbGraph } from "react-icons/tb";
import { TiDocumentAdd } from "react-icons/ti";
import { MyApplications } from "../MyApplications";

export function Dashboard(props: PropTypes) {
	const { user, setUser } = useContext(UserContext);

	const [notifications, setNotifications] = useState<INotification[] | null>(
		null
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [hasAmazonCertificationTodo, setHasAmazonCertificationTodo] = useState<
		boolean | null
	>(null);
	const [showHistoricallyApprovedModal, setShowHistoricallyApprovedModal] =
		useState(false);
	/*const [showWaitingConfirmationModal, setShowWaitingConfirmationModal] =
		useState(false);*/
	const [showIframe, setShowIframe] = useState(false);
	const [firstTime, setFirstTime] = useState<boolean | null>(null);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);

	const { isLoading, refetch: refetchNotifications } = useQuery(
		"notifications",
		() => notificationActions.list(5, user?.company.id, undefined, true),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setNotifications(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const {
		refetch: refetchClimecoCertifications,
		isLoading: isLoadingClimecoCertifications,
	} = useQuery(
		"certifications-climeco-dashboard",
		() =>
			certificationActions.list(
				true,
				user?.company.id,
				undefined,
				undefined,
				undefined,
				undefined,
				10,
				false
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setFirstTime(res.results.length > 0 ? false : true);
				const lcas = Array<number>();
				setCertifications(
					res.results.filter((certification) => {
						const foundLCA = lcas.find((lca) =>
							certification.lca_versions?.find(
								(auxLCAVersion) => auxLCAVersion.lca_id === lca
							)
						);

						if (
							foundLCA === undefined &&
							certification.lca_versions &&
							certification.lca_versions.length > 0
						) {
							lcas.push(certification.lca_versions![0].lca_id ?? -1);
						}

						return !foundLCA;
					})
				);
				const foundCompletedCertification = res.results.find(
					(c) => c.status === "COMPLETED" && !c.is_historical
				)
					? true
					: false;

				setHasAmazonCertificationTodo(foundCompletedCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { mutate: mutateSeenHistoricalCompany } = useMutation(
		"seen-historical-company",
		() =>
			companyActions.update({
				id: user?.company!.id!,
				seen_historically_approved_message: new Date(),
			}),
		{
			retry: false,
			onSuccess: () => {
				const auxUser = { ...user };
				auxUser.company!.seen_historically_approved_message = new Date();
				setUser(auxUser as IUser);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.company?.id) {
			refetchNotifications();
			refetchClimecoCertifications();
		}
	}, [user, refetchNotifications, refetchClimecoCertifications]);

	/*useEffect(() => {
		refetchServices();
	}, [refetchServices]);*/

	/*useEffect(() => {
		if (
			user?.company?.requested_historically_approved &&
			!user?.company?.historically_approved
		) {
			setShowWaitingConfirmationModal(true);
		} else if (
			!user?.company?.seen_historically_approved_message &&
			user?.company?.historically_approved
		) {
			setShowHistoricallyApprovedModal(true);
		}
	}, [user]);*/

	return (
		<Box>
			{firstTime === null ? (
				<Box
					sx={{
						display: "flex",
						padding: 4,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : firstTime && user && !user.is_superuser ? (
				<Box sx={{ width: { xs: 400, md: 800 } }}>
					<InitialStep />
				</Box>
			) : (
				<>
					{!user?.is_superuser && (
						<>
							<Heading
								title="Quick Actions"
								subtitle=""
								showSearch={false}
								noMarginBottom
							/>
							<Box
								sx={{
									maxWidth: 800,
									width: "100%",
									display: "flex",
									flexDirection: { xs: "column", sm: "row" },
									gap: 2,
									marginBottom: 4,
								}}
							>
								<QuickActions
									title="Get Certified"
									description="Apply to get your products ClimeCo certified to then be eligible for our partner programs, such as Amazon's Climate Pledge Friendly program"
									buttonTitle="Get Certified"
									icon={<TiDocumentAdd size={26} />}
									callback={() => navigate("/lca")}
									buttonType="contained"
								/>
								<QuickActions
									title="Stay Certified"
									description="Update your product’s estimated sales with actual sales data and supporting evidence to maintain certifications."
									buttonTitle="Update Product Sales"
									icon={<TbGraph size={24} />}
									callback={() => navigate("/sales")}
									buttonType="outlined"
								/>
							</Box>
						</>
					)}

					{certifications && certifications.length > 0 && (
						<>
							<Heading
								title="My Latest ClimeCo Applications"
								subtitle=""
								showSearch={false}
								noMarginBottom
							/>

							<MyApplications certifications={certifications} />
						</>
					)}
					<Heading
						title="Updates"
						subtitle=""
						showSearch={false}
						noMarginBottom
					/>
					<Box
						sx={{
							backgroundColor: "#fff",
							marginBottom: 8,
							maxWidth: "800px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{isLoading || isLoadingClimecoCertifications ? (
							<CircularProgress size={24} />
						) : notifications && notifications.length > 0 ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										flexWrap: "wrap",
									}}
								>
									{notifications.slice(0, 3).map((notification) => {
										return (
											<Updates
												key={notification.id}
												notification={notification}
												callback={() => {
													setNotifications((prev) => {
														const auxNotifications = [...prev!];
														const index = auxNotifications.findIndex(
															(n) => n.id === notification.id
														);
														if (index > -1) {
															auxNotifications.splice(index, 1);
														}
														return auxNotifications;
													});
													refetchNotifications();
												}}
												productsLength={0}
											/>
										);
									})}
								</Box>
								{notifications.length > 2 && (
									<Typography
										variant="body2"
										onClick={() => {
											navigate("/notifications/");
										}}
										sx={{
											textAlign: "right",
											color: "#34918E",
											fontWeight: "bold",
											marginBottom: 4,
											marginTop: 1,
											fontSize: 12,
											cursor: "pointer",
											"&:hover": { textDecoration: "underline" },
										}}
									>
										See more
									</Typography>
								)}
							</Box>
						) : hasAmazonCertificationTodo ? (
							<Updates
								key={-1}
								notification={{
									id: -1,
									title: "Apply for Amazon CPF certification",
									message: "",
									notification_type: "START_EXTERNAL_CERTIFICATION",
									status: "",
									created_at: new Date().toISOString(),
									users: [],
									product_id: null,
									certification_process_id: null,
									lca_version: null,
									sale_id: null,
									button_title: "Apply",
								}}
								callback={() => {}}
								hideCheckbox={true}
								productsLength={0}
							/>
						) : (
							<Updates
								key={-1}
								notification={{
									id: -1,
									title:
										"Click start to begin the process of getting your products certified",
									message: "",
									notification_type: "ADD_LCA",
									status: "",
									created_at: new Date().toISOString(),
									users: [],
									product_id: null,
									certification_process_id: null,
									lca_version: null,
									sale_id: null,
									button_title: "Start",
								}}
								callback={() => {}}
								hideCheckbox={true}
								productsLength={0}
							/>
						)}
					</Box>
				</>
			)}

			{user && user.is_superuser ? (
				<Box sx={{ marginTop: 2, marginBottom: 6 }}>
					<AdminAnalytics title="Platform management" />
				</Box> /*: productsLength > 0 ? (
				<Box sx={{ marginTop: 2, marginBottom: 6 }}>
					<CompanyAnalytics title={"Account management"} />
				</Box>
			)*/
			) : null}

			{/*hasCompletedClimecoCertification &&
				services &&
				services?.findIndex(
					(service) => user?.is_superuser || service.visible
				) > -1 && (
					<>
						<Heading
							title="More from ClimeCo"
							showSearch={false}
							noMarginBottom
						/>
						<Grid container spacing={2}>
							{services &&
								services
									.filter((service) => user?.is_superuser || service.visible)
									.map((service) => {
										return (
											<Grid key={service.id} item xs={12} sm={6} md={6} lg={4}>
												<ServiceCard service={service} />
											</Grid>
										);
									})}
						</Grid>
					</>
				)*/}

			{/*<BasicModal
				width={600}
				showModal={showWaitingConfirmationModal}
				handleClose={() => {}}
				hideClose
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							Hold tight...
						</Typography>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
						>
							A ClimeCo representative will now review your historical product
							data. Please expect an email from us in 2-3 business days. Please
							stay tuned for updates on when you can upload new products via
							this portal.. Once your historical data is verified, you can start
							adding and certifying new products along with updating your sales
							numbers and managing your offset inventory in our ClimeCo product
							portal.
						</Typography>
						<Divider sx={{ marginTop: "40px", marginBottom: "40px" }} />
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<Typography
								variant="body2"
								sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
							>
								<small>
									While waiting, click{" "}
									<a
										href="#logout"
										onClick={(e) => {
											e.preventDefault();
											connectionsClient.updateTokens({
												access: null,
												refresh: null,
												userId: null,
											});
											setUser(null);
											navigate("/");
											window.location.href = window.location.origin;
										}}
										style={{ color: "#25406d" }}
									>
										here if you need to logout
									</a>
								</small>
							</Typography>
						</Box>
					</Box>
				}
			/>*/}

			<BasicModal
				width={600}
				showModal={showHistoricallyApprovedModal}
				handleClose={() => {
					setShowHistoricallyApprovedModal(false);
				}}
				hideClose
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{
								fontWeight: 700,
								textAlign: "center",
								marginBottom: 2,
							}}
						>
							Welcome!
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<ConfettiExplosion zIndex={1400} />
						</Box>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginTop: 2, marginBottom: 2 }}
						>
							Thank you for adding your historical information. Welcome to
							ClimeCo's new product portal where you can add products, certify
							those products, report sales and manage offset inventory. The goal
							is to make certifying and decarbonizing products easy for you, so
							please provide feedback or ideas on how we can do that.
							<br />
							<br />
							If you would like to have a ClimeCo team member walk you through
							the portal or answer any questions about this program or ClimeCo
							in general, here is a {""}
							<a
								style={{ color: "#25406D", cursor: "pointer", fontWeight: 700 }}
								href={
									"https://outlook.office365.com/owa/calendar/ProductCertCustomers@climeco.com/bookings/"
								}
								onClick={(e) => {
									e.preventDefault();
									setShowIframe(true);
								}}
							>
								link
							</a>{" "}
							where you can schedule time.
							<br />
							<br />
							We are here to help you in your decarbonization journey!
						</Typography>
						{showIframe && (
							// eslint-disable-next-line jsx-a11y/iframe-has-title
							<iframe
								src="https://outlook.office365.com/owa/calendar/ProductCertCustomers@climeco.com/bookings/"
								width="100%"
								height="500px"
								scrolling="yes"
								style={{ border: "0" }}
							></iframe>
						)}
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									minHeight: 34,
								}}
								onClick={() => {
									setShowHistoricallyApprovedModal(false);
									mutateSeenHistoricalCompany();
								}}
							>
								OK
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
