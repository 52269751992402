import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { TiDocumentAdd as AddIcon } from "react-icons/ti";
import { TiEdit as EditIcon } from "react-icons/ti";
import { PropTypes } from "./GetCertifiedActions.types";
import { lca as lcaActions } from "../../controllers";
import { useQuery } from "react-query";
import { UserContext } from "../../contexts/user";
import { ILCA } from "../../models";

export default function GetCertifiedActions({
	selectedOption,
	setSelectedOption,
}: PropTypes) {
	const handleSelectOption = (option: string) => {
		setSelectedOption(option);
	};
	const { user } = useContext(UserContext);
	const [LCAs, setLCAs] = useState<Array<ILCA>>([]);

	const { refetch } = useQuery(
		"lcas",
		() => lcaActions.list(!user?.is_superuser ? user!.company.id : undefined),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCAs(res.results);
			},
			onError: (error: any) => {
				console.log(error);
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return (
		<Box>
			<Box
				sx={{
					display: "flex",
					gap: 3,
					padding: 6,
					paddingBottom: "20px",
					justifyContent: LCAs && LCAs.length > 0 ? "start" : "center",
				}}
			>
				<Button
					variant="outlined"
					onClick={() => handleSelectOption("newLCA")}
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "start",
						padding: 3,
						borderRadius: "16px",
						paddingTop: 6,
						border: "none!important",
						outline:
							(selectedOption === "newLCA"
								? "2px solid #34918E"
								: "1px solid #B1D1F0") + "!important",
						flex: 1,
						"&:hover": {
							outline: "2px solid #34918E!important",
							backgroundColor: "transparent",
							border: "none!important",
						},
						maxWidth: 300,
					}}
				>
					<AddIcon style={{ fontSize: 24, color: "#000" }} />
					<Typography
						variant="body2"
						sx={{
							marginTop: 1,
							fontWeight: "bold",
							color: "#000",
							textTransform: "none",
							textAlign: "left",
						}}
					>
						I want to add a new LCA
					</Typography>
				</Button>

				{LCAs && LCAs.length > 0 && (
					<Button
						variant="outlined"
						onClick={() => handleSelectOption("existingLCA")}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "start",
							padding: 2,
							borderRadius: "16px",
							paddingTop: 6,
							border: "none!important",
							outline:
								(selectedOption === "existingLCA"
									? "2px solid #34918E"
									: "1px solid #B1D1F0") + "!important",
							flex: 1,
							"&:hover": {
								outline: "2px solid #34918E!important",
								backgroundColor: "transparent",
								border: "none!important",
							},
						}}
					>
						<EditIcon style={{ fontSize: 24, color: "#000" }} />
						<Typography
							variant="body2"
							sx={{
								marginTop: 1,
								fontWeight: "bold",
								color: "#000",
								textTransform: "none",
							}}
						>
							I want to update an existing LCA
						</Typography>
					</Button>
				)}
			</Box>
		</Box>
	);
}
