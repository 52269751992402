import { useCallback, useContext, useEffect, useState } from "react";
import { PropTypes } from "./CreateLCAsTable.types";
import {
	ICompany,
	IProduct,
	ILCA,
	ILCAVersion,
	IGetCertifiedStep,
} from "../../models";
import {
	Box,
	TextField,
	Button,
	CircularProgress,
	Typography,
	Autocomplete,
	Tooltip,
	IconButton,
} from "@mui/material";
import { UploadFile } from "../UploadFile";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import {
	certification as certificationActions,
	company as companyActions,
	lca as lcaActions,
	lcaVersion as lcaVersionActions,
} from "../../controllers";
import { ICertification } from "../../models";
import { BasicModal } from "../BasicModal";
import { LCARecommendations } from "../LCARecommendations";
import InfoIcon from "@mui/icons-material/Info";
import { NumericFormat } from "react-number-format";

export function CreateLCAsTable({
	inModal,
	inModalCallback,
	selectedLCA,
	setShowLCAInfo,
	readMode,
	index,
	step,
	setCurrentStep,
	rows,
	setRows,
	defaultLCA,
	createLCAs,
	setCreateLCAs,
	setNextButtonLoading,
	visible = true,
}: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [dateType, setDateType] = useState(["text"]);
	const [certifying, setCertifying] = useState(false);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
	const [loading, setLoading] = useState(false);
	const [showLCARecommendations, setLCARecommendations] = useState(false);
	const [showLCARejectedModal, setShowLCARejectedModal] = useState(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [rejectedReason, setRejectedReason] = useState<string | null>(null);
	const [wrongDates, setWrongDates] = useState<Array<number>>([]);

	const handleAddInput = () => {
		setRows([
			...rows,
			{
				id: new Date().getTime(),
				status: "PENDING" as "PENDING",
				title: "",
				lca_document: undefined,
				certification_date: "",
				lca_provider: "",
				number_of_products: undefined,
				products: new Array<IProduct>(),
				product_category: undefined,
				lca_standards: undefined,
				certification_processes: new Array<ICertification>(),
			},
		]);
	};

	useEffect(() => {
		if (selectedLCA) {
			if (user?.is_superuser && selectedLCA.company) {
				setSelectedCompany(selectedLCA.company);
			}
			if (selectedLCA.lca_versions && selectedLCA.lca_versions.length > 0) {
				const currentIndex = index ?? 0;
				setRows([
					{
						id: selectedLCA.lca_versions[currentIndex].id,
						status: selectedLCA.lca_versions[currentIndex].status,
						title: selectedLCA.lca_versions[currentIndex].title,
						lca_document: selectedLCA.lca_versions[currentIndex].lca_document,
						certification_date:
							selectedLCA.lca_versions[currentIndex].certification_date?.split(
								"T"
							)[0],
						lca_provider: selectedLCA.lca_versions[currentIndex].lca_provider,
						number_of_products:
							selectedLCA.lca_versions[currentIndex].number_of_products,
						products: selectedLCA.lca_versions[currentIndex].products,
						product_category:
							selectedLCA.lca_versions[currentIndex].product_category,
						lca_standards: selectedLCA.lca_versions[currentIndex].lca_standards,
						certification_processes: new Array<ICertification>(),
						certification_process:
							selectedLCA.lca_versions[currentIndex].certification_process,
					},
				]);
				if (currentIndex === 0) {
					const auxRejectedReason =
						selectedLCA.lca_versions[currentIndex].reject_reason ?? null;

					setRejectedReason(auxRejectedReason);

					if (!readMode) {
						if (auxRejectedReason) {
							setShowLCARejectedModal(true);
							if (setShowLCAInfo) {
								setShowLCAInfo(false);
							}
						} else if (setShowLCAInfo) {
							setShowLCAInfo(true);
						}
					}
				}
			}
		} else {
			setSelectedCompany(null);
			//setRows([{ ...defaultLCA }]);
			setRejectedReason(null);
			if (!readMode && setShowLCAInfo) {
				setShowLCAInfo(false);
			}
		}
	}, [
		selectedLCA,
		setShowLCAInfo,
		user?.is_superuser,
		index,
		readMode,
		defaultLCA,
		setRows,
	]);

	const { isLoading, mutate } = useMutation(
		"bulk-lcas",
		({ lca }: { lca: ILCA }) =>
			lcaActions.create(
				lca!.title!,
				user?.is_superuser ? selectedCompany?.id ?? 0 : user?.company.id ?? 0
			),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingLCAVersion, mutate: mutateLCAVersion } =
		useMutation(
			"bulk-lca-versions",
			({ lcaVersion, lca }: { lcaVersion: ILCAVersion; lca: ILCA }) =>
				lcaVersionActions.create(
					lcaVersion.title!,
					lcaVersion.lca_document,
					lcaVersion.lca_provider!,
					lcaVersion.certification_date!,
					lcaVersion.number_of_products!,
					lcaVersion.lca_standards!,
					lcaVersion.product_category!,
					user?.is_superuser ? selectedCompany?.id ?? 0 : user?.company.id ?? 0,
					lca.id
				),
			{
				retry: false,
			}
		);

	const { mutate: createCertification } = useMutation(
		"certification",
		({
			lcaVersions,
			companyId,
		}: {
			lcaVersions: Array<number>;
			companyId: number;
		}) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: companyId,
				isHistorical: false,
				lcaVersions: lcaVersions,
			}),
		{
			retry: false,
		}
	);

	const filteredRows = rows.filter(
		(r) =>
			r.title &&
			r.lca_document &&
			r.certification_date &&
			r.lca_provider &&
			r.number_of_products
	);

	const bulkLCAs = useCallback(
		(certify: boolean) => {
			setLoading(true);
			let index = 0;
			let lcaVersions = Array<number>();
			const createLCAVersion = (
				lcaVersion: ILCAVersion,
				index: number,
				lca: ILCA
			) => {
				return new Promise((resolve, reject) => {
					mutateLCAVersion(
						{
							lcaVersion,
							lca,
						},
						{
							onSuccess: (res) => {
								resolve(res.id);
							},
							onError: (error: any) => {
								setLoading(false);
								enqueueSnackbar(
									`Something went wrong while uploading the LCA ${lcaVersion.title}. Please retry it or contact support`,
									{
										variant: "error",
									}
								);
								reject(-1);
							},
						}
					);
				});
			};
			const createLCA = async (lcaVersion: ILCAVersion, index: number) => {
				const lca: ILCA = {
					id: -1,
					title: lcaVersion.title,
				};
				if (!selectedLCA) {
					mutate(
						{
							lca: lca,
						},
						{
							onSuccess: async (res) => {
								const auxRows = [...filteredRows];
								auxRows[index].id = res.id;
								lca.id = res.id;
								setRows(auxRows);
								index += 1;
								const lcaVersionId = await createLCAVersion(
									lcaVersion,
									index,
									selectedLCA || lca
								);
								lcaVersions.push(lcaVersionId as number);

								if (index < filteredRows.length) {
									createLCA(filteredRows[index], index);
								} else {
									if (certify) {
										// create certification
										// add current LCAs if needed
										createCertification(
											{
												lcaVersions: lcaVersions,
												companyId: user?.is_superuser
													? selectedCompany?.id ?? 0
													: user?.company.id ?? 0,
											},
											{
												onSuccess: (res) => {
													/*if (setCurrentStep) {
													setCurrentStep((currentStep: number) => {
														return currentStep + 1;
													});
												}
												if (setCertificationProcessId) {
													setCertificationProcessId(res.id);
												}*/
													navigate("/climeco-certificationss/" + res.id);
												},
												onError: (error) => {
													setLoading(false);
													console.log(error);
													enqueueSnackbar("Something went wrong.", {
														variant: "error",
													});
												},
											}
										);
									} else {
										setLoading(false);
										enqueueSnackbar("LCA created successfully.", {
											variant: "success",
										});
										if (inModal) {
											setTimeout(() => {
												inModalCallback && inModalCallback(rows);
											}, 500);
										} else {
											navigate("/lcas");
										}
									}
								}
							},
							onError: (error: any) => {
								enqueueSnackbar("Something went wrong.", {
									variant: "error",
								});

								index += 1;
								if (index < filteredRows.length) {
									createLCA(filteredRows[index], index);
								} else {
									setCertifying(false);
									setLoading(false);
									enqueueSnackbar("LCA created successfully.", {
										variant: "success",
									});
									navigate("/lcas");
								}
							},
						}
					);
				} else {
					const lcaVersionId = await createLCAVersion(
						lcaVersion,
						index,
						selectedLCA || lca
					);

					index += 1;

					if (certify) {
						// create certification
						createCertification(
							{
								lcaVersions: [lcaVersionId as number],
								companyId: user?.is_superuser
									? selectedCompany?.id ?? 0
									: user?.company.id ?? 0,
							},
							{
								onSuccess: (res) => {
									/*if (setCurrentStep) {
									setCurrentStep((currentStep: number) => {
										return currentStep + 1;
									});
								}
								if (setCertificationProcessId) {
									setCertificationProcessId(res.id);
								}*/
									navigate("/climeco-certificationss/" + res.id);
								},
								onError: (error) => {
									setLoading(false);
									console.log(error);
									enqueueSnackbar("Something went wrong.", {
										variant: "error",
									});
								},
							}
						);
					} else {
						setLoading(false);
						enqueueSnackbar("LCA created successfully.", {
							variant: "success",
						});
						if (inModal) {
							setTimeout(() => {
								inModalCallback && inModalCallback(rows);
							}, 500);
						} else {
							navigate("/lcas");
						}
					}
				}
			};

			createLCA(filteredRows[index], index);
		},
		[
			createCertification,
			enqueueSnackbar,
			filteredRows,
			inModal,
			inModalCallback,
			mutate,
			mutateLCAVersion,
			navigate,
			rows,
			selectedCompany?.id,
			selectedLCA,
			setRows,
			user?.company.id,
			user?.is_superuser,
		]
	);

	const { refetch: refetchCompanies } = useQuery(
		"companies",
		() => companyActions.list(undefined, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const addFourYears = (dateString?: string) => {
		if (dateString) {
			const inputDate = new Date(dateString);

			// Get the year, month, and day from the input date
			const year = inputDate.getUTCFullYear();
			const month = inputDate.getUTCMonth();
			const day = inputDate.getUTCDate();

			return `${year + 4}-${("0" + String(month + 1)).slice(-2)}-${(
				"0" + String(day)
			).slice(-2)}`;
		} else {
			return "";
		}
	};

	useEffect(() => {
		if (user?.is_superuser) {
			refetchCompanies();
		}
	}, [refetchCompanies, user?.is_superuser]);

	const numberOfRejections = selectedLCA
		? (selectedLCA?.lca_versions ?? []).filter(
				(lcaVersion) =>
					lcaVersion.rejected_date && !lcaVersion.rejected_date_paid
		  ).length
		: 0;

	const intToOrdinalNumberString = (num: number): string => {
		num = Math.round(num);
		let numString = num.toString();
		// If the ten's place is 1, the suffix is always "th" (e.g., 11th, 12th, 13th)
		if (Math.floor(num / 10) % 10 === 1) {
			return numString + "th";
		}
		// Otherwise, the suffix depends on the one's place
		switch (num % 10) {
			case 1:
				return numString + "st";
			case 2:
				return numString + "nd";
			case 3:
				return numString + "rd";
			default:
				return numString + "th";
		}
	};

	useEffect(() => {
		if (createLCAs) {
			setCreateLCAs(false);

			// if something changed
			// TO DO: Check current version is not either before approve or completed
			// Otherwise show a message saying this can't be done until the current certification gets completed
			if (!selectedLCA) {
				if (!step || (step && step === IGetCertifiedStep.LCA_LIST)) {
					bulkLCAs(true);
				} else if (setCurrentStep) {
					setCurrentStep((currentStep: number) => {
						return currentStep + 1;
					});
				}
			} else if (
				selectedLCA &&
				selectedLCA.lca_versions &&
				((rejectedReason &&
					(selectedLCA.lca_versions[0].lca_document !== rows[0].lca_document ||
						selectedLCA.lca_versions[0].certification_date?.split("T")[0] !==
							rows[0].certification_date?.split("T")[0])) ||
					(!rejectedReason &&
						selectedLCA.lca_versions[0].lca_document !== rows[0].lca_document &&
						selectedLCA.lca_versions[0].certification_date?.split("T")[0] !==
							rows[0].certification_date?.split("T")[0]))
			) {
				setShowConfirmationModal(true);
			} else if (
				selectedLCA &&
				selectedLCA.lca_versions &&
				((rejectedReason &&
					selectedLCA.lca_versions[0].lca_document === rows[0].lca_document &&
					selectedLCA.lca_versions[0].certification_date?.split("T")[0] ===
						rows[0].certification_date?.split("T")[0]) ||
					(!rejectedReason &&
						((selectedLCA.lca_versions[0].lca_document !==
							rows[0].lca_document &&
							selectedLCA.lca_versions[0].certification_date?.split("T")[0] ===
								rows[0].certification_date?.split("T")[0]) ||
							(selectedLCA.lca_versions[0].lca_document ===
								rows[0].lca_document &&
								rows[0].lca_document &&
								selectedLCA.lca_versions[0].certification_date?.split(
									"T"
								)[0] !== rows[0].certification_date?.split("T")[0]))))
			) {
				enqueueSnackbar(
					rejectedReason
						? "Change at least the document or the certification date to fix a rejection"
						: "Change at least the document and certification date to start a new revision",
					{ variant: "error" }
				);
			} else if (
				selectedLCA &&
				selectedLCA.lca_versions &&
				selectedLCA.lca_versions.length > 0 &&
				selectedLCA.lca_versions[0].certification_process
			) {
				navigate(
					"/climeco-certificationss/" +
						selectedLCA.lca_versions[0].certification_process.id
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		//bulkLCAs,
		createLCAs,
		/*enqueueSnackbar,
		rejectedReason,
		rows,
		selectedLCA,
		setCreateLCAs,
		setCurrentStep,
		step,*/
	]);

	useEffect(() => {
		setNextButtonLoading(isLoading || isLoadingLCAVersion || loading);
	}, [isLoading, isLoadingLCAVersion, loading, setNextButtonLoading]);

	const currentIndex = index ?? 0;

	return (
		<Box
			sx={{
				height: visible ? "auto" : 0,
				maxHeight: "100%",
				width: "100%",
				gap: 2,
				padding: { xs: "20px 20px", md: "20px 60px" },
				visibility: visible ? "visible" : "hidden",
			}}
		>
			{rows.map((row, index) => (
				<Box
					key={row.id}
					sx={{
						visibility: currentIndex === index ? "visible" : "hidden",
						height: currentIndex === index ? "auto" : 0,
					}}
				>
					<Box
						key={row.id}
						sx={{
							padding: 2,
							//marginBottom: 4,
							display: "flex",
							visibility:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.ADD_LCAS))
									? "visible"
									: "hidden",
							height:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.ADD_LCAS))
									? "auto"
									: 0,
							flexDirection: "column",
							gap: 1,
							overflow: "auto",
							maxHeight: 400,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
								minWidth: 200,
								flex: 1,
								justifyContent: "flex-start",
								alignItems: "flex-start",
							}}
						>
							<span
								style={{
									textTransform: "capitalize",
									fontSize: 14,
									marginBottom: "10px",
									fontWeight: 700,
								}}
							>
								Name your LCA<span style={{ color: "#B61313" }}>*</span>
							</span>
							<TextField
								autoFocus
								placeholder="LCA Title"
								sx={{
									display: "flex",
									alignSelf: "start",
									width: "100%",
								}}
								value={row.title}
								onChange={(event) => {
									const auxRows = [...rows];
									auxRows[index].title = event.target.value;
									setRows(auxRows);
								}}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flex: 1,
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									LCA Provider<span style={{ color: "#B61313" }}>*</span>
								</span>
								<Tooltip
									title="You can type any provider, the options are just suggestions"
									arrow
								>
									<Autocomplete
										freeSolo
										options={[
											"Planet FWD",
											"WAP Sustainability",
											"Carbon Pass",
											"Sustainabase",
											"Aspire Sustainability",
											"Zero Carbon Industry Operations Center (Shenzhen) Co., Ltd",
											"Shenzhen Zhongrui Standard Technical Services Co., Ltd",
										]}
										value={row.lca_provider || ""}
										onChange={(event, newValue) => {
											let providerValue;
											if (typeof newValue === "string") {
												providerValue = newValue;
											} else {
												providerValue = newValue ? newValue : "";
											}

											const auxRows = [...rows];
											auxRows[index].lca_provider = providerValue;
											setRows(auxRows);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label=""
												placeholder="LCA Provider"
												onChange={(event) => {
													const auxRows = [...rows];
													auxRows[index].lca_provider = event.target.value;
													setRows(auxRows);
												}}
											/>
										)}
										sx={{ flex: 1, width: "100%" }}
									/>
								</Tooltip>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									Applied LCA Standards
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<Autocomplete
									clearIcon={null}
									options={[
										"GHG Product Protocol",
										"ISO 14040",
										"ISO 14044",
										"ISO 14046",
										"ISO 14067",
										"ISO 14068",
										"PAS 2050",
										"PAS 2060",
										"OTHER",
									]}
									multiple
									value={row.lca_standards || []}
									onChange={(event, newValue) => {
										let providerValue;
										if (typeof newValue === "object") {
											providerValue = newValue;
										} else {
											providerValue = newValue ? newValue : [];
										}

										const auxRows = [...rows];
										auxRows[index].lca_standards = providerValue;
										setRows(auxRows);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label=""
											placeholder="Applied LCA Standards"
										/>
									)}
									sx={{
										flex: 1,
										width: "100%",
										"& .MuiAutocomplete-inputRoot": {
											padding: "0px 11.5px",
										},
									}}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flex: 1,
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									Product Category<span style={{ color: "#B61313" }}>*</span>
								</span>
								<Autocomplete
									options={[
										"BEAUTY PRODUCTS",
										"HEALTH PRODUCTS",
										"COOKING PRODUCTS",
										"FOOD",
										"BABY CARE",
										"PET CARE",
										"FASHION",
										"TOYS",
										"ELECTRONICS",
										"ACCESSORY",
										"HOME/OFFICE DECOR",
										"OTHER",
									]}
									value={row.product_category || ""}
									getOptionLabel={(option) => {
										const capitalizeFirstLetterOfEachWord = (
											input: string
										): string => {
											return input
												.split(" ")
												.map(
													(word) =>
														word.charAt(0).toUpperCase() +
														word.slice(1).toLowerCase()
												)
												.join(" ");
										};
										return capitalizeFirstLetterOfEachWord(option);
									}}
									onChange={(event, newValue) => {
										let providerValue;
										if (typeof newValue === "string") {
											providerValue = newValue;
										} else {
											providerValue = newValue ? newValue : "";
										}

										const auxRows = [...rows];
										auxRows[index].product_category = providerValue;
										setRows(auxRows);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label=""
											placeholder="Product Category"
											onChange={(event) => {
												const auxRows = [...rows];
												auxRows[index].product_category = event.target.value;
												setRows(auxRows);
											}}
										/>
									)}
									sx={{ flex: 1, width: "100%" }}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									Total Products in LCA
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<NumericFormat
									name={"number_of_products"}
									placeholder="# of products on LCA"
									customInput={TextField}
									type="text"
									thousandSeparator={","}
									suffix={""}
									allowNegative={false}
									value={row.number_of_products}
									onValueChange={({ value }) => {
										const auxRows = [...rows];
										auxRows[index].number_of_products = parseFloat(value);
										setRows(auxRows);
									}}
									style={{
										flex: 1,
										width: "100%",
										marginRight: 2,
									}}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								flex: 1,
								flexDirection: { xs: "column", md: "row" },
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginTop: 8,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									Date Completed
									<span style={{ color: "#B61313" }}>*</span>
								</span>
								<TextField
									placeholder={"LCA Completion Date"}
									type={dateType[index]}
									variant="outlined"
									InputProps={{
										inputProps: {
											max: `${new Date().toISOString().split("T")[0]}`,
											min: "2021-01-01",
											style: { minWidth: "150px" },
										},
									}}
									sx={{
										flex: 1,
										width: "100%",
										minWidth: "150px",
										border:
											wrongDates.findIndex((d) => d === row.id) > -1
												? "1px solid #eb4343"
												: undefined,
										borderRadius:
											wrongDates.findIndex((d) => d === row.id) > -1 ? 1 : 0,
									}}
									value={row.certification_date}
									onChange={(event) => {
										const auxRows = [...rows];
										let certificationDate = event.target.value;
										try {
											const inputDate = new Date(event.target.value);
											if (inputDate > new Date()) {
												certificationDate = new Date()
													.toISOString()
													.split("T")[0];
											}
										} catch (error) {
											console.log(error);
										}

										auxRows[index].certification_date = certificationDate;
										setRows(auxRows);
									}}
									onFocus={() => {
										const auxDateType = [...dateType];
										auxDateType[index] = "date";
										setDateType(auxDateType);
									}}
									onBlur={() => {
										if (!row.certification_date) {
											const auxDateType = [...dateType];
											auxDateType[index] = "text";
											setDateType(auxDateType);
										} else {
											const auxRows = [...rows];
											const auxWrongDates = [...wrongDates];
											try {
												const inputDate = new Date(row.certification_date);
												if (inputDate < new Date("2021-01-01")) {
													auxRows[index].certification_date = "";
													if (
														!auxWrongDates.find((d) => d === auxRows[index].id)
													) {
														auxWrongDates.push(auxRows[index].id);
														setWrongDates(auxWrongDates);
													}
													setRows(auxRows);
												} else {
													const found = auxWrongDates.findIndex(
														(d) => d === auxRows[index].id
													);
													if (found > -1) {
														auxWrongDates.splice(found, 1);
														setWrongDates(auxWrongDates);
													}
												}
											} catch (error) {
												console.log(error);
												if (
													!auxWrongDates.find((d) => d === auxRows[index].id)
												) {
													auxWrongDates.push(auxRows[index].id);
													setWrongDates(auxWrongDates);
												}
											}
										}
									}}
								/>
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 1,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									LCA Expiration Date
									<span style={{ color: "#B61313" }}>*</span>
									<Tooltip
										sx={{ padding: 0 }}
										title={
											"Autofills based on LCA completion date. According to our program requirements, LCAs expire after 4 years. You can update LCAs via your LCA Library"
										}
									>
										<IconButton sx={{ height: 12 }}>
											<InfoIcon sx={{ fontSize: 14 }} />
										</IconButton>
									</Tooltip>
								</span>
								<TextField
									placeholder={"LCA Expiration Date"}
									type={dateType[index]}
									variant="outlined"
									InputProps={{
										inputProps: {
											max: `${new Date().toISOString().split("T")[0]}`,
											style: { minWidth: "150px" },
										},
									}}
									sx={{
										flex: 1,
										minWidth: "150px",
										width: "100%",
									}}
									value={addFourYears(row.certification_date)}
									disabled
								/>
							</Box>
						</Box>
						{user?.is_superuser && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 2,
									flex: 1,
									justifyContent: "flex-start",
									alignItems: "flex-start",
								}}
							>
								<span
									style={{
										textTransform: "capitalize",
										fontSize: 14,
										marginBottom: "10px",
										fontWeight: 700,
									}}
								>
									Companies
								</span>
								<Autocomplete
									disablePortal
									id="companies"
									options={companies}
									value={
										companies.find(
											(company) => company.id === selectedCompany?.id
										) || null
									}
									getOptionLabel={(option) => option.name}
									renderOption={(props, option) => {
										return (
											<li {...props} key={option.id} style={{ minWidth: 200 }}>
												{option.name}
											</li>
										);
									}}
									onChange={(_, value) => {
										if (value) {
											const auxRows = [...rows];
											setSelectedCompany(value);
											setRows(auxRows);
										}
									}}
									renderInput={(params) => (
										<TextField
											sx={{
												display: "flex",
												alignSelf: "start",
											}}
											placeholder="Company"
											{...params}
											variant="outlined"
										/>
									)}
									sx={{ flex: 1, width: "100%" }}
								/>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							visibility:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.UPLOAD_LCAS))
									? "visible"
									: "hidden",
							height:
								currentIndex === index &&
								(!step || (step && step === IGetCertifiedStep.UPLOAD_LCAS))
									? "auto"
									: 0,
							flexDirection: "column",
							gap: 2,
							overflow: "hidden",
							alignSelf: "start",
							flex: 1,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								gap: 2,
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									minWidth: 200,
									marginTop: 2,
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										justifyContent: "flex-start",
										alignItems: "flex-start",
									}}
								>
									<span
										style={{
											textTransform: "capitalize",
											fontSize: 14,
											marginBottom: "10px",
											fontWeight: 700,
										}}
									>
										Upload Your LCA<span style={{ color: "#B61313" }}>*</span>
									</span>
									<Box sx={{ width: { sx: 300, md: 500 } }}>
										<UploadFile
											key={row.id}
											padding={-1}
											flex={1}
											accept=".pdf,.docx,.doc"
											value={row.lca_document}
											onFileChange={(file) => {
												//if (file) {
												const auxRows = [...rows];
												auxRows[index].lca_document = file ?? undefined;
												setRows(auxRows);
												//}
											}}
											id={row.id}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>

					{/*(readMode || selectedLCA) && (
						<TableFooter
							sx={{
								visibility: collapsed && readMode ? "collapse" : "visible",
							}}
						>
							<TableRow>
								<TableCell colSpan={3}>
									<Typography variant="body1" sx={{ marginBottom: 1 }}>
										Products in this version:
									</Typography>
									{!selectedLCA?.lca_versions ||
									(selectedLCA?.lca_versions &&
										selectedLCA?.lca_versions[index ?? 0] &&
										(selectedLCA?.lca_versions![index ?? 0].products ?? [])
											.length === 0) ? (
										<Typography variant="body2">
											<b>No products yet</b>
										</Typography>
									) : (
										selectedLCA?.lca_versions &&
										selectedLCA?.lca_versions[index ?? 0] &&
										selectedLCA?.lca_versions![index ?? 0].products?.map(
											(product) => (
												<Box sx={{ display: "flex", gap: 2 }} key={product.id}>
													<Typography variant="body2">
														<b>{product.name}</b>
													</Typography>
													{(product.asin || product.upc) && (
														<Typography variant="body2">
															{" - "}
															{product.asin || product.upc || ""}
														</Typography>
													)}
												</Box>
											)
										)
									)}
								</TableCell>
							</TableRow>
						</TableFooter>
					)*/}
				</Box>
			))}

			{false && !readMode && (
				<Box
					sx={{
						marginBottom: 2,
						minHeight: 34,
						minWidth: 120,
						display: "flex",
						alignItems: "center",
						justifyContent: selectedLCA ? "space-between" : "flex-end",
						gap: 2,
					}}
				>
					{selectedLCA && <Typography variant="h6">Current Version</Typography>}
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						{selectedLCA && (
							<Button
								variant="outlined"
								sx={{ lineHeight: 1, minHeight: 34 }}
								onClick={() => {
									navigate(
										"/climeco-certificationss/" +
											selectedLCA!.lca_versions![0].certification_process!.id
									);
								}}
							>
								Go to current certification
							</Button>
						)}
						{(!selectedLCA ||
							(selectedLCA &&
								(rejectedReason ||
									(selectedLCA!.lca_versions &&
										selectedLCA!.lca_versions![0]?.certification_process
											?.status === "COMPLETED")))) && (
							<Button
								variant="contained"
								sx={{ lineHeight: 1, minHeight: 34 }}
								onClick={() => {
									// if something changed
									// TO DO: Check current version is not either before approve or completed
									// Otherwise show a message saying this can't be done until the current certification gets completed
									if (!selectedLCA) {
										if (
											!step ||
											(step && step === IGetCertifiedStep.UPLOAD_LCAS)
										) {
											bulkLCAs(true);
										} else if (setCurrentStep) {
											setCurrentStep((currentStep: number) => {
												return currentStep + 1;
											});
										}
									} else if (
										selectedLCA &&
										selectedLCA.lca_versions &&
										((rejectedReason &&
											(selectedLCA.lca_versions[0].lca_document !==
												rows[0].lca_document ||
												selectedLCA.lca_versions[0].certification_date?.split(
													"T"
												)[0] !== rows[0].certification_date?.split("T")[0])) ||
											(!rejectedReason &&
												selectedLCA.lca_versions[0].lca_document !==
													rows[0].lca_document &&
												selectedLCA.lca_versions[0].certification_date?.split(
													"T"
												)[0] !== rows[0].certification_date?.split("T")[0]))
									) {
										setShowConfirmationModal(true);
									} else {
										enqueueSnackbar(
											rejectedReason
												? "Change at least the document or the certification date to fix a rejection"
												: "Change at least the document and certification date to start a new revision",
											{ variant: "error" }
										);
									}
								}}
								disabled={
									rows.find((r, i) => {
										const isValid = (dateString: string): boolean => {
											return !isNaN(Date.parse(dateString));
										};

										return (
											(!r.title ||
												((!step ||
													(step && step === IGetCertifiedStep.UPLOAD_LCAS)) &&
													!r.lca_document) ||
												!r.certification_date ||
												(r.certification_date &&
													(!isValid(r.certification_date) ||
														new Date(r.certification_date).getFullYear() <
															2021)) ||
												!r.lca_provider ||
												!r.number_of_products ||
												!r.product_category ||
												!r.lca_standards) &&
											!(
												!r.title &&
												(!step ||
													(step && step === IGetCertifiedStep.UPLOAD_LCAS)) &&
												!r.lca_document &&
												(!r.certification_date ||
													(r.certification_date &&
														(!isValid(r.certification_date) ||
															new Date(r.certification_date).getFullYear() <
																2021))) &&
												!r.lca_provider &&
												!r.number_of_products &&
												!r.product_category &&
												!r.lca_standards &&
												i > 0
											)
										);
									}) ||
									isLoading ||
									isLoadingLCAVersion ||
									loading
										? true
										: false
								}
							>
								{!certifying &&
								(isLoading || isLoadingLCAVersion || loading) ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : rejectedReason ? (
									"Fix Rejection"
								) : selectedLCA ? (
									"Submit Revision"
								) : (
									"Next Step"
								)}
							</Button>
						)}
					</Box>
				</Box>
			)}
			{false && !selectedLCA && (
				<Box
					sx={{
						minHeight: 34,
						width: "100%",
						display: "flex",
						marginTop: 2,
						flexDirection: "column",
						alignItems: "flex-end",
					}}
				>
					<Button
						variant="outlined"
						disabled={rows.length >= 20}
						sx={{
							"&.Mui-disabled": {
								backgroundColor: "#fff",
								border: "1px solid rgba(37, 64, 109, 0.5)",
								color: "#25406D",
								opacity: 0.4,
							},
							maxWidth: 200,
						}}
						onClick={() => {
							handleAddInput();
						}}
					>
						Add LCA
					</Button>
					{rows.length >= 200 && (
						<Typography
							variant="body2"
							sx={{
								fontSize: 12,
								textAlign: "center",
								marginTop: 1,
							}}
						>
							Max. 200 LCAs at the same time
						</Typography>
					)}
				</Box>
			)}

			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setLCARecommendations(false);
				}}
				children={
					<LCARecommendations setLCARecommendations={setLCARecommendations} />
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showLCARejectedModal}
				handleClose={() => {
					setShowLCARejectedModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Note from ClimeCo Representative:
							<br />
							<br />
							<b>{rejectedReason ?? "Invalid LCA"}</b>
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowLCARejectedModal(false);
								}}
							>
								OK
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showConfirmationModal}
				handleClose={() => {
					setShowConfirmationModal(false);
				}}
				children={
					<Box
						sx={{
							width: "100%",
							textAlign: "center",
							padding: 4,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h5"
							sx={{ fontWeight: "bold", marginBottom: 2, maxWidth: 500 }}
						>
							New LCA {selectedLCA ? " data" : ""}
						</Typography>
						<Typography variant="body1" sx={{ marginBottom: 4, maxWidth: 500 }}>
							{rejectedReason
								? numberOfRejections
									? "Please note that we charge USD $500 for each additional LCA review after two reviews. Your resubmission will be the " +
									  intToOrdinalNumberString(numberOfRejections + 1) +
									  " review."
									: "Please note that you will be charged USD $500 for the third and subsequent ClimeCo reviews."
								: selectedLCA &&
								  (selectedLCA.lca_versions ?? []).length > 0 &&
								  selectedLCA!.lca_versions![0].certification_process &&
								  selectedLCA!.lca_versions![0].certification_process.status !==
										"COMPLETED"
								? "You are updating the current LCA version with new data."
								: "You are updating the current LCA version with new data. Have in mind this will trigger a new certification process and fees ($250) will be applied accordingly."}
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									minHeight: 34,
								}}
								onClick={() => {
									setShowConfirmationModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									minHeight: 34,
								}}
								onClick={() => {
									setShowConfirmationModal(false);
									bulkLCAs(true);
								}}
							>
								OK
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
