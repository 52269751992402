import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { PropTypes } from "./DataTable.types";
import styled from "styled-components";
import React, { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { Checkbox } from "@mui/material";
import {
	MdOutlineCheckBoxOutlineBlank as CheckOutlineIcon,
	MdCheckBox as CheckIcon,
} from "react-icons/md";

const StyledDataGrid = styled(DataGrid)`
	&& {
		.MuiDataGrid-cell:focus {
			outline: none;
		}
		.MuiDataGrid-virtualScroller {
			min-height: 100px;
		}
		.overlay-row {
			position: relative;
			& > div {
				opacity: 0.5;
			}
			&:before {
				content: "You have requested its decertification";
				position: absolute;
				width: 270px;
				height: 30px;
				left: 50%;
				top: 50%;
				margin-left: -135px;
				margin-top: -15px;
				background: white;
				display: flex;
				align-items: center;
				justify-content: center;
				color: black;
				font-size: 14px;
				font-weight: bold;
				z-index: 2;
				border-radius: 8px;
				border: 1px solid #eee;
			}
			.MuiDataGrid-cell {
				z-index: 1; /* Las celdas deben estar detrás del overlay */
			}
		}
		.admin-overlay-row {
			position: relative;
			& > div {
				opacity: 0.5;
			}
			&:before {
				content: "User has requested its decertification";
				position: absolute;
				width: 270px;
				height: 30px;
				left: 50%;
				top: 50%;
				margin-left: -135px;
				margin-top: -15px;
				background: white;
				display: flex;
				align-items: center;
				justify-content: center;
				color: black;
				font-size: 14px;
				font-weight: bold;
				z-index: 2;
				border-radius: 8px;
				border: 1px solid #eee;
			}
			.MuiDataGrid-cell {
				z-index: 1;
			}
		}

		.MuiCheckbox-root {
			color: #e5e5e5;
			padding: 4px;
			& svg {
				font-size: 20px;
			}
		}

		.MuiCheckbox-root.Mui-checked {
			color: #003899;
		}

		.MuiCheckbox-root.Mui-disabled {
			color: #e5e5e5;
		}
		.MuiOutlinedInput-root {
			border-radius: 8px !important;
		}
		.MuiDataGrid-columnHeader:focus-within,
		.MuiDataGrid-cell:focus-within {
			outline: none;
		}
	}
`;

export default function DataTable({
	rows,
	columns,
	rowCount,
	setSelectedRows,
	paginationModel,
	setPaginationModel,
	sortModel,
	setSortModel,
	showCheckbox = true,
	isLoading,
	sx = {},
}: PropTypes) {
	const { user } = useContext(UserContext);
	const getRowClassName = (params: GridRowParams) => {
		if (params.row.decertifying_request != null && !user?.is_superuser) {
			return "overlay-row";
		} else if (params.row.decertifying_request != null && user?.is_superuser) {
			return "admin-overlay-row";
		}
		return "";
	};

	return (
		<div
			style={{
				height: "auto",
				maxHeight: "100%",
				width: "100%",
			}}
		>
			<StyledDataGrid
				sx={sx}
				pageSizeOptions={[10, 50]}
				loading={isLoading}
				disableColumnMenu
				disableRowSelectionOnClick={!showCheckbox}
				rows={rows}
				columns={columns}
				rowCount={rowCount}
				initialState={{
					pagination: {
						paginationModel: paginationModel,
					},
					sorting: {
						sortModel: sortModel,
					},
				}}
				disableColumnFilter
				paginationModel={paginationModel}
				paginationMode="server"
				sortingMode="server"
				filterMode="server"
				sortModel={sortModel}
				checkboxSelection={showCheckbox}
				onRowSelectionModelChange={(ids) => {
					const selectedIDs = new Set(ids);
					const selectedRowData = rows.filter((row) => {
						return selectedIDs.has(row.id);
					});
					setSelectedRows(selectedRowData);
				}}
				onSortModelChange={setSortModel}
				onPaginationModelChange={setPaginationModel}
				getRowId={(row: any) => row.id}
				getRowClassName={getRowClassName}
				isRowSelectable={(params: GridRowParams) => !params.row.disabled}
				slots={{
					baseCheckbox: (props) => (
						<Checkbox
							{...props}
							icon={
								<CheckOutlineIcon
									style={{
										fontSize: 18,
									}}
								/>
							}
							checkedIcon={
								<CheckIcon
									style={{
										fontSize: 18,
									}}
								/>
							}
						/>
					),
				}}
			/>
		</div>
	);
}
