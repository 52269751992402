import {
	Button,
	CircularProgress,
	TextField,
	Box,
	Typography,
	InputAdornment,
	IconButton,
	Divider,
} from "@mui/material";
import { PropTypes } from "./Auth.types";
import { useState, useRef, useEffect } from "react";
import { token as tokenActions, user as userActions } from "../../controllers";
import { useMutation } from "react-query";
import { useSnackbar /*, VariantType*/ } from "notistack";
import { useContext } from "react";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import {
	AiOutlineEye as Visibility,
	AiOutlineEyeInvisible as VisibilityOff,
} from "react-icons/ai";
import Logo from "../../assets/logo-vertical.png";
import InfoIcon from "@mui/icons-material/Info";
import ReactCodeInput from "react-code-input";
import { BasicModal } from "../BasicModal";
import { useWindowSize } from "@uidotdev/usehooks";

const useStyles = makeStyles({
	authContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		flex: 1,
		height: "100vh",
		padding: 20,

		"@media (max-width: 600px)": {
			height: "auto",
		},
	},
	authContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		maxWidth: "800px",
		border: "1px solid #B1D1F0",
		borderRadius: "40px",
	},
	mainButton: {
		height: 40,
		minWidth: "120px",
	},

	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
	inputLabelRequired: {
		fontSize: 18,
		display: "inline-block",
		height: "5px",
		verticalAlign: "sub",
		color: "#B61313",
	},
});

export function Auth(props: PropTypes) {
	const classes = useStyles();
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [token, setToken] = useState("");
	const size = useWindowSize();
	const [isSmallHeight, setIsSmallHeight] = useState(window.innerHeight < 740);
	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 600);

	const passwordInputRef = useRef<HTMLInputElement | null>(null);

	const [resendDisabled, setResendDisabled] = useState(false);
	const [countdown, setCountdown] = useState(30);

	const [showPassword, setShowPassword] = useState(false);
	const [showWelcomeModal, setShowWelcomeModal] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const [auth, setAuth] = useState("login");

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const { enqueueSnackbar } = useSnackbar();
	const { getUser } = useContext(UserContext);
	const { isLoading, mutate: mutateLogin } = useMutation(
		"token",
		() => {
			return tokenActions.login({ email: email, password: password });
		},
		{
			onSuccess: () => {
				getUser();
			},
			onError: (error) => {
				console.log(error);
				enqueueSnackbar(
					"Wrong credentials. Try again or click forgot password to reset it.",
					{ variant: "error" }
				);
			},
		}
	);

	const { isLoading: isLoadingStartReset, mutate: mutateStartReset } =
		useMutation(
			"start-reset",
			() => {
				return tokenActions.startReset({ email: email, password: "" });
			},
			{
				onSuccess: () => {
					enqueueSnackbar(
						"An email with a link to reset your password has been sent.",
						{ variant: "success" }
					);
					setAuth("create_password");
					setShowPassword(false);
				},
				onError: (error) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	const { isLoading: isLoadingReset, mutate: mutateReset } = useMutation(
		"reset",
		() => {
			return tokenActions.reset({ token: token, password: password });
		},
		{
			onSuccess: () => {
				enqueueSnackbar(
					"Password successfully reseted. You can now init session.",
					{ variant: "success" }
				);
				setAuth("login");
				setShowPassword(false);
			},
			onError: (error) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { mutate: mutateRegister } = useMutation(
		"register",
		() => {
			return userActions.create({
				first_name: name,
				email: email,
				password: password,
				username: email,
			});
		},
		{
			onSuccess: () => {
				mutateLogin();
			},
			onError: (error: any) => {
				const capitalizeFirstLetter = (text: string) => {
					return text.charAt(0).toUpperCase() + text.slice(1);
				};
				if (error.response.data.email) {
					enqueueSnackbar(capitalizeFirstLetter(error.response.data.email[0]), {
						variant: "error",
					});
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				//eslint-disable-next-line
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const validatePasswords = (password: string) => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumber = /\d/.test(password);
		//eslint-disable-next-line
		const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

		return (
			password.length >= minLength &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumber &&
			hasSpecialChar
		);
	};

	const login = () => {
		if (validateEmail(email)) {
			mutateLogin();
		}
	};

	const register = () => {
		let errorMessage = "";

		if (!name) {
			errorMessage = "Name is required.";
		} else if (!validateEmail(email)) {
			errorMessage = "Invalid email address.";
		} else if (!validatePasswords(password)) {
			errorMessage = "Password does not meet the criteria";
			setPasswordError(true);
		} else if (password !== repeatPassword) {
			errorMessage = "Passwords do not match.";
		}

		if (errorMessage) {
			enqueueSnackbar(errorMessage, {
				variant: "error",
			});
		} else {
			mutateRegister();
		}
	};

	const onKeyPressName = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			if (passwordInputRef.current) {
				passwordInputRef.current.focus();
			}
		}
	};

	const onKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault();
			login();
		}
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		if (searchParams.has("welcome")) {
			setShowWelcomeModal(true);
		}
	}, []);

	useEffect(() => {
		if (size.height && size.width) {
			setIsSmallHeight(size.height < 740);
			setIsSmallScreen(size.width < 600);
		}
	}, [size]);

	return (
		<Box
			className={classes.authContainer}
			sx={{
				justifyContent: isSmallHeight ? "flex-start" : "center",
			}}
		>
			<Box className={classes.authContent}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						padding: 3,
					}}
				>
					<img
						src={Logo}
						alt="climeco"
						style={{ width: isSmallScreen ? 100 : 130, marginBottom: 20 }}
					/>

					<Typography
						variant="body2"
						sx={{ textAlign: "center", marginBottom: 2 }}
					>
						ClimeCo Certified Product Portal
					</Typography>
					<Typography
						variant="h4"
						sx={{
							fontWeight: 700,
							textAlign: "center",
						}}
					>
						{auth === "login"
							? "Sign in"
							: auth === "register"
							? "Sign up"
							: "Forgot password"}
					</Typography>
				</Box>
				<Divider sx={{ borderBottom: "1px solid #B1D1F0", width: "100%" }} />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						padding: { xs: "20px 20px", md: "20px 160px" },
						width: "100%",
						alignItems: "center",
					}}
				>
					{auth === "login" ? (
						<>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span className={classes.inputLabel}>Email</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									placeholder="Enter email"
									value={email}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
								<span className={classes.inputLabel}>Password</span>
								<TextField
									inputRef={passwordInputRef}
									type={showPassword ? "text" : "password"}
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="password"
									value={password}
									placeholder="Enter password"
									onKeyDown={onKeyPress}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									InputProps={{
										sx: {
											paddingRight: "20px",
											border: "1px solid #C9C9C9",
											borderRadius: "8px",
											"& input": {
												border: "none",
											},
											"& .MuiInputBase-input": {
												border: "none",
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										},
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Box>
							<Box
								sx={{ display: "flex", spacing: 2, flexDirection: "column" }}
							>
								<Typography
									variant="body2"
									sx={{ textAlign: "center", marginTop: 4, marginRight: 1 }}
								>
									Don't have an account?{" "}
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setPassword("");
										setRepeatPassword("");
										setShowPassword(false);
										setAuth("register");
									}}
									sx={{
										textAlign: "center",
										fontWeight: "bold",
										color: "#183A67",
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									Sign up
								</Typography>
							</Box>
							<Button
								sx={{ marginTop: 2, minHeight: 34 }}
								disabled={!email || !password}
								variant="contained"
								onClick={() => {
									login();
								}}
								className={classes.mainButton}
							>
								{isLoading ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Login"
								)}
							</Button>
							<Typography
								variant="body2"
								sx={{
									textAlign: "center",
									marginTop: 2,
									"&:hover": {
										cursor: "pointer",
										textDecoration: "underline",
									},
								}}
								onClick={() => {
									setAuth("password");
									setShowPassword(false);
								}}
							>
								Forgot password?
							</Typography>
						</>
					) : auth === "register" ? (
						<>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span className={classes.inputLabel}>Full Name</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="name"
									placeholder="Enter name"
									value={name}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setName(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
								<span className={classes.inputLabel}>Email</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									placeholder="Enter email"
									value={email}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
								<span className={classes.inputLabel}>Password</span>
								<TextField
									inputRef={passwordInputRef}
									type={showPassword ? "text" : "password"}
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="password"
									placeholder="Enter password"
									value={password}
									onKeyDown={onKeyPress}
									sx={{ marginBottom: 1 }}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
									InputProps={{
										sx: {
											paddingRight: "20px",
											border: "1px solid #C9C9C9",
											borderRadius: "8px",
											"& input": {
												border: "none",
											},
											"& .MuiInputBase-input": {
												border: "none",
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										},
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								<span className={classes.inputLabel}>Repeat Password</span>
								<TextField
									inputRef={passwordInputRef}
									type={showPassword ? "text" : "password"}
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="repeatPassword"
									placeholder="Enter password again"
									onKeyDown={onKeyPress}
									sx={{
										marginBottom: passwordError ? 2 : isSmallScreen ? 2 : 4,
									}}
									onChange={(e) => {
										setRepeatPassword(e.target.value);
									}}
									InputProps={{
										sx: {
											paddingRight: "20px",
											border: "1px solid #C9C9C9",
											borderRadius: "8px",
											"& input": {
												border: "none",
											},
											"& .MuiInputBase-input": {
												border: "none",
											},
											"& .MuiOutlinedInput-notchedOutline": {
												border: "none",
											},
										},
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Box>
							{passwordError && (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										marginTop: 2,
										marginBottom: isSmallScreen ? 2 : 4,
									}}
								>
									<InfoIcon
										sx={{ marginRight: 1, color: "red", marginTop: 1 }}
									/>
									<Typography
										variant="body2"
										sx={{
											color: "red",
											textAlign: "center",
										}}
									>
										The password must be at least 8 characters long and should
										include at least one uppercase letter, one lowercase letter,
										one number, and one special character.
									</Typography>
								</Box>
							)}
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									variant="body2"
									sx={{
										textAlign: "center",
										marginRight: 1,
										marginBottom: 0,
									}}
								>
									Already have an account?
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setPassword("");
										setRepeatPassword("");
										setShowPassword(false);
										setAuth("login");
									}}
									sx={{
										color: "#183A67",
										textAlign: "center",
										fontWeight: "bold",
										marginBottom: 2,
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									Log In
								</Typography>
							</Box>
							<Button
								disabled={!email || !password}
								variant="contained"
								onClick={() => {
									setPasswordError(false);
									register();
								}}
								sx={{ minHeight: 34, padding: "0px 24px" }}
								className={classes.mainButton}
							>
								{isLoading ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Create Account"
								)}
							</Button>
						</>
					) : auth === "create_password" ? (
						<>
							<Box
								sx={{
									"& input": {
										boxShadow: "none!important",
										"&:focus-visible": {
											outline: "#25406D auto 1px",
										},
									},
								}}
							>
								<ReactCodeInput
									type="text"
									fields={6}
									name=""
									inputMode="numeric"
									value={token}
									onChange={(value) => setToken(value)}
								/>
							</Box>
							<Typography
								variant="body2"
								sx={{
									marginTop: 1,
									marginBottom: 2,
									cursor: resendDisabled ? "default" : "pointer",
									"&:hover": {
										textDecoration: resendDisabled ? "none" : "underline",
									},
								}}
								onClick={() => {
									if (!resendDisabled) {
										if (validateEmail(email)) {
											mutateStartReset();
											setResendDisabled(true);
											let seconds = 30;
											const interval = setInterval(() => {
												seconds -= 1;
												setCountdown(seconds);
												if (seconds <= 0) {
													setCountdown(30);
													clearInterval(interval);
													setResendDisabled(false);
												}
											}, 1000);
										} else {
											enqueueSnackbar("Invalid email", {
												variant: "error",
											});
										}
									}
								}}
							>
								{resendDisabled
									? `Wait ${countdown} seconds to resend another code`
									: "Didn't receive the email? Check your other/spam folder. Click to send another code."}
							</Typography>

							<TextField
								inputRef={passwordInputRef}
								type={showPassword ? "text" : "password"}
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="password"
								placeholder="Password"
								value={password}
								onKeyDown={onKeyPress}
								sx={{ marginBottom: 2 }}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								inputRef={passwordInputRef}
								type={showPassword ? "text" : "password"}
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="repeatPassword"
								placeholder="Repeat Password"
								onKeyDown={onKeyPress}
								sx={{ marginBottom: 1 }}
								onChange={(e) => {
									setRepeatPassword(e.target.value);
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{passwordError && (
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										marginTop: 2,
										marginBottom: 4,
									}}
								>
									<InfoIcon
										sx={{ marginRight: 1, color: "red", marginTop: 1 }}
									/>
									<Typography
										variant="body2"
										sx={{
											color: "red",
											textAlign: "center",
										}}
									>
										The password must be at least 8 characters long and should
										include at least one uppercase letter, one lowercase letter,
										one number, and one special character.
									</Typography>
								</Box>
							)}
							<Button
								sx={{ marginTop: 8, minHeight: 34 }}
								disabled={
									isLoadingReset ||
									!token ||
									token.length < 6 ||
									!password ||
									!repeatPassword
								}
								variant="contained"
								onClick={() => {
									if (validatePasswords(password)) {
										mutateReset();
									} else {
										setPasswordError(true);
										let errorMessage = "Password does not meet the criteria";
										if (password !== repeatPassword) {
											errorMessage = "Passwords do not match.";
										}
										enqueueSnackbar(errorMessage, {
											variant: "error",
										});
									}
								}}
								className={classes.mainButton}
							>
								{isLoadingReset ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Change Password"
								)}
							</Button>

							<Box sx={{ display: "flex", spacing: 2 }}>
								<Typography
									variant="body2"
									sx={{
										textAlign: "center",
										marginTop: isSmallScreen ? 2 : 4,
										marginRight: 1,
									}}
								>
									Already have an account?
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setAuth("login");
										setShowPassword(false);
									}}
									sx={{
										textAlign: "center",
										marginTop: 4,
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									Login
								</Typography>
							</Box>
						</>
					) : (
						<>
							<Box
								sx={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span className={classes.inputLabel}>Email</span>
								<TextField
									InputLabelProps={{ shrink: false }}
									fullWidth
									label=""
									id="email"
									value={email}
									placeholder="Enter email"
									sx={{ marginBottom: 0 }}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									onKeyDown={onKeyPressName}
								/>
							</Box>
							<Button
								sx={{ marginTop: 2, minHeight: 34 }}
								disabled={isLoadingStartReset || !email}
								variant="contained"
								onClick={() => {
									if (validateEmail(email)) {
										mutateStartReset();
									} else {
										enqueueSnackbar("Invalid email", {
											variant: "error",
										});
									}
								}}
								className={classes.mainButton}
							>
								{isLoadingStartReset ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									"Send"
								)}
							</Button>
							<Box sx={{ display: "flex", flexDirection: "column" }}>
								<Typography
									variant="body2"
									sx={{ textAlign: "center", marginTop: 2 }}
								>
									Already have an account?
								</Typography>
								<Typography
									variant="body2"
									onClick={() => {
										setAuth("login");
										setShowPassword(false);
									}}
									sx={{
										color: "#183A67",
										textAlign: "center",
										fontWeight: "bold",
										marginBottom: 2,
										"&:hover": {
											cursor: "pointer",
											textDecoration: "underline",
										},
									}}
								>
									Log in
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Box>
			<BasicModal
				width={800}
				showModal={showWelcomeModal}
				handleClose={() => {
					setShowWelcomeModal(false);
				}}
				children={
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							id="welcome-modal"
							variant="h6"
							component="h2"
							sx={{ textAlign: "center" }}
						>
							Hello and thank you for your participation in the ClimeCo
							Certified Product Program
						</Typography>
						<Typography id="welcome-modal" variant="body2" component="h2">
							<p>
								We would like to introduce you to our new Product Certification
								Dashboard where you can easily decarbonize your products.
							</p>
							<p>
								To get started, we need to gather your already-registered
								product data. The steps to do so are:
							</p>
							<ul>
								<li>1) Create an account</li>
								<li>
									2) Add product information and their corresponding Life Cycle
									Analysis (LCA){" "}
								</li>
								<li>3) Add your quarterly sales</li>
							</ul>
							<p>
								Your data will be reviewed by a ClimeCo representative and then
								approved to add more products going forward. We thank you for
								your continued support!
							</p>
							<p>Click OK to get started.</p>
						</Typography>
						<br />
						<Typography
							id="welcome-modal-note"
							variant="body2"
							component="h2"
							sx={{ fontSize: 11 }}
						>
							***Note: This platform will be used to store account data and for
							adding new products from now on. Use this platform for adding
							products, estimating sales, buying offsets, and viewing your sales
							and offset balance. If you have any issues, please write your
							input in the lower right hand box titled “Feedback & Bugs”. If you
							have specific questions, please email
							productcertification@climeco.com.
						</Typography>
						<Button
							variant="contained"
							sx={{ marginTop: 2 }}
							onClick={() => {
								const searchParams = new URLSearchParams(
									window.location.search
								);
								searchParams.delete("welcome");
								const newUrl = searchParams.toString()
									? `${window.location.pathname}?${searchParams}`
									: window.location.pathname;
								window.history.pushState({}, "", newUrl);
								setShowWelcomeModal(false);
							}}
						>
							OK
						</Button>
					</Box>
				}
			/>
		</Box>
	);
}
