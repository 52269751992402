import { PropTypes } from "./ProductDetail.types";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	FormControl,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { product as productActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { ICertification, IEstimation, IProduct } from "../../models";
import { UserContext } from "../../contexts/user";
import ArrayInput from "../ArrayInput/ArrayInput";
import { Drawer } from "@mui/material";
import { ListTable } from "../ListTable";
import { ProductStatusChip } from "./ProductStatusChip";
import { BasicModal } from "../BasicModal";
import { CertificateProduct } from "../CertificateProduct";
import {
	certification as certificationActions,
	estimation as estimationsActions,
} from "../../controllers";
import { GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { Filters } from "../../routes/Routes.types";
import { ProductStatus } from "../ProductStatus";
import { LCARecommendations } from "../LCARecommendations";

const useStyles = makeStyles({
	inputLabel: {
		fontSize: "14px",
		marginTop: 8,
		marginBottom: 4,
		fontWeight: 700,
	},
});
export function ProductDetail({ inModal, inModalCallback }: PropTypes) {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);

	const classes = useStyles();
	const navigate = useNavigate();
	const { id: paramsId } = useParams();
	const id = inModal
		? inModal === "create"
			? 0
			: inModal
		: paramsId
		? parseInt(paramsId)
		: 0;

	const [product, setProduct] = useState<IProduct | null>({
		id: 0,
		name: "",
		description: "",
		status: "PENDING",
	});

	const [products, setProducts] = useState<Array<IProduct>>([]);
	const [search] = useState("");
	const [filters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
		certification: "All certifications",
	});
	const [paginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);

	const [showProductInformation, setShowProductInformation] = useState(false);
	const [showLCARejectedModal, setShowLCARejectedModal] = useState(false);
	const [isHistorical] = useState(
		user?.company.historically_approved === null ? true : false
	);
	const [showCertificateModal, setShowCertificateModal] = useState(false);
	const [showLCARecommendations, setLCARecommendations] = useState(false);

	const { refetch } = useQuery(
		"products-detail-list",
		() =>
			productActions.getOne(
				id!,
				!user?.is_superuser ? user?.company.id : undefined
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProduct(res);

				if (
					res &&
					res.estimations &&
					res?.estimations?.length > 0 &&
					res.estimations.find((e) => e.rejected_date !== null)
				) {
					setShowLCARejectedModal(true);
				}
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const { refetch: refetchProducts } = useQuery(
		"products",
		() =>
			productActions.list(
				!user?.is_superuser ? user?.company.id : undefined,
				search,
				filters.companies,
				filters.certification,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProducts(res.results);
				//setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"products",
		() =>
			id
				? productActions.update({
						id: id,
						name: product?.name,
						description: product?.description,
						companyId: !user?.is_superuser ? user?.company.id : undefined,
						external_id: product?.external_id,
						sku: product?.sku,
						asin: product?.asin,
						ean: product?.ean,
						isbn: product?.isbn,
						upc: product?.upc,
						gtin: product?.gtin,
						brand: product?.brand,
						websites: product?.websites ?? [""],
						status: user?.is_superuser ? product?.status : undefined,
				  })
				: productActions.create({
						name: product?.name,
						description: product?.description,
						companyId: user?.company.id,
						external_id: product?.external_id,
						sku: product?.sku,
						asin: product?.asin,
						ean: product?.ean,
						isbn: product?.isbn,
						upc: product?.upc,
						gtin: product?.gtin,
						websites: product?.websites ?? [""],
						brand: product?.brand,
						status: user?.is_superuser ? product?.status : undefined,
				  }),
		{
			retry: false,
			onSuccess: async (res) => {
				setProduct({ ...product!, id: res.id });

				enqueueSnackbar(
					"The changes on the product have been successfully saved",
					{
						variant: "success",
					}
				);
				/*const toCreateLCA = (product?.lca_documents ?? []).filter(
					(p) => !productLCAs.find((originalP) => originalP.id === p.id)
				);

				for (const p of toCreateLCA) {
					await new Promise((resolve, reject) => {
						mutateDocuments(
							{ productLCAId: undefined, productLCA: p, productId: res.id },
							{
								onSuccess: (res) => {
									resolve(true);
								},
								onError: (error: any) => {
									enqueueSnackbar(
										"Something went wrong while updating the documents. Please retry it or contact support",
										{
											variant: "error",
										}
									);
									reject();
								},
							}
						);
					});
				}
				// delete
				const toDeleteLCA = productLCAs.filter(
					(p) =>
						!(product?.lca_documents ?? []).find(
							(originalP) => originalP.id === p.id
						)
				);

				for (const p of toDeleteLCA) {
					await new Promise((resolve, reject) => {
						mutateDeleteDocuments(
							{ id: p.id },
							{
								onSuccess: (res) => {
									resolve(true);
								},
								onError: (error: any) => {
									enqueueSnackbar(
										"Something went wrong while updating the documents. Please retry it or contact support",
										{
											variant: "error",
										}
									);
									reject();
								},
							}
						);
					});
				}
				// update
				const toUpdateLCA = (product?.lca_documents ?? []).filter((p) =>
					productLCAs.find((originalP) => originalP.id === p.id)
				);

				for (const p of toUpdateLCA) {
					await new Promise((resolve, reject) => {
						mutateDocuments(
							{ productLCAId: p.id, productLCA: p, productId: res.id },
							{
								onSuccess: (res) => {
									resolve(true);
								},
								onError: (error: any) => {
									enqueueSnackbar(
										"Something went wrong while updating the documents. Please retry it or contact support",
										{
											variant: "error",
										}
									);
									reject();
								},
							}
						);
					});
				}

				getUser();
				navigate("/products");

				/*if (inModal) {
					inModalCallback && inModalCallback(res);
				} else {
					if (!paramsId) {
						setShowCertificateModal(true);
					} else {
						navigate("/products");
					}
				}*/
			},
			onError: (error: any) => {
				if (
					error.response.data.name.length > 0 &&
					error.response.data.name[0] ===
						"product with this name already exists."
				) {
					enqueueSnackbar(
						"A product with this name already exists. Please, choose a new one.",
						{ variant: "error" }
					);
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const goToProducts = () => {
		navigate("/products");
	};
	const addProduct = () => {
		setShowCertificateModal(false);
		setProduct({
			id: 0,
			name: "",
			description: "",
			status: "PENDING",
			asin: "",
			upc: "",
			lca_documents: [],
			brand: "",
			websites: [""],
		});
	};

	const { mutate: createEstimationMutate } = useMutation(
		"start-estimations",
		({
			estimation,
			certification,
		}: {
			estimation: IEstimation;
			certification: ICertification;
		}) =>
			estimationsActions.create({
				amount: estimation.amount,
				//emissions: estimation.emissions,
				product: estimation.product.id,
				certificationProcess: certification!.id,
				companyId: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const { mutate: createCertificationMutate } = useMutation(
		"certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: "New",
				companyId: user?.company.id,
			}),
		{
			retry: false,
		}
	);

	const goToCertification = () => {
		// create certification
		createCertificationMutate(null, {
			onSuccess: (res) => {
				// create estimations
				let index = 0;
				const createEstimation = (
					id: number,
					amount: number,
					//emissions: number,
					product: IProduct,
					index: number
				) => {
					createEstimationMutate(
						{
							estimation: {
								id: id,
								amount: amount,
								//emissions: emissions,
								product: product,
							},
							certification: res,
						},
						{
							onSuccess: () => {
								navigate("/climeco-certificationss/" + res.id);
							},
							onError: (error) => {
								console.log(error);
								enqueueSnackbar("Something went wrong.", {
									variant: "error",
								});
							},
						}
					);
				};

				createEstimation(
					index,
					0,
					//0,
					product as IProduct,
					index
				);
			},
			onError: (error) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", {
					variant: "error",
				});
			},
		});
	};

	useEffect(() => {
		refetchProducts();
	}, [refetchProducts]);

	useEffect(() => {
		if (id) {
			refetch();
		} else {
			navigate("/404", { replace: true });
		}
	}, [id, refetch, navigate]);

	const validateForm = () => {
		// TO DO

		return true;
		/*const needToUpdateLCA =
			product &&
			product.estimations &&
			product?.estimations?.length > 0 &&
			product.estimations.find((e) => e.rejected_date !== null);
		const notUpdatedLCA =
			product?.lca_documents &&
			(product.lca_documents.length === 0 ||
				(product.lca_documents.length > 0 &&
					(!product.lca_documents[0].lca_document ||
						(product.lca_documents[0].lca_document &&
							product.lca_documents[0].lca_document === originalLCA))));
		if (needToUpdateLCA && notUpdatedLCA) {
			enqueueSnackbar(
				"You need to update your current LCA document as stated on the rejection reason.",
				{
					variant: "error",
				}
			);
			return false;
		} else if (!product?.name) {
			enqueueSnackbar("You need to input the name of the product", {
				variant: "error",
			});
			return false;
		} else if (!product.asin) {
			enqueueSnackbar("You need to input the ASIN of the product", {
				variant: "error",
			});
			return false;
		} else if (
			product &&
			product.lca_documents &&
			product.lca_documents.length > 0 &&
			product.lca_documents.findIndex(
				(lca) => lca.lca_document && lca.emissions! > 0
			) > -1 &&
			product.lca_documents.findIndex(
				(lca) =>
					lca.lca_document &&
					lca.emissions! > 0 &&
					new Date(lca.certification_date).getTime() >
						new Date(
							new Date().getTime() - 1095 * 24 * 60 * 60 * 1000
						).getTime()
			) > -1
		) {
			return true;
		} else {
			enqueueSnackbar("Make sure you have at least one valid LCA document.", {
				variant: "error",
			});
			return false;
		}*/
	};

	const certified =
		(product?.certification_processes?.length ?? 0) > 0 &&
		product!.certification_processes!.find(
			(c) => c.certification_process_steps!.length > 0
		) &&
		product!.certification_processes!.find(
			(c) =>
				c.certification_process_steps!.find(
					(step) => step.step.step_type === "OTHER"
				)?.status === "COMPLETED"
		) !== undefined;

	const amazonCertified =
		(product?.certification_processes ?? []).filter(
			(c) =>
				c.certification?.certification_type === "DEFAULT_EXTERNAL_CERTIFICATION"
		).length > 0 &&
		product!.certification_processes!.find(
			(c) => c.certification_process_steps!.length > 0
		) &&
		product!.certification_processes!.find(
			(c) =>
				c.certification_process_steps!.find(
					(step) => step.step.step_type === "OTHER"
				)?.status === "COMPLETED"
		) !== undefined;

	return (
		<Box>
			<Drawer
				anchor="right"
				open={showProductInformation}
				onClose={() => setShowProductInformation(false)}
				sx={{
					width: {
						xs: "90%",
						sm: "600px",
						md: "800px",
						lg: "auto",
					},
					maxWidth: {
						xs: "90%",
						sm: "600px",
						md: "800px",
						lg: "auto",
					},
					flexShrink: 0,
					boxShadow: "none",
					"& .MuiPaper-root": {
						width: {
							xs: "90%",
							sm: "600px",
							md: "800px",
							lg: "auto",
						},
					},
				}}
			>
				<Box
					sx={{
						padding: 4,
						width: "100%",
					}}
				>
					<Typography variant="h6" sx={{ marginBottom: 4 }}>
						{product?.name
							? product?.name + " Information"
							: "Product Information"}
					</Typography>
					<Box>
						<span className={classes.inputLabel}>LCAs</span>
						<Box sx={{ marginTop: 2, marginBottom: 2 }}>
							{(product?.lca_documents?.length ?? 0) > 0 ? (
								product!
									.lca_documents!.filter((d) => d.lca_document)
									.map((d) => (
										<Box
											sx={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
												maxWidth: {
													xs: "90%",
													sm: "600px",
													md: "800px",
													lg: "auto",
												},
											}}
											key={d.id}
										>
											<Typography
												sx={{
													maxWidth: {
														xs: "calc(80% - 60)",
													},
													overflow: "hidden",
													whiteSpace: "nowrap",
													textOverflow: "ellipsis",
												}}
											>
												<a
													href={d.lca_document!.toString()}
													target="_blank"
													rel="noreferrer"
												>
													{
														d.lca_document!.toString().split("/")[
															d.lca_document!.toString().split("/").length - 1
														]
													}
												</a>
											</Typography>
											<Typography
												sx={{
													textAlign: "right",
												}}
											>
												{new Intl.DateTimeFormat("en", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
												}).format(new Date(d.updated_at ?? ""))}
											</Typography>
										</Box>
									))
							) : (
								<Typography>--</Typography>
							)}
						</Box>
					</Box>

					<Box>
						<span className={classes.inputLabel}>Signed Contracts</span>
						<Box
							sx={{
								marginTop: 2,
								marginBottom: 2,
								display: "flex",
								flexDirection: "column",
							}}
						>
							{product &&
							product.certification_processes &&
							product.certification_processes.length > 0 ? (
								product.certification_processes.map((d) => {
									return (d.certification_process_files ?? []).map((file) => {
										const fileUrl =
											typeof file.file === "string" ? file.file : undefined;
										const fileName = fileUrl || "--";

										return (
											<Box
												sx={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
													maxWidth: {
														xs: "90%",
														sm: "600px",
														md: "800px",
														lg: "auto",
													},
												}}
											>
												<Typography
													sx={{
														maxWidth: {
															xs: "calc(80% - 60)",
														},
														overflow: "hidden",
														whiteSpace: "nowrap",
														textOverflow: "ellipsis",
													}}
												>
													<a href={fileUrl} target="_blank" rel="noreferrer">
														{
															fileName.toString().split("/")[
																fileName.toString().split("/").length - 1
															]
														}
													</a>
												</Typography>
												<Typography
													sx={{
														textAlign: "right",
													}}
												>
													{new Intl.DateTimeFormat("en", {
														day: "2-digit",
														month: "2-digit",
														year: "numeric",
													}).format(new Date(file.updated_at ?? ""))}
												</Typography>
											</Box>
										);
									});
								})
							) : (
								<Typography>--</Typography>
							)}
						</Box>
					</Box>
					<span className={classes.inputLabel}>Estimations</span>
					{product?.estimations ? (
						<ListTable
							headers={[
								{ key: "amount", title: "Estimated Sales", type: "text" },
								{
									key: "lca_document",
									subkey: "emissions",
									title: "Emissions",
									type: "lca_document_emissions",
								},
								{
									key: "checklist_document",
									title: "Checklist Document",
									type: "download",
								},
							]}
							data={product?.estimations}
						/>
					) : (
						<Typography variant="body2" sx={{ padding: 2 }}>
							No estimations yet
						</Typography>
					)}
				</Box>
			</Drawer>

			<Box sx={{ flexGrow: 1 }}>
				{/*<Typography
					variant="body2"
					fontSize={10}
					marginBottom={2}
					sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
					onClick={() => {
						navigate("/products");
					}}
				>
					{"BACK"}
				</Typography>*/}
				<Heading
					title={id ? "Edit Product" : "Add Product"}
					showSearch={false}
					setShowProductInformation={id ? setShowProductInformation : undefined}
					noMarginBottom
				/>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							sm: "row",
						},
						gap: 2,
						justifyContent: "flex-end",
					}}
				>
					<ProductStatus
						title="Product LCA Status"
						subtitle={`${
							(product?.product_lca_version_emissions?.length ?? 0) > 0 &&
							product?.product_lca_version_emissions![0].lca_version &&
							product?.product_lca_version_emissions![0].lca_version!.status ===
								"ACTIVE" &&
							product?.product_lca_version_emissions![0].lca_version!
								.approved_date
								? "since " +
								  new Intl.DateTimeFormat("en", {
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
								  }).format(
										new Date(
											product?.product_lca_version_emissions![0].lca_version!.approved_date!
										)
								  )
								: (product?.product_lca_version_emissions?.length ?? 0) > 0 &&
								  product?.product_lca_version_emissions![0].lca_version
								? product?.product_lca_version_emissions![0].lca_version!.status.toLowerCase()
								: "pending"
						}`}
						status={
							(product?.product_lca_version_emissions?.length ?? 0) > 0 &&
							product?.product_lca_version_emissions![0].lca_version
								? product?.product_lca_version_emissions![0].lca_version!.status.toLowerCase()
								: "pending"
						}
					/>
					<ProductStatus
						title="ClimeCo Cert Status"
						subtitle={
							certified
								? `since ${
										(product?.certification_processes?.length ?? 0) > 0 &&
										(product!
											.certification_processes!.filter(
												(c) =>
													c.certification?.certification_type ===
													"CLIMECO_INTERNAL_CERTIFICATION"
											)
											.find(
												(c) =>
													c.certification_process_steps!.find(
														(step) => step.step.step_type === "OTHER"
													)?.status === "COMPLETED"
											)?.certification_process_steps?.length ?? 0) > 0
											? new Intl.DateTimeFormat("en", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
											  }).format(
													new Date(
														product!
															.certification_processes!.filter(
																(c) =>
																	c.certification?.certification_type ===
																	"CLIMECO_INTERNAL_CERTIFICATION"
															)
															.find(
																(c) =>
																	c.certification_process_steps!.find(
																		(step) => step.step.step_type === "OTHER"
																	)?.status === "COMPLETED"
															)!
															.certification_process_steps!.find(
																(step) => step.step.step_type === "OTHER"
															)!.updated_at!
													)
											  )
											: "--"
								  }`
								: "pending"
						}
						status={certified ? "completed" : "pending"}
					/>

					<ProductStatus
						title="Amazon CPF Status"
						subtitle={
							certified
								? `since ${
										(product?.certification_processes ?? []).filter(
											(c) =>
												c.certification?.certification_type ===
												"DEFAULT_EXTERNAL_CERTIFICATION"
										).length > 0 &&
										((product?.certification_processes ?? [])
											.filter(
												(c) =>
													c.certification?.certification_type ===
													"DEFAULT_EXTERNAL_CERTIFICATION"
											)
											.find(
												(c) =>
													c.certification_process_steps?.find(
														(step) => step.step.step_type === "OTHER"
													)?.status === "COMPLETED"
											)?.certification_process_steps?.length ?? 0) > 0
											? new Intl.DateTimeFormat("en", {
													day: "2-digit",
													month: "2-digit",
													year: "numeric",
											  }).format(
													new Date(
														product?.certification_processes
															?.filter(
																(c) =>
																	c.certification?.certification_type ===
																	"DEFAULT_EXTERNAL_CERTIFICATION"
															)
															.find(
																(c) =>
																	c.certification_process_steps?.find(
																		(step) => step.step.step_type === "OTHER"
																	)?.status === "COMPLETED"
															)!
															.certification_process_steps?.find(
																(step) => step.step.step_type === "OTHER"
															)!.updated_at!
													)
											  )
											: "--"
								  }`
								: "pending"
						}
						status={amazonCertified ? "completed" : "pending"}
					/>
				</Box>
				<Box
					sx={{
						marginBottom: 4,
						boxShadow: "0 0px 6px rgba(0, 0, 0, 0.1)",
						padding: 2,
						display: "flex",
						flexDirection: "column",
						marginTop: 4,
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "start",
							gap: 2,
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "column" }}>
							<Typography variant="h6" sx={{ marginBottom: 2 }}>
								Lifecycle Assesment (LCA) of your Product
							</Typography>
							{!product?.product_lca_version_emissions &&
							(product?.product_lca_version_emissions?.length ?? 0) > 0 ? (
								<Typography variant="body2" sx={{ marginBottom: 4 }}>
									You need to have one valid LCA (completed in the last three
									years) completed by an LCA Practitioner before you can receive
									a Product Certification from ClimeCo. If you don't have an LCA
									for your product, you can not continue.
								</Typography>
							) : (
								<Typography variant="body2" sx={{ marginBottom: 4 }}>
									Here is the current LCA version this product is attached to:
								</Typography>
							)}
						</Box>
						{!product?.product_lca_version_emissions &&
							(product?.product_lca_version_emissions?.length ?? 0) > 0 && (
								<Box
									sx={{
										border: "1px solid #efefef",
										padding: 1,
										maxWidth: 400,
										textAlign: "center",
									}}
								>
									<Typography variant="body2" sx={{ fontSize: 12 }}>
										Don't have a Lifecycle Assessment (LCA) of your product?{" "}
										<span
											style={{
												color: "#25406D",
												fontSize: 12,
												fontWeight: 700,
												cursor: "pointer",
											}}
											onClick={() => {
												setLCARecommendations(true);
											}}
										>
											Click Here
										</span>
									</Typography>
								</Box>
							)}
					</Box>

					{product &&
						product.product_lca_version_emission?.lca_version
							?.rejected_date && (
							<Alert severity="error" sx={{ marginBottom: 4 }}>
								Your certification has been rejected:{" "}
								{product.product_lca_version_emission?.lca_version
									?.reject_reason ?? "Invalid LCA"}
							</Alert>
						)}
					<Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
						<Box
							sx={{
								marginRight: 0,
								marginLeft: 2,
								display: "flex",
								flexDirection: "column",
								maxHeight: 77,
							}}
						>
							<span className={classes.inputLabel}>LCA Name</span>

							<Typography
								variant="body2"
								sx={{
									textAlign: "center",
									marginBottom: 2,
									fontWeight: "bold",
								}}
							>
								{(product?.product_lca_version_emissions &&
									(product?.product_lca_version_emissions[0].lca_version
										?.title ??
										product?.product_lca_version_emissions[0].lca_version?.lca
											?.title ??
										"LCA")) ??
									"LCA"}
							</Typography>
						</Box>

						<Box
							sx={{
								marginRight: 0,
								marginLeft: 2,
								display: "flex",
								flexDirection: "column",
								maxHeight: 77,
							}}
						>
							<span className={classes.inputLabel}>LCA Document</span>

							<Typography
								sx={{
									marginLeft: 2,
									textOverflow: "ellipsis",
									overflow: "hidden",
									width: 150,
									flex: { xs: 1, sm: 1, md: "unset" },
									fontSize: 14,
									whiteSpace: "nowrap",
								}}
							>
								<a
									href={
										(product?.product_lca_version_emissions &&
											product?.product_lca_version_emissions[0].lca_version?.lca_document?.toString()) ??
										"#"
									}
									style={{ whiteSpace: "nowrap" }}
									target="_blank"
									rel="noreferrer"
									download
								>
									...
									{product?.product_lca_version_emissions &&
									product?.product_lca_version_emissions[0].lca_version?.lca_document?.toString()
										? "..." +
										  product?.product_lca_version_emissions[0]
												.lca_version!.lca_document!.toString()
												.split("/")
												[
													product?.product_lca_version_emissions[0]
														.lca_version!.lca_document!.toString()
														.split("/").length - 1
												].slice(-15)
										: "--"}
								</a>
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
							}}
						>
							{product?.product_lca_version_emissions &&
								product?.product_lca_version_emissions[0].lca_version
									?.certification_date && (
									<Box
										sx={{
											marginRight: 0,
											marginLeft: 2,
											display: "flex",
											flexDirection: "column",
											maxHeight: 77,
										}}
									>
										<span className={classes.inputLabel}>
											LCA Certification Date
										</span>

										<Typography
											sx={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												maxWidth: 250,
											}}
										>
											{new Intl.DateTimeFormat("en", {
												day: "2-digit",
												month: "2-digit",
												year: "numeric",
											}).format(
												new Date(
													(product?.product_lca_version_emissions &&
														product?.product_lca_version_emissions[0]
															.lca_version?.certification_date) ??
														""
												)
											)}
										</Typography>
									</Box>
								)}
						</Box>
					</Box>
				</Box>

				<FormControl sx={{ width: "100%" }}>
					{id &&
					((!user?.is_superuser && product?.status !== "ACTIVE") ||
						user?.is_superuser) ? (
						<>
							<span className={classes.inputLabel}>Status</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="status"
								select
								value={product?.status}
								placeholder="Status of the product"
								disabled={!user?.is_superuser}
								onChange={(e) => {
									setProduct(
										(prevState) =>
											({
												...prevState,
												status: e.target.value,
											} as IProduct)
									);
								}}
								sx={{
									marginBottom: 2,
									"& .MuiSelect-select": {
										backgroundColor: "#fff",
									},
									"& .MuiOutlinedInput-input": {
										backgroundColor: "#fff",
									},
									"& .MuiSelect-icon": {
										border: "none",
									},
								}}
							>
								{["ACTIVE", "PENDING", "OUTDATED"].map((option, index) => (
									<MenuItem key={option} value={option}>
										<ProductStatusChip
											status={option as "ACTIVE" | "PENDING" | "OUTDATED"}
										/>
									</MenuItem>
								))}
							</TextField>
						</>
					) : (
						<></>
					)}
					<span className={classes.inputLabel}>Title</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="title"
						value={product?.name}
						placeholder="Title of the product"
						onChange={(e) => {
							setProduct(
								(prevState) =>
									({
										...prevState,
										name: e.target.value,
									} as IProduct)
							);
						}}
						sx={{ marginBottom: 2 }}
					/>

					<span className={classes.inputLabel}>ASIN</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="asin"
						value={product?.asin}
						placeholder="ASIN"
						onChange={(e) => {
							setProduct(
								(prevState) =>
									({
										...prevState,
										asin: e.target.value,
									} as IProduct)
							);
						}}
						sx={{ marginBottom: 2 }}
					/>

					<span className={classes.inputLabel}>UPC</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="upc"
						value={product?.upc}
						placeholder="UPC"
						type="text"
						inputProps={{
							inputMode: "numeric",
						}}
						onChange={(e) => {
							const value = e.target.value;
							// Regular expression to allow only numbers
							const isNumeric = /^[0-9]*$/.test(value);
							// Check if the input is numeric and does not exceed 12 digits
							if (isNumeric && value.length <= 12) {
								setProduct(
									(prevState) =>
										({
											...prevState,
											upc: e.target.value,
										} as IProduct)
								);
							}
						}}
						sx={{
							marginBottom: 2,
						}}
					/>

					<span className={classes.inputLabel}>BRAND</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="brand"
						value={product?.brand}
						placeholder="Brand"
						onChange={(e) => {
							setProduct(
								(prevState) =>
									({
										...prevState,
										brand: e.target.value,
									} as IProduct)
							);
						}}
						sx={{ marginBottom: 2 }}
					/>
					<Box sx={{ marginTop: 2 }}>
						<span className={classes.inputLabel}>Website(s)</span>
						<ArrayInput
							array={product?.websites}
							setArray={(websites) => {
								setProduct(
									(prevState) =>
										({
											...prevState,
											websites: websites,
										} as IProduct)
								);
							}}
							title="Website(s)"
						/>
					</Box>
					<Button
						variant="contained"
						onClick={() => {
							if (validateForm()) {
								mutate();
							}
						}}
						disabled={isLoading}
						sx={{
							marginTop: 2,
							maxWidth: 300,
							alignSelf: "end",
							minHeight: 34,
						}}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : (
							"Submit changes"
						)}
					</Button>
				</FormControl>
			</Box>

			<BasicModal
				width={800}
				showModal={showCertificateModal}
				handleClose={() => {
					setShowCertificateModal(false);
				}}
				children={
					<CertificateProduct
						goToProducts={goToProducts}
						goToCertification={goToCertification}
						addProduct={addProduct}
						firstTime={products.length < 1}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setLCARecommendations(false);
				}}
				children={
					<LCARecommendations setLCARecommendations={setLCARecommendations} />
				}
				hideClose
			/>
			<BasicModal
				width={600}
				showModal={showLCARejectedModal}
				handleClose={() => {
					setShowLCARejectedModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						{!isHistorical ? (
							<Typography
								variant="body2"
								sx={{ textAlign: "center", marginBottom: 2 }}
							>
								This product LCA has been{" "}
								<b style={{ color: "#EB4B4B" }}>rejected</b> for the following
								reason:
								<br />
								<br />
								<b>
									{product &&
									product.estimations &&
									product?.estimations?.length > 0
										? product.estimations.find((e) => e.rejected_date !== null)
												?.reject_reason
										: "Invalid LCA"}
								</b>
								<br />
								<br />
								Please, have the LCA updated accordingly and re-upload the LCA
								on this product page. If you have additional questions, please
								email us at{" "}
								<a href="mailto:productcertification@climeco.com">
									productcertification@climeco.com
								</a>
							</Typography>
						) : (
							<Typography
								variant="body2"
								sx={{ textAlign: "center", marginBottom: 2 }}
							>
								Note from ClimeCo Representative:
								<br />
								<br />
								<b>
									{product &&
									product.estimations &&
									product?.estimations?.length > 0
										? product.estimations.find((e) => e.rejected_date !== null)
												?.reject_reason
										: "Invalid LCA"}
								</b>
							</Typography>
						)}
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								onClick={() => {
									setShowLCARejectedModal(false);
								}}
							>
								OK
							</Button>
						</Box>
					</Box>
				}
			/>
		</Box>
	);
}
