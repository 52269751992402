import { Box, Typography, Button } from "@mui/material";
import { PropTypes } from "./LCAListItem.types";

const LCAListItem = ({
	lca,
	index,
	editCallback,
	deleteCallback,
}: PropTypes) => {
	return (
		<Box
			key={index}
			sx={{
				border: "1px solid #E4E4E4",
				display: "flex",
				justifyContent: "space-between",
				alignItems: "center",
				padding: 2,
				borderRadius: 2,
				flex: 1,
				width: "100%",
			}}
		>
			<Typography variant="body2" sx={{ fontSize: 14, fontWeight: "bold" }}>
				{lca.title}
			</Typography>
			<Box sx={{ display: "flex" }}>
				<Button
					onClick={() => {
						editCallback();
					}}
				>
					Edit LCA
				</Button>
				<Button
					sx={{ color: "#eb4343" }}
					onClick={() => {
						deleteCallback();
					}}
				>
					Delete LCA
				</Button>
			</Box>
		</Box>
	);
};

export default LCAListItem;
