import { useContext, useEffect, useState } from "react";
import {
	Typography,
	Box,
	Button,
	TextField,
	Grid,
	FormControl,
	FormControlLabel,
	Checkbox,
	Autocomplete,
	Alert,
	ButtonGroup,
	CircularProgress,
} from "@mui/material";
import { PropTypes } from "./CompanyOnboarding.types";
import { useMutation } from "react-query";
import { company as companyActions } from "../../controllers";
import { ICompany } from "../../models";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { makeStyles } from "@mui/styles";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
	inputLabelRequired: {
		fontSize: 18,
		display: "inline-block",
		height: "5px",
		verticalAlign: "sub",
		color: "#B61313",
	},
});

export function CompanyOnboarding(props: PropTypes) {
	const classes = useStyles();
	const { user, getUser } = useContext(UserContext);
	const [company, setCompany] = useState<ICompany | null>({
		id: 0,
		name: "",
		email: user?.email ?? "",
		country: "US",
		formatted_address: "",
	});
	const [policyCheckbox, setPolicyCheckbox] = useState(false);
	const [protocolCheckbox, setProtocolCheckbox] = useState(false);

	const productProtocolUrl =
		"https://www.climeco.com/wp-content/uploads/2024/05/ClimeCo-Cert-Prod-Protocol_v12_FINAL_clean_no-sign_050124.pdf";
	const productProtocolAsset = "/ClimeCo-Cert-Prod-Protocol.pdf";
	const [isRemoteAvailable, setIsRemoteAvailable] = useState<boolean>(true);

	const [hadHistoricalData] = useState<Date | null | undefined>(undefined);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const [hadChinaProjects, setHadChinaProjects] = useState<boolean | undefined>(
		undefined
	);

	const { enqueueSnackbar } = useSnackbar();

	const [inputValue, setInputValue] = useState(
		company?.formatted_address || ""
	);

	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
		usePlacesService({
			apiKey: "AIzaSyBeHWqS_4Rm9ZBIqbOtQ5PwGJIUYsaHxD0",
			options: {
				/*componentRestrictions: {
				country: ["us", "ca"],
			},*/
				types: ["street_address", "route"],
			},
			language: "en",
		});

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				//eslint-disable-next-line
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const validate = (company: ICompany | null) => {
		// TO DO: Add more
		let valid = true;
		if (!validateEmail(company?.email ?? "")) {
			valid = false;
			enqueueSnackbar("Email field is incorrect.", { variant: "error" });
		}
		return valid;
	};

	useEffect(() => {
		fetch(productProtocolUrl, { method: "HEAD" })
			.then((response) => {
				if (!response.ok) throw new Error("Network response was not ok");
				setIsRemoteAvailable(true);
			})
			.catch(() => {
				setIsRemoteAvailable(false);
			});
	}, [productProtocolUrl]);

	const { mutate } = useMutation(
		"company",
		() =>
			companyActions.create({
				name: company?.name,
				description: company?.description,
				formatted_address: company?.formatted_address,
				address: company?.address,
				city: company?.city,
				state: company?.state,
				zip_code: company?.zip_code,
				country: hadChinaProjects ? "cn" : company?.country,
				phone: company?.phone,
				email: company?.email,
				contact_name: user?.first_name,
				contact_email: company?.contact_email,
				contact_title: company?.contact_title,
				historically_approved: new Date(), //hadHistoricalData,
				seen_historically_approved_message: !hadHistoricalData
					? null
					: new Date(),
				historically_amazon_certified: true,
			}),
		{
			retry: false,
			onSuccess: (res) => {
				getUser();
			},
			onError: (error: any) => {
				console.log(error);
				setIsLoading(false);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.company) {
			setIsLoading(false);
			navigate("/");
			props.handleClose();
		}
	}, [user, navigate, props]);

	const createCompany = () => {
		if (validate(company)) {
			setIsLoading(true);
			mutate();
		}
	};

	return (
		<Box
			sx={{
				width: "100%",
				position: "relative",
				height: "100%",
				overflowY: "hidden",
			}}
		>
			<Typography
				variant="h5"
				sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
			>
				Your Company
			</Typography>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				Please provide the following information about your company to begin
				registering your products for ClimeCo Certification.
			</Typography>
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					display: "flex",
					justifyContent: "center",
					marginBottom: 2,
				}}
			></Box>
			<FormControl sx={{ width: "100%" }}>
				<Box
					sx={{
						overflowY: "auto",
						overflowX: "hidden",
						height: "calc(100vh - 370px)",
						"@media (max-width: 600px)": {
							height: "calc(100vh - 390px)",
						},
					}}
				>
					<span className={classes.inputLabel}>
						Company Name <span className={classes.inputLabelRequired}>*</span>
					</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="name"
						placeholder="Name"
						value={company?.name}
						sx={{ marginBottom: 2 }}
						onChange={(e) => {
							setCompany(
								(prevState) =>
									({
										...prevState,
										name: e.target.value,
									} as ICompany)
							);
						}}
					/>
					<span className={classes.inputLabel}>
						Description <span className={classes.inputLabelRequired}>*</span>
					</span>
					<TextField
						InputLabelProps={{ shrink: false }}
						fullWidth
						label=""
						id="description"
						placeholder="Please include your industry and product types. For example - fashion, clothing and shoes."
						value={company?.description}
						sx={{ marginBottom: 2 }}
						onChange={(e) => {
							setCompany(
								(prevState) =>
									({
										...prevState,
										description: e.target.value,
									} as ICompany)
							);
						}}
					/>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6}>
							<span className={classes.inputLabel}>
								Email <span className={classes.inputLabelRequired}>*</span>
							</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="email"
								placeholder="Email"
								sx={{ marginBottom: 2 }}
								value={company?.email}
								onChange={(e) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												email: e.target.value,
											} as ICompany)
									);
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<span className={classes.inputLabel}>Phone</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="phone"
								placeholder="Phone"
								value={company?.phone}
								sx={{ marginBottom: 2 }}
								onChange={(e) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												phone: e.target.value,
											} as ICompany)
									);
								}}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<span className={classes.inputLabel}>
								Address <span className={classes.inputLabelRequired}>*</span>
							</span>

							<Autocomplete
								id="autocomplete-address"
								filterOptions={(options) => options}
								noOptionsText={
									isPlacePredictionsLoading ? "Loading..." : "No address found"
								}
								value={inputValue}
								options={placePredictions.map((place) => place.description)}
								sx={{ width: "100%", marginBottom: 2 }}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder="Enter address"
										variant="outlined"
										InputLabelProps={{ shrink: false }}
										value={inputValue}
										onChange={(e) => {
											const value = e.target.value;
											setInputValue(value);
											setCompany((prevState) => ({
												...prevState!,
												formatted_address: value,
											}));
											if (value.trim() !== "") {
												getPlacePredictions({ input: value });
											}
										}}
									/>
								)}
								onChange={(e, newValue) => {
									if (newValue) {
										setInputValue(newValue);

										setCompany((prevState) => ({
											...prevState!,
											formatted_address: newValue,
										}));
									}
								}}
							/>
						</Grid>
					</Grid>
					{/*<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label="Historical data choice"
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() => setHadHistoricalData(null)}
											color={hadHistoricalData === null ? "primary" : "inherit"}
											sx={{
												backgroundColor:
													hadHistoricalData === null
														? "primary.main"
														: "#fafafa",
												color:
													hadHistoricalData === null
														? "common.white"
														: "text.primary",
											}}
										>
											Yes
										</Button>
										<Button
											onClick={() => setHadHistoricalData(new Date())}
											color={hadHistoricalData ? "primary" : "inherit"}
											sx={{
												backgroundColor:
													hadHistoricalData !== null &&
													hadHistoricalData !== undefined
														? "primary.main"
														: "#fafafa",
												color:
													hadHistoricalData !== null &&
													hadHistoricalData !== undefined
														? "common.white"
														: "text.primary",
											}}
										>
											No
										</Button>
									</ButtonGroup>
								}
								label={
									"I had products certified with ClimeCo or Carbonfund.org in the past"
								}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
									},
								}}
							/>
						</Grid>
							</Grid>*/}

					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<FormControlLabel
								control={
									<ButtonGroup
										disableElevation
										variant="contained"
										aria-label="China Projects data choice"
										sx={{
											"& button:not(:last-of-type)": {
												borderColor: "#bdbdbd!important",
											},
										}}
									>
										<Button
											onClick={() => setHadChinaProjects(true)}
											color={hadChinaProjects ? "primary" : "inherit"}
											sx={{
												backgroundColor: hadChinaProjects
													? "primary.main"
													: "#fafafa",
												color: hadChinaProjects
													? "common.white"
													: "text.primary",
											}}
										>
											Yes
										</Button>
										<Button
											onClick={() => setHadChinaProjects(false)}
											sx={{
												backgroundColor:
													!hadChinaProjects && hadChinaProjects !== undefined
														? "primary.main"
														: "#fafafa",
												color:
													!hadChinaProjects && hadChinaProjects !== undefined
														? "common.white"
														: "text.primary",
												"&:hover": {
													backgroundColor:
														hadChinaProjects || hadChinaProjects === undefined
															? "#f5f5f5!important"
															: "primary.main",
												},
											}}
										>
											No
										</Button>
									</ButtonGroup>
								}
								label={"I request offsets only from China"}
								sx={{
									paddingLeft: 1.5,
									marginTop: 2,
									"@media (max-width: 600px)": {
										marginBottom: 2,
									},
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
										paddingLeft: 1,
									},
								}}
							/>
						</Grid>
					</Grid>

					{hadHistoricalData === null ||
						(hadHistoricalData !== undefined && (
							<Box
								sx={{
									marginBottom: 3,
									marginTop: 2,
								}}
							>
								<Alert severity="warning">
									<Typography variant="body2" sx={{ fontWeight: "bold" }}>
										Fees: You will be charged $1,000 as one time fee plus $250
										per product.
									</Typography>
								</Alert>
							</Box>
						))}
				</Box>
				<Box
					sx={{
						backgroundColor: "#fff",
						height: "120px",
						"@media (max-width: 600px)": {
							height: "150px",
						},
						borderTop: "1px solid #ccc",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							flexDirection: "row",
							"@media (max-width: 600px)": {
								flexDirection: "column",
								alignItems: "flex-start",
							},
						}}
					>
						<FormControlLabel
							value="end"
							control={
								<Checkbox
									size="small"
									checked={policyCheckbox}
									onChange={(e) => {
										setPolicyCheckbox(e.target.checked);
									}}
								/>
							}
							label="Please confirm that you have read our"
							labelPlacement="end"
							sx={{ marginRight: 0.5, span: { fontSize: 14 } }}
						/>
						<Box
							sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
						>
							<a
								style={{ fontSize: 14, color: "#25406D", marginRight: 4 }}
								href="https://products.climeco.com/termsofuse.pdf"
								rel="noreferrer"
								target="_blank"
							>
								<b>Terms of Use</b>
							</a>
							<span style={{ fontSize: 14, marginRight: 4 }}>and</span>
							<a
								style={{ fontSize: 14, color: "#25406D" }}
								href="https://products.climeco.com/privacypolicy.pdf"
								rel="noreferrer"
								target="_blank"
							>
								<b>Privacy Policy</b>
							</a>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							flexDirection: "row",
							"@media (max-width: 600px)": {
								flexDirection: "column",
								alignItems: "flex-start",
							},
						}}
					>
						<FormControlLabel
							value="end"
							control={
								<Checkbox
									size="small"
									checked={protocolCheckbox}
									onChange={(e) => {
										setProtocolCheckbox(e.target.checked);
									}}
								/>
							}
							label="Please confirm that you have read our"
							labelPlacement="end"
							sx={{ marginRight: 0.5, span: { fontSize: 14 } }}
						/>

						<a
							style={{ fontSize: 14, color: "#25406D" }}
							href={
								isRemoteAvailable ? productProtocolUrl : productProtocolAsset
							}
							rel="noreferrer"
							target="_blank"
							download={
								!isRemoteAvailable
									? "ClimeCo-Certified-Product-Protocol.pdf"
									: undefined
							}
						>
							<b>Product Certification Protocol</b>
						</a>
					</Box>
					<Button
						variant="contained"
						onClick={createCompany}
						disabled={
							!company?.name ||
							!company?.description ||
							!company?.email ||
							!company?.formatted_address ||
							hadChinaProjects === undefined ||
							(!policyCheckbox ? true : false) ||
							(!protocolCheckbox ? true : false) //||
							//hadHistoricalData === undefined
						}
						sx={{
							marginTop: 0,
							maxWidth: 300,
							alignSelf: "end",
							minWidth: 100,
							minHeight: 34,
						}}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : (
							"Save"
						)}
					</Button>
				</Box>
			</FormControl>
		</Box>
	);
}
