import { useState, useEffect, useContext } from "react";
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { PropTypes } from "./ExternalCertificationActionBar.types";
import { ICompany, IProduct } from "../../models";
import {
	company as companyActions,
	product as productActions,
} from "../../controllers";
import { BasicModal } from "../BasicModal";
import ChooseProvider from "../ChooseProvider/ChooseProvider";
import { UserContext } from "../../contexts/user";

const debounce = <F extends (...args: any[]) => void>(
	func: F,
	delay: number
): ((...args: Parameters<F>) => void) => {
	let timer: NodeJS.Timeout | null = null;
	return (...args: Parameters<F>): void => {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			func(...args);
		}, delay);
	};
};

type Provider = {
	id: number;
	name: string;
	certification_type: string;
};

export function ExternalCertificationActionBar({
	refetch,
	filters,
	setFilters,
}: PropTypes) {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");
	const [products, setProducts] = useState<Array<IProduct>>([]);
	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const debouncedSearchChange = debounce(handleSearchChange, 500);
	const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
		null
	);

	const [showProviderModal, setShowProviderModal] = useState(false);

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies",
		() => companyActions.list(companiesSearch, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	const { refetch: refetchProducts, isLoading: isLoadingProducts } = useQuery(
		"products-active-external",
		() =>
			productActions.list(
				user?.company.id,
				undefined,
				[],
				"ClimeCo certified",
				1,
				undefined,
				1000
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				const products = res.results.filter(
					(product) =>
						product.certification_processes?.find(
							(certificationProcess) =>
								certificationProcess.certification?.certification_type ===
									"CLIMECO_INTERNAL_CERTIFICATION" &&
								!certificationProcess.certification_process_steps.find(
									(certificationProcessStep) =>
										certificationProcessStep.status !== "COMPLETED"
								)
						) &&
						!product.certification_processes?.find(
							(certificationProcess) =>
								certificationProcess.certification?.certification_type ===
								"DEFAULT_EXTERNAL_CERTIFICATION"
						)
				);
				setProducts(products);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user) {
			refetchProducts();
		}
	}, [user, refetchProducts]);

	return (
		<>
			<Box sx={{ alignItems: "stretch", display: "flex", width: "100%" }}>
				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "stretch",
						justifyContent: "space-between",
						flexDirection: {
							xs: "column",
							sm: "column",
							md: "column",
							lg: "column-reverse",
						},
						gap: {
							xs: 2,
							sm: 2,
							md: 2,
							lg: 0,
						},
						marginTop: {
							xs: 2,
							sm: 2,
							md: 2,
							lg: 0,
						},
						width: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								md: "column",
								lg: "row",
							},
							flex: 1,
							alignItems: {
								xs: "flex-start",
								sm: "flex-start",
								md: "flex-start",
								lg: "flex-end",
							},
							justifyContent: "flex-start",
							marginTop: "9px",
							alignSelf: "flex-start",
							gap: 2,
							width: "100%",
						}}
					>
						{filters.companies && (
							<Autocomplete
								disablePortal
								id="company-box"
								options={companies}
								sx={{
									width: {
										xs: 240,
										sm: 240,
										md: 240,
										lg: 240,
										xl: 300,
									},
									"& .MuiInputBase-root": {
										padding: "0px 0px 0px 8px",
										overflow: "auto",
										maxHeight: "38px",
									},
								}}
								multiple
								getOptionLabel={(company) => company?.name ?? ""}
								/*value={filters.companies!.map((company) =>
								companies.find((auxCompany) => auxCompany.id === company)
							)}*/
								onChange={(_, value) => {
									setFilters((prev) => ({
										...prev,
										companies: value.map((company) => company!.id),
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder="Company"
										sx={{
											maxHeight: 56,
											overflow: "auto!important",
											border: "1px solid #ececec",
											borderRadius: 2,
											backgroundColor: "#fff",
											"& input": {
												fontSize: 14,
											},
										}}
										onChange={(ev) => {
											// dont fire API if the user delete or not entered anything
											if (ev.target.value !== "" || ev.target.value !== null) {
												debouncedSearchChange(ev.target.value);
											}
										}}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isLoadingCompanies ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flex: 1,
							alignItems: "center",
							justifyContent: "flex-end",
							flexDirection: {
								xs: "row",
								sm: "row",
								md: "row",
								lg: "row",
							},
							gap: {
								xs: 0.5,
								sm: 0.5,
								md: 0.5,
								lg: 0.5,
							},
							marginTop: {
								xs: 0,
								sm: 0,
								md: 0,
								lg: 0,
							},
							width: "100%",
						}}
					>
						<Button
							variant="contained"
							sx={{ minHeight: 34, minWidth: 120 }}
							onClick={() => {
								setShowProviderModal(true);
							}}
							disabled={isLoadingProducts || products.length === 0}
						>
							Create new
						</Button>
					</Box>
				</Box>
			</Box>
			<BasicModal
				width={600}
				showModal={showProviderModal}
				handleClose={() => {
					setShowProviderModal(false);
				}}
				children={
					<ChooseProvider
						selectedProvider={selectedProvider}
						setSelectedProvider={setSelectedProvider}
						mutate={() => {
							navigate("/certifications/create");
						}}
						providerTypes={["DEFAULT_EXTERNAL_CERTIFICATION"]}
					/>
				}
			/>
		</>
	);
}
