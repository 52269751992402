import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RiShieldStarFill } from "react-icons/ri";

export default function CertificationReview() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				width: "100%",
				textAlign: "center",
				padding: 4,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					marginBottom: 2,
					width: 180,
					height: 140,
					border: "1px solid #D6E9FF",
					borderRadius: 4,
					padding: 2,
					textAlign: "left",
				}}
			>
				<RiShieldStarFill
					style={{
						fontSize: 20,
						color: "#00A889",
						marginBottom: 2,
					}}
				/>
				<Typography variant="h6" sx={{ fontSize: 18 }}>
					ClimeCo Certification
				</Typography>
			</Box>
			<Typography
				variant="h5"
				sx={{
					fontWeight: "bold",
					marginBottom: 2,
					maxWidth: 500,
					fontSize: 22,
				}}
			>
				Your application for the ClimeCo <br />
				Certified Product Program has been submitted!
			</Typography>
			<Typography>
				A ClimeCo representative will review your application within 10 business
				days. If you're approved, you will then purchase the first-year product
				registration fees and offsets in your agreement to complete your
				certification.
			</Typography>

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: 2,
					marginTop: 4,
				}}
			>
				<Button
					variant="contained"
					sx={{
						minWidth: 180,
					}}
					onClick={() => {
						navigate("/");
					}}
				>
					Go to Dashboard
				</Button>
			</Box>
		</Box>
	);
}
