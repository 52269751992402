import { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Heading } from "../Heading";
import { ICertification, ICompany, IEstimation } from "../../models";
import DataTable from "../DataTable/DataTable";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useMutation, useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { Filters } from "../../routes/Routes.types";
import { useSearchParams } from "react-router-dom";

export function Documents() {
	const { enqueueSnackbar } = useSnackbar();

	const { user } = useContext(UserContext);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);
	const [search] = useState("");
	const [filters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [documentLoadingId, setDocumentLoadingId] = useState(-1);
	const [searchParams, setSearchParams] = useSearchParams();

	const { mutate: refetchLink, isLoading: isLoadingRefetch } = useMutation(
		"generate-doc-link",
		({
			certificationId,
			companyId,
		}: {
			certificationId: number;
			companyId: number;
		}) => certificationActions.generateLink(true, certificationId, companyId),
		{
			retry: false,
			onSuccess: (res) => {
				setDocumentLoadingId(-1);
				window.location.href = (res as any)["redirection_url"];
			},
			onError: (error: any) => {
				console.log(error);
				setDocumentLoadingId(-1);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"unsigned-climeco-certifications",
		() =>
			certificationActions.unsignedList(
				user?.is_superuser ? undefined : user?.company.id,
				search,
				filters.companies,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field === "estimations"
								? "estimations__product__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCertifications(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const columns = [
		{
			field: "id",
			headerName: "ID",
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{
			field: "estimations",
			headerName: "Products",
			sortable: false,
			flex: 1,
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<any>) => {
				if (params.value == null) {
					return "";
				}

				return (
					params.value &&
					params.value
						.map((estimation: IEstimation) => estimation.product?.name)
						.join(", ")
				);
			},
		},
		{
			field: "company",
			headerName: "Company",
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<ICompany>) => {
				if (params.value == null) {
					return "";
				}
				return params.value.name;
			},
			flex: 1,
		},
		{
			field: "customer_sign_date",
			headerName: "Customer Sign Date",
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				return (
					params.row.requested_historically_approved && (
						<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
							{params.row.requested_historically_approved &&
								Intl.DateTimeFormat("en", {
									day: "numeric",
									month: "numeric",
									year: "numeric",
								}).format(new Date(params.row.requested_historically_approved))}
						</Typography>
					)
				);
			},
		},
		{
			field: "action",
			headerName: "Actions",
			sortable: false,
			headerAlign: "right",
			align: "right",
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const onClickSign = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					if (user?.is_superuser) {
						setDocumentLoadingId(row.id as number);
						refetchLink({
							certificationId: row.row.id,
							companyId: row.row.company.id,
						});
					}
				};

				return (
					<>
						<Button
							sx={{ minHeight: 34 }}
							disabled={isLoadingRefetch}
							onClick={(e) => {
								setDocumentLoadingId(row.id as number);
								onClickSign(e);
							}}
						>
							{isLoadingRefetch && documentLoadingId === row.id ? (
								<CircularProgress size={12} sx={{ color: "#25406D" }} />
							) : (
								"Sign"
							)}
						</Button>
					</>
				);
			},
		},
	];

	if (!user?.is_superuser) {
		columns.splice(4, 1);
	}

	useEffect(() => {
		if (user) {
			if (searchParams.get("event") === "signing_complete") {
				enqueueSnackbar(
					"Signing successful. Reloading... (it might take a minute)",
					{
						variant: "success",
						autoHideDuration: 25000,
					}
				);
				searchParams.delete("event");
				setSearchParams(searchParams);
				setTimeout(() => {
					refetch();
				}, 25000);
			}
		}
	}, [
		searchParams,
		refetch,
		refetchLink,
		enqueueSnackbar,
		setSearchParams,
		user,
	]);

	return (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Heading title="Documents" showSearch={false} actions={() => <></>} />
			<DataTable
				rows={certifications}
				columns={columns}
				setSelectedRows={() => {}}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
				}}
			/>
		</Box>
	);
}
