import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Modal,
	Box,
	Button,
	Typography,
	IconButton,
	CircularProgress,
	Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import Logo from "../../assets/logo-vertical.png";
import { makeStyles } from "@mui/styles";
import GetCertifiedActions from "../GetCertifiedActions/GetCertifiedActions";
import { CreateLCAsTable } from "../CreateLCAsTable";
import {
	ICertification,
	IGetCertifiedStep,
	ILCA,
	ILCAVersion,
	IProduct,
} from "../../models";
import { ClimecoCertificationProcesss } from "../ClimecoCertificationProcesss";
import { PropTypes } from "./GetCertified.types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LCAListItem from "../LCAListItem/LCAListItem";
import { useQuery } from "react-query";
import { lca as lcaActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { BasicModal } from "../BasicModal";
import { keyframes } from "@mui/system";

const useStyles = makeStyles({
	logo: {
		height: "auto",
		width: 70,
		objectFit: "contain",
	},
});

const defaultLCA = {
	id: new Date().getTime(),
	status: "PENDING" as "PENDING",
	title: "",
	lca_document: undefined,
	certification_date: "",
	lca_provider: "",
	number_of_products: undefined,
	products: new Array<IProduct>(),
	product_category: undefined,
	lca_standards: undefined,
	certification_processes: new Array<ICertification>(),
	certification_process: undefined,
};

const steps = [
	{
		title: "Life Cycle Assessment (LCA)",
		description:
			"Are you importing a new Life Cycle Assessment or do you want to add to an existing LCA?",
		step: IGetCertifiedStep.CHOOSE_ACTION,
		size: "700px",
	},
	{
		title: "Add New Life Cycle Assessment (LCA)",
		description:
			"A Life Cycle Assessment assesses the environmental impacts associated with all stages of your product's life cycle.",
		step: IGetCertifiedStep.ADD_LCAS,
		size: "700px",
	},
	{
		title: "Add New Life Cycle Assessment (LCA)",
		description:
			"A Life Cycle Assessment assesses the environmental impacts associated with all stages of your product's life cycle.",
		step: IGetCertifiedStep.UPLOAD_LCAS,
		size: "700px",
	},
	{
		title: "These LCAs are in your application",
		description:
			"Would you like to add another LCA to your application? If you are done adding LCAs, click Next to confirm your offset agreement.",
		step: IGetCertifiedStep.LCA_LIST,
		size: "900px",
	},
	{
		title: "Add Products to Certify",
		description:
			"Add each product from your LCA(s) that you would like to certify and estimate their sales to determine the cost to offset each product.",
		step: IGetCertifiedStep.ADD_PRODUCTS,
		size: "900px",
	},
	{
		title: "Uploading your products...",
		description:
			"This may take a few seconds (a couple dozen products) or several minutes (lots of products). Please do not refresh or exit the page while uploading products.",
		step: IGetCertifiedStep.LOADING_PRODUCTS,
		size: "900px",
	},
	{
		title: "Confirm First Year Product Registration Fees and Offsets",
		description:
			"Please review the registration and offset fees below. ClimeCo will then review your application and you will be notified in 7-10 business days.",
		step: IGetCertifiedStep.CONFIRM_OFFSETS,
		size: "1100px",
	},
	{
		title: "Sign Offset Purchase Contract",
		description:
			"Electronically sign the agreement to submit your application.",
		step: IGetCertifiedStep.SIGN_AGREEMENT,
		size: "900px",
	},
	{
		title: "Purchase Offsets.",
		description:
			"To complete your certification, purchase your agreed offsets now. You will not be certified until you purchase your offsets.",
		step: IGetCertifiedStep.PURCHASE_OFFSETS,
		size: "900px",
	},
	{
		title: "Congratulations! Your products are now ClimeCo certified.",
		description:
			"This makes it a breeze to get certified by third party sustainability certifiers. Let’s keep the momentum going.",
		step: IGetCertifiedStep.COMPLETED,
		size: "900px",
	},
];

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

export default function GetCertified({ defaultOpen }: PropTypes) {
	const hasRun = useRef(0);
	const [open, setOpen] = useState(defaultOpen ?? false);
	const [LCAIndex, setLCAIndex] = useState(0);
	const [rows, setRows] = useState<Array<ILCAVersion>>([{ ...defaultLCA }]);
	const [certification, setCertification] = useState<ICertification | null>(
		null
	);
	const [nextButtonLoading, setNextButtonLoading] = useState(false);
	const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
	const [selectedOption, setSelectedOption] = useState("");
	const [selectedLCA, setSelectedLCA] = useState<ILCA | null>(null);
	const [showLCAInfo, setShowLCAInfo] = useState(false);
	const location = useLocation();
	const { id: LCAId } = useParams();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();

	const [showCloseModal, setShowCloseModal] = useState(false);

	const [isAnimating, setIsAnimating] = useState(false);
	const buttonRef = useRef(null);

	const { refetch } = useQuery(
		"lca",
		() =>
			lcaActions.getOne(
				parseInt(LCAId!),
				user?.is_superuser ? undefined : user?.company.id ?? 0
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.lca_versions) {
					res.lca_versions.forEach((lcaVersion) => {
						if (
							lcaVersion.lca_standards &&
							!Array.isArray(lcaVersion.lca_standards)
						) {
							lcaVersion.lca_standards = JSON.parse(lcaVersion.lca_standards);
						}
					});
				}
				setSelectedLCA({ ...res });
				setShowLCAInfo(true);
				console.log(showLCAInfo);
			},
			onError: (error: any) => {
				console.log(error);
				setSelectedLCA(null);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (location.pathname.includes("lca") && LCAId && !isNaN(parseInt(LCAId))) {
			refetch();
		} else {
			setShowLCAInfo(true);
		}
	}, [LCAId, location.pathname, refetch, setShowLCAInfo]);

	const getCurrentStepFromLocation = (
		path: string,
		certification: ICertification | null
	): number => {
		if (path.includes("/climeco-certificationss")) {
			if (certification) {
				let step = 0;
				const rejectedStep =
					certification.certification_process_steps.findIndex(
						(step) => step.status === "REJECTED"
					);

				const pendingStep = certification.certification_process_steps.findIndex(
					(step) => step.status === "PENDING"
				);

				step =
					rejectedStep > -1
						? rejectedStep
						: pendingStep > -1
						? pendingStep
						: certification.certification_process_steps.length - 1;

				switch (
					certification.certification_process_steps[step].step.step_type
				) {
					case "CERTIFICATION_SUMMARY":
						if (certification.estimations.find((e) => !e.amount)) {
							return 4;
						} else {
							return 6;
						}
					case "REDIRECTION":
						return 7;
					case "PURCHASE_OFFSETS":
						return 8;
					case "OTHER":
						return 9;
					default:
						return 4;
				}
			} else {
				return 4;
			}
		} else if (path.includes("/lcas/") && path.split("/").length > 2) {
			return 1;
		} else {
			return 0;
		}
	};
	const getCertificationProcessIdFromLocation = (path: string): number => {
		if (
			path.includes("/climeco-certificationss") &&
			path.split("/").length > 2 &&
			!isNaN(parseInt(path.split("/")[2]))
		) {
			return parseInt(path.split("/")[2]);
		} else {
			return -1;
		}
	};
	// To do: change it
	const [certificationProcessId, setCertificationProcessId] = useState(
		getCertificationProcessIdFromLocation(location.pathname)
	);
	const [currentStep, setCurrentStep] = useState(
		location.state?.initialStep &&
			steps.findIndex((step) => step.step === location.state?.initialStep) > -1
			? steps.findIndex((step) => step.step === location.state?.initialStep)!
			: getCurrentStepFromLocation(location.pathname, certification)
	);
	const navigate = useNavigate();
	const classes = useStyles();

	const handleCloseWithoutSaving = () => {
		setOpen(false);
		navigate("/");
		/*if (location.state?.notBack || window.history.length < 1) {
			navigate("/");
		} else {
			navigate(-1);
		}*/
	};
	const handleClose = () => {
		const step = steps[currentStep].step;

		if (
			step &&
			[
				IGetCertifiedStep.ADD_LCAS,
				IGetCertifiedStep.UPLOAD_LCAS,
				IGetCertifiedStep.LCA_LIST,
			].includes(step)
		) {
			setShowCloseModal(true);
		} else {
			handleCloseWithoutSaving();
		}
	};

	useEffect(() => {
		if (location.state?.initialStep && (hasRun.current ?? 0) < 2) {
			setCurrentStep(
				location.state?.initialStep &&
					steps.findIndex((step) => step.step === location.state?.initialStep) >
						-1
					? steps.findIndex(
							(step) => step.step === location.state?.initialStep
					  )!
					: getCurrentStepFromLocation(location.pathname, certification)
			);
		} else {
			setCurrentStep(
				getCurrentStepFromLocation(location.pathname, certification)
			);
		}

		hasRun.current = (hasRun.current ?? 0) + 1;

		setCertificationProcessId(
			getCertificationProcessIdFromLocation(location.pathname)
		);
		setNextButtonLoading(false);
	}, [location, certification]);

	const shouldNextButtonBeDisabled = (
		isLoading = false,
		isLoadingLCAVersion = false,
		loading = false
	) => {
		switch (steps[currentStep].step) {
			case IGetCertifiedStep.CHOOSE_ACTION:
				return !selectedOption;
			case IGetCertifiedStep.ADD_LCAS:
				const step = steps[currentStep].step;
				return rows.find((r, i) => {
					const isValid = (dateString: string): boolean => {
						return !isNaN(Date.parse(dateString));
					};

					return (
						(!r.title ||
							((!step || (step && step === IGetCertifiedStep.UPLOAD_LCAS)) &&
								!r.lca_document) ||
							!r.certification_date ||
							(r.certification_date &&
								(!isValid(r.certification_date) ||
									new Date(r.certification_date).getFullYear() < 2021)) ||
							!r.lca_provider ||
							!r.number_of_products ||
							!r.product_category ||
							!r.lca_standards) &&
						!(
							!r.title &&
							(!step || (step && step === IGetCertifiedStep.UPLOAD_LCAS)) &&
							!r.lca_document &&
							(!r.certification_date ||
								(r.certification_date &&
									(!isValid(r.certification_date) ||
										new Date(r.certification_date).getFullYear() < 2021))) &&
							!r.lca_provider &&
							!r.number_of_products &&
							!r.product_category &&
							!r.lca_standards &&
							i > 0
						)
					);
				}) ||
					isLoading ||
					isLoadingLCAVersion ||
					loading
					? true
					: false;

			case IGetCertifiedStep.UPLOAD_LCAS:
				return rows.find((r, i) => {
					return !r.lca_document && !(!r.lca_document && i > 0);
				}) ||
					isLoading ||
					isLoadingLCAVersion ||
					loading
					? true
					: false;
			case IGetCertifiedStep.ADD_PRODUCTS:
				return !nextButtonEnabled;
			case IGetCertifiedStep.LOADING_PRODUCTS:
			case IGetCertifiedStep.CONFIRM_OFFSETS:
			case IGetCertifiedStep.SIGN_AGREEMENT:
			case IGetCertifiedStep.PURCHASE_OFFSETS:
			case IGetCertifiedStep.COMPLETED:
				return true;
			default:
				return false;
		}
	};

	const nextButtonIsLoading = () => {
		return nextButtonLoading;
	};

	const shouldPreviousButtonBeDisabled = (
		isLoading = false,
		isLoadingLCAVersion = false,
		loading = false
	) => {
		switch (steps[currentStep].step) {
			case IGetCertifiedStep.UPLOAD_LCAS:
			case IGetCertifiedStep.LCA_LIST:
				return false;
			default:
				return true;
		}
	};

	const [createLCAs, setCreateLCAs] = useState(false);
	const [createProducts, setCreateProducts] = useState(false);

	const handleAddInput = () => {
		setRows([
			...rows,
			{
				id: new Date().getTime(),
				status: "PENDING" as "PENDING",
				title: "",
				lca_document: undefined,
				certification_date: "",
				lca_provider: "",
				number_of_products: undefined,
				products: new Array<IProduct>(),
				product_category: undefined,
				lca_standards: undefined,
				certification_processes: new Array<ICertification>(),
			},
		]);
	};

	const clickNextButton = () => {
		switch (steps[currentStep].step) {
			case IGetCertifiedStep.CHOOSE_ACTION:
				if (selectedOption === "existingLCA") {
					navigate("/lcas", { state: { fromExistingLCA: true } });
				} else {
					setCurrentStep(currentStep + 1);
				}
				break;
			case IGetCertifiedStep.UPLOAD_LCAS:
				if (selectedLCA) {
					setCreateLCAs(true);
				} else {
					setCurrentStep(currentStep + 1);
				}
				break;
			case IGetCertifiedStep.LCA_LIST:
				setCreateLCAs(true);
				break;
			case IGetCertifiedStep.ADD_PRODUCTS:
				setCreateProducts(true);
				setCurrentStep(currentStep + 1);
				break;
			case IGetCertifiedStep.CONFIRM_OFFSETS:
			case IGetCertifiedStep.SIGN_AGREEMENT:
			case IGetCertifiedStep.PURCHASE_OFFSETS:
				break;
			case IGetCertifiedStep.COMPLETED:
				navigate("/");
				break;
			default:
				setCurrentStep(currentStep + 1);
		}
	};

	const clickPreviousButton = () => {
		setCurrentStep(currentStep - 1);
	};

	useEffect(() => {
		const buttonElement = buttonRef.current;

		console.log(buttonElement);
		if (!buttonElement) return;

		const observer = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				if (mutation.attributeName === "disabled") {
					const isDisabled = (buttonElement as HTMLButtonElement).hasAttribute(
						"disabled"
					);
					if (!isDisabled) {
						setIsAnimating(true);
						setTimeout(() => setIsAnimating(false), 2000);
					}
				}
			}
		});

		observer.observe(buttonElement, { attributes: true });

		return () => {
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buttonRef.current]);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Box
					sx={{
						width: "100vw",
						height: "100vh",
						backgroundColor: "white",
						position: "relative",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							padding: "16px 24px",
							borderBottom: "1px solid #ccc",
							position: "absolute",
							top: 0,
						}}
					>
						<Box sx={{ display: "flex", gap: 2 }}>
							<img src={Logo} alt="climeco" className={classes.logo} />

							<Typography variant="body2" sx={{ fontSize: 16 }}>
								<span style={{ fontWeight: "bold" }}>Get Certified </span>|
								ClimeCo Certification
							</Typography>
						</Box>
						<IconButton sx={{ marginRight: "-14px" }} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Box>

					<Box
						sx={{
							display: "flex",
							gap: 4,
							padding: 2,
							paddingTop: 1,
							marginTop: "80px",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
							<HomeIcon
								fontSize="small"
								sx={{
									color:
										steps[currentStep].step ===
											IGetCertifiedStep.CHOOSE_ACTION ||
										steps[currentStep].step === IGetCertifiedStep.ADD_LCAS ||
										steps[currentStep].step === IGetCertifiedStep.UPLOAD_LCAS
											? "#34918E "
											: "#ABA9AD",
								}}
							/>
							<Typography
								variant="body2"
								color={
									steps[currentStep].step === IGetCertifiedStep.CHOOSE_ACTION ||
									steps[currentStep].step === IGetCertifiedStep.ADD_LCAS ||
									steps[currentStep].step === IGetCertifiedStep.UPLOAD_LCAS
										? "#ABA9AD"
										: "textSecondary"
								}
								sx={{
									color:
										steps[currentStep].step ===
											IGetCertifiedStep.CHOOSE_ACTION ||
										steps[currentStep].step === IGetCertifiedStep.ADD_LCAS ||
										steps[currentStep].step === IGetCertifiedStep.UPLOAD_LCAS
											? "#34918E"
											: "#ABA9AD",
								}}
							>
								Lifecycle Assessment
							</Typography>
						</Box>

						<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
							<HomeIcon
								fontSize="small"
								sx={{
									color:
										steps[currentStep].step ===
											IGetCertifiedStep.ADD_PRODUCTS ||
										steps[currentStep].step ===
											IGetCertifiedStep.LOADING_PRODUCTS
											? "#34918E"
											: "#ABA9AD",
								}}
							/>
							<Typography
								variant="body2"
								sx={{
									color:
										steps[currentStep].step ===
											IGetCertifiedStep.ADD_PRODUCTS ||
										steps[currentStep].step ===
											IGetCertifiedStep.LOADING_PRODUCTS
											? "#34918E"
											: "#ABA9AD",
								}}
							>
								Products
							</Typography>
						</Box>

						<Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
							<HomeIcon
								fontSize="small"
								sx={{
									color:
										steps[currentStep].step ===
											IGetCertifiedStep.CONFIRM_OFFSETS ||
										steps[currentStep].step ===
											IGetCertifiedStep.SIGN_AGREEMENT ||
										steps[currentStep].step ===
											IGetCertifiedStep.PURCHASE_OFFSETS ||
										steps[currentStep].step === IGetCertifiedStep.COMPLETED
											? "#34918E"
											: "#ABA9AD",
								}}
							/>
							<Typography
								variant="body2"
								sx={{
									color:
										steps[currentStep].step ===
											IGetCertifiedStep.CONFIRM_OFFSETS ||
										steps[currentStep].step ===
											IGetCertifiedStep.SIGN_AGREEMENT ||
										steps[currentStep].step ===
											IGetCertifiedStep.PURCHASE_OFFSETS ||
										steps[currentStep].step === IGetCertifiedStep.COMPLETED
											? "#34918E"
											: "#ABA9AD",
								}}
							>
								Commit to Offsets
							</Typography>
						</Box>
					</Box>

					<Box
						id="process-container"
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							maxHeight: "calc(100% - 250px)",
							overflow: "auto",
							alignItems: "flex-start",
							marginTop: "40px",
							position: "relative",
						}}
					>
						<Box
							sx={{
								width: { xs: "90%", sm: "80%", md: steps[currentStep].size },
								paddingBottom: "20px",
								maxWidth: "100%",
								backgroundColor: "white",
								borderRadius: "48px",
								border: "1px solid #B1D1F0",
								textAlign: "center",
								overflow: "hidden",
							}}
						>
							<Box
								sx={{
									textAlign: "center",
									borderBottom: "1px solid #B1D1F0",
									padding: 6,
									paddingBottom: 2,
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								<Typography variant="h5" fontWeight="bold">
									{steps[currentStep].title}
								</Typography>
								<Typography
									variant="body1"
									color="textSecondary"
									sx={{ marginTop: 1, maxWidth: "600px" }}
								>
									{steps[currentStep].description}
								</Typography>
								{steps[currentStep].step === "ADD_PRODUCTS" && (
									<Tooltip title="Download the template to fill out your product information">
										<Button
											variant="text"
											sx={{
												textTransform: "uppercase",
												fontSize: "12px",
												maxWidth: 200,
												display: "flex",
												alignItems: "center",
												marginTop: 2,
											}}
											onClick={() => {
												const link = document.createElement("a");
												link.href = `${process.env.PUBLIC_URL}/assets/Bulk Product Upload Template.xlsx`;
												link.download = "Bulk Product Upload Template.xlsx";
												document.body.appendChild(link);
												link.click();
												document.body.removeChild(link);
											}}
										>
											Download Template
										</Button>
									</Tooltip>
								)}
							</Box>

							<Box
								sx={{
									overflow: "auto",
									minHeight: "210px",
									//maxHeight: "calc(100vh - 468px)",
									position: "relative",
								}}
							>
								{steps[currentStep].step ===
									IGetCertifiedStep.CHOOSE_ACTION && (
									<GetCertifiedActions
										selectedOption={selectedOption}
										setSelectedOption={setSelectedOption}
									/>
								)}
								{(steps[currentStep].step === IGetCertifiedStep.ADD_LCAS ||
									steps[currentStep].step === IGetCertifiedStep.UPLOAD_LCAS ||
									steps[currentStep].step === IGetCertifiedStep.LCA_LIST) && (
									<CreateLCAsTable
										selectedLCA={selectedLCA}
										index={LCAIndex}
										step={steps[currentStep].step}
										setCurrentStep={setCurrentStep}
										rows={rows}
										setRows={setRows}
										defaultLCA={defaultLCA}
										setShowLCAInfo={setShowLCAInfo}
										setCertificationProcessId={setCertificationProcessId}
										createLCAs={createLCAs}
										setCreateLCAs={setCreateLCAs}
										setNextButtonLoading={setNextButtonLoading}
										visible={
											steps[currentStep].step !== IGetCertifiedStep.LCA_LIST
										}
									></CreateLCAsTable>
								)}

								{steps[currentStep].step === IGetCertifiedStep.LCA_LIST && (
									<Box
										sx={{
											padding: 4,
											display: "flex",
											flexDirection: "column",
											gap: 2,
											alignItems: "center",
										}}
									>
										{(rows ?? []).map((row, index) => {
											return (
												<LCAListItem
													key={row.id}
													lca={row}
													index={index}
													editCallback={() => {
														setCurrentStep((step) => step - 2);
														setLCAIndex(index);
													}}
													deleteCallback={() => {
														const newRows = [...rows];
														newRows.splice(index, 1);
														setRows([...newRows]);
													}}
												/>
											);
										})}

										<Button
											variant="outlined"
											onClick={() => {
												handleAddInput();
												setCurrentStep((step) => step - 2);
												setLCAIndex(rows.length);
											}}
										>
											Add another LCA
										</Button>
									</Box>
								)}

								{[
									IGetCertifiedStep.LOADING_PRODUCTS,
									IGetCertifiedStep.ADD_PRODUCTS,
									IGetCertifiedStep.CONFIRM_OFFSETS,
									IGetCertifiedStep.SIGN_AGREEMENT,
									IGetCertifiedStep.PURCHASE_OFFSETS,
									IGetCertifiedStep.COMPLETED,
								].find((step) => step === steps[currentStep].step) && (
									<ClimecoCertificationProcesss
										id={
											certificationProcessId > -1
												? certificationProcessId.toString()
												: ""
										}
										certification={certification}
										setCertification={setCertification}
										createProducts={createProducts}
										setCreateProducts={setCreateProducts}
										setNextButtonEnabled={setNextButtonEnabled}
										setParentCurrentStep={setCurrentStep}
									/>
								)}
							</Box>
						</Box>
						{certificationProcessId > -1 && !certification && (
							<Box
								sx={{
									position: "absolute",
									top: 0,
									left: 0,
									right: 0,
									bottom: 0,
									display: "flex",
									justifyContent: "center",
									alignItems: "flex-start",
									padding: 4,
									backgroundColor: "#ffffff",
									height: "100%",
								}}
							>
								<CircularProgress size={12} />
							</Box>
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							position: "absolute",
							bottom: 0,
							left: 0,
							right: 0,
							padding: 2,
							background: "#FFFFFF",
							borderTop: "1px solid #EAEBEB",
							fontSize: "16px",
						}}
					>
						<Button
							variant="outlined"
							disabled={shouldPreviousButtonBeDisabled()}
							onClick={clickPreviousButton}
							sx={{
								fontSize: "16px",
								paddingLeft: "26px",
								paddingRight: "26px",
							}}
						>
							Previous
						</Button>
						<Box>
							<Button
								variant="contained"
								disabled={shouldNextButtonBeDisabled()}
								onClick={clickNextButton}
								ref={buttonRef}
								sx={{
									position: "relative",
									color: nextButtonIsLoading() ? "transparent" : "white",
									fontSize: "16px",
									paddingLeft: "26px",
									paddingRight: "26px",
									animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
									animationIterationCount: 3,
								}}
							>
								{"Next"}
								{nextButtonIsLoading() && (
									<CircularProgress
										sx={{
											position: "absolute",
											top: "12px",
											left: "50%",
											marginLeft: "-6px",
											color: "#ffffff",
										}}
										size={12}
									/>
								)}
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
			<BasicModal
				width={800}
				showModal={showCloseModal}
				handleClose={() => {
					setShowCloseModal(false);
				}}
				children={
					<Box
						sx={{
							width: "100%",
							textAlign: "center",
							padding: 4,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h5"
							sx={{ fontWeight: "bold", marginBottom: 4, maxWidth: 500 }}
						>
							Are you sure you want to exit? Your LCA will not be saved
						</Typography>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								gap: 2,
							}}
						>
							<Button
								variant="outlined"
								sx={{
									minWidth: 180,
								}}
								onClick={() => {
									handleCloseWithoutSaving();
								}}
							>
								Exit without saving
							</Button>
							<Button
								variant="contained"
								sx={{
									minWidth: 180,
								}}
								onClick={() => {
									setShowCloseModal(false);
								}}
							>
								Cancel
							</Button>
						</Box>
					</Box>
				}
				hideClose
			/>
		</div>
	);
}
