import axios from "axios";
import { IToken } from "../../models";

class ConnectionsClient {
    accessTokenKey = "access_token";
    refreshTokenKey = "refresh_token";
    userIdKey = "user_id";
    parentAccessTokenKey = "parent_access_token";
    parentRefreshTokenKey = "parent_refresh_token";
    parentUserIdKey = "parent_user_id";
    token: IToken = {
        access: localStorage.getItem("access_token"),
        refresh: localStorage.getItem("refresh_token"),
        userId: localStorage.getItem("user_id"),
        parentAccess: localStorage.getItem("parent_access_token"),
        parentRefresh: localStorage.getItem("parent_refresh_token"),
        parentUserId: localStorage.getItem("parent_user_id"),
    };

    client = axios.create({
        baseURL:
            process.env.REACT_APP_API_URL,
        timeout: 60000 * 30, // 30 minutes for now until AZURE OPEN API embeddings problem is fixed
        headers: {
            "Content-type": "application/json",
        },
    });

    constructor() {
        this.client.interceptors.request.use((config) => {
            if (!(config.method?.toLowerCase() === "post" && config.url?.includes("user"))) {
                config.headers.Authorization = this.token.access
                    ? `Bearer ${this.token.access}`
                    : "";
            }

            const queryStringWithoutSlashIndex =
                config.url?.includes("?") && !config.url?.includes("/?");
            if (queryStringWithoutSlashIndex) {
                config.url?.replace("?", "/?");
            } else if (config.url && config.url[config.url.length - 1] !== "/") {
                config.url += "/";
            }

            return config;
        });

        this.client.interceptors.response.use(
            (response) => {
                return response;
            },
            async (error) => {
                console.log(error);
                const originalRequest = error.config;
                if (error.response?.status === 403 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    try {
                        await this.refreshAccessToken();
                        return this.client(originalRequest);
                    } catch (error) {
                        console.log(error);
                        if (window.location.href.replaceAll("/", "") !== window.location.origin.replaceAll("/", "")) {
                            this.updateTokens({ access: null, refresh: null, userId: null, parentAccess: null, parentRefresh: null, parentUserId: null, });
                            window.location.href = window.location.origin
                        }
                    }
                }

                return Promise.reject(error);
            }
        );

        setInterval(async () => {
            if (this.token && this.token.refresh) {
                try {
                    await this.refreshAccessToken();
                }
                catch (error) {
                    console.log(error)
                }
            }
        }, 60000)
    }

    updateTokens = (token: IToken) => {
        this.token = token;
        if (this.token.access && this.token.refresh) {
            localStorage.setItem(this.accessTokenKey, this.token.access);
            localStorage.setItem(this.refreshTokenKey, this.token.refresh);
            if (this.token.parentAccess && this.token.parentRefresh) {
                localStorage.setItem(this.parentAccessTokenKey, this.token.parentAccess);
                localStorage.setItem(this.parentRefreshTokenKey, this.token.parentRefresh);
            }
            else {
                localStorage.removeItem(this.parentAccessTokenKey);
                localStorage.removeItem(this.parentRefreshTokenKey);
                localStorage.removeItem(this.parentUserIdKey);
            }
        } else {
            localStorage.removeItem(this.accessTokenKey);
            localStorage.removeItem(this.refreshTokenKey);
            localStorage.removeItem(this.userIdKey);
            localStorage.removeItem(this.parentAccessTokenKey);
            localStorage.removeItem(this.parentRefreshTokenKey);
            localStorage.removeItem(this.parentUserIdKey);
        }
    };

    refreshAccessToken = async () => {
        const res = await this.client.post("token/refresh", {
            refresh: this.token.refresh,
        });
        const accessToken = res.data.access;
        this.updateTokens({ access: accessToken, refresh: this.token.refresh, userId: this.token.userId, parentAccess: this.token.parentAccess, parentRefresh: this.token.parentRefresh, parentUserId: this.token.parentUserId });
    };
}

export const connectionsClient = new ConnectionsClient();
