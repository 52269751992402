import { useState, useEffect, useContext } from "react";
import { Box, Chip, IconButton, Typography, Button } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useLocation, useNavigate } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { lca as lcaActions } from "../../controllers";
import { useSnackbar } from "notistack";
import {
	IEntity,
	IGetCertifiedStep,
	ILCA,
	ILCAVersion,
	IProduct,
} from "../../models";
import { UserContext } from "../../contexts/user";
import { Filters } from "../../routes/Routes.types";
import { BasicModal } from "../BasicModal";
import { LCAActionBar } from "../LCAActionBar";
import { AiOutlineEye as Visibility } from "react-icons/ai";
import { TiDocumentAdd as AddIcon } from "react-icons/ti";
import { TiEdit as EditIcon } from "react-icons/ti";

export function LCAs() {
	const { user } = useContext(UserContext);

	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const location = useLocation();
	const [showProductsModal, setShowProductsModal] = useState(false);
	const [showLCAsModal, setShowLCAsModal] = useState(
		location.state?.fromExistingLCA ? !location.state?.fromExistingLCA : true
	);

	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser ? { companies: [] } : {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);
	const [LCAs, setLCAs] = useState<Array<ILCA>>([]);

	const [rowCount, setRowCount] = useState<number>(0);

	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);
	const [selectedRow, setSelectedRow] = useState<ILCAVersion | null>(null);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"lcas",
		() =>
			lcaActions.list(
				!user?.is_superuser ? user!.company.id : undefined,
				search,
				paginationModel.page + 1,
				filters.companies,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCAs(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const columns = [
		{
			field: "id",
			headerName: "ID",
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
			minWidth: 40,
		},
		{
			field: "title",
			headerName: "Title",
			flex: 1,
			renderCell: (row: GridRenderCellParams<Array<string>, any>) => (
				<a
					href={`/lca/${row.id}`}
					onClick={(e) => {
						e.preventDefault();
						navigate(`/lcas/${row.id}`);
					}}
				>
					{row.value}
				</a>
			),
			minWidth: 150,
		},
		{
			field: "lca_versions",
			headerName: "Versions",
			width: 80,
			valueGetter: ({ value }: { value: Array<ILCAVersion> }) => value.length,
		},
		{
			field: "current_lca_version",
			headerName: "Current LCA Version",
			flex: 1,
			minWidth: 150,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.lca_versions;
			},
			renderCell: (row: GridRenderCellParams<Array<ILCAVersion>, any>) => (
				<Box
					sx={{ display: "flex", flexDirection: "column", maxWidth: "100%" }}
				>
					{row.value.length > 0 && row.value[0].lca_document && (
						<a href={row.value[0].lca_document} download>
							{row.value[0].lca_document?.split("/").pop()}
						</a>
					)}
					{row.value.length > 0 && row.value[0].certification_date
						? new Intl.DateTimeFormat("en", {
								day: "2-digit",
								month: "2-digit",
								year: "numeric",
						  }).format(new Date(row.value[0].certification_date))
						: "No versions yet"}
				</Box>
			),
		},
		{
			field: "lca_versions_products",
			headerName: "Products",
			flex: 1,
			minWidth: 150,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.lca_versions;
			},
			renderCell: (row: GridRenderCellParams<Array<ILCAVersion>, any>) =>
				row.value.length > 0 ? (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							maxHeight: 40,
							overflow: "auto",
							textAlign: "left",
							gap: 1,
						}}
					>
						<Typography
							variant="body2"
							sx={{ fontSize: 10, fontWeight: "bold" }}
						>
							{"TOTAL PRODUCTS: " + row.value[0].products.length}
						</Typography>
						{row.value[0].products.length > 0 && (
							<IconButton
								size={"small"}
								onClick={(e) => {
									e.stopPropagation();
									setSelectedRow(row.value[0]);
									setShowProductsModal(true);
								}}
							>
								<Visibility />
							</IconButton>
						)}
					</Box>
				) : (
					"No products yet"
				),
		},
		{
			field: "lca_versions_certification",
			headerName: "Certification Status",
			width: 180,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.lca_versions;
			},
			renderCell: (row: GridRenderCellParams<Array<ILCAVersion>, any>) =>
				row.value.length > 0 && row.value[0].certification_process ? (
					<Chip
						label={
							row.value[0] && row.value[0].status === "REJECTED"
								? "REJECTED"
								: row.value[0].certification_process.status
								? row.value[0].certification_process.status
								: "IN PROGRESS"
						}
						onClick={() =>
							navigate(
								"/climeco-certificationss/" +
									row.value[0].certification_process.id
							)
						}
						size={"small"}
						sx={{
							backgroundColor:
								row.value[0] && row.value[0].status === "REJECTED"
									? "EB4B4B"
									: row.value[0].certification_process.status
									? "#6ab04c"
									: "#f9ca24",
							color: row.value[0].certification_process.status
								? "#fff"
								: "#000",
							fontWeight: "bold",
							fontSize: 10,
							textTransform: "uppercase",

							"&:hover": {
								backgroundColor:
									row.value[0] && row.value[0].status === "REJECTED"
										? "EB4B4B"
										: row.value[0].certification_process.status
										? "#6ab04c"
										: "#f9ca24",
							},
						}}
					/>
				) : (
					"No certification yet"
				),
		},
	];

	return (
		<>
			<Box>
				<Heading
					title="Life Cycle Assessment"
					subtitle=""
					showSearch
					actions={() => (
						<LCAActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
						/>
					)}
					setSearch={setSearch}
				/>
				<DataTable
					rows={LCAs}
					columns={columns}
					setSelectedRows={setSelectedRows}
					rowCount={rowCount}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
					sortModel={sortModel}
					setSortModel={setSortModel}
					isLoading={isLoading || isRefetching || isFetching}
					sx={{
						border: "1px solid #E3E3E3",
						borderRadius: "28px",
						"& .MuiDataGrid-root": {
							border: "none",
						},
						"& .MuiDataGrid-columnHeaders": {},
						"& .MuiDataGrid-footerContainer": {
							borderTop: "none!important",
						},
					}}
				/>
			</Box>

			<BasicModal
				width={600}
				showModal={showProductsModal}
				handleClose={() => {
					setSelectedRow(null);
					setShowProductsModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							List of products in the LCA:
						</Typography>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								gap: 1,
							}}
						>
							{selectedRow?.products.map((p: IProduct) => (
								<a
									href={`/product/${p.id}`}
									onClick={(e) => {
										e.preventDefault();
										navigate(`/product/${p.id}`);
									}}
									style={{ minHeight: 18, width: "100%", direction: "ltr" }}
								>
									{p.name}
								</a>
							))}
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showLCAsModal}
				handleClose={() => {
					setShowLCAsModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h5"
							sx={{ fontWeight: 700, textAlign: "center", marginBottom: 2 }}
						>
							Life Cycle Assessment (LCA)
						</Typography>

						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								gap: 1,
							}}
						>
							Are you importing a new Life Cycle Assessment or do you want to
							add to an existing LCA?
						</Box>

						<Box
							sx={{
								display: "flex",
								gap: 3,
								padding: 6,
								paddingBottom: "20px",
								justifyContent: LCAs && LCAs.length > 0 ? "start" : "center",
							}}
						>
							<Button
								variant="outlined"
								onClick={() => {
									navigate("/lca", {
										state: {
											initialStep: IGetCertifiedStep.ADD_LCAS,
										},
									});
								}}
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "start",
									padding: 3,
									borderRadius: "16px",
									paddingTop: 6,
									border: "none!important",
									outline: "1px solid #B1D1F0!important",
									flex: 1,
									"&:hover": {
										outline: "2px solid #34918E!important",
										backgroundColor: "transparent",
										border: "none!important",
									},
									maxWidth: 300,
								}}
							>
								<AddIcon style={{ fontSize: 24, color: "#000" }} />
								<Typography
									variant="body2"
									sx={{
										marginTop: 1,
										fontWeight: "bold",
										color: "#000",
										textTransform: "none",
										textAlign: "left",
									}}
								>
									I want to add a new LCA
								</Typography>
							</Button>

							<Button
								variant="outlined"
								onClick={() => {
									setShowLCAsModal(false);
								}}
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "start",
									padding: 2,
									borderRadius: "16px",
									paddingTop: 6,
									border: "none!important",
									outline: "1px solid #B1D1F0!important",
									flex: 1,
									"&:hover": {
										outline: "2px solid #34918E!important",
										backgroundColor: "transparent",
										border: "none!important",
									},
								}}
							>
								<EditIcon style={{ fontSize: 24, color: "#000" }} />
								<Typography
									variant="body2"
									sx={{
										marginTop: 1,
										fontWeight: "bold",
										color: "#000",
										textTransform: "none",
									}}
								>
									I want to update an existing LCA
								</Typography>
							</Button>
						</Box>
					</Box>
				}
			/>
		</>
	);
}
