import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	TextField,
	Typography,
	Grid,
	Autocomplete,
	Divider,
	IconButton,
	Table,
	TableHead,
	TableCell,
	TableRow,
	FormControlLabel,
	Switch,
	TableContainer,
	TableBody,
	Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Heading } from "../Heading";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	company as companyActions,
	offset as offsetActions,
} from "../../controllers";
import { useSnackbar } from "notistack";
import {
	IBalance,
	ICertificationFiles,
	ICompany,
	ILCA,
	IOffset,
} from "../../models";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
	sale as saleActions,
	certification as certificationActions,
	lca as lcaActions,
} from "../../controllers";
import { AiFillDelete as DeleteIcon } from "react-icons/ai";
import { NumericFormat } from "react-number-format";
import { BasicModal } from "../BasicModal";
import { ContractDocuments } from "../ContractDocuments";
import { ClimecoCertifications } from "../ClimecoCertifications";
import { Certifications } from "../Certifications";
import { CompanyAnalytics } from "../CompanyAnalytics";
import InfoIcon from "@mui/icons-material/Info";
import { UserContext } from "../../contexts/user";

const useStyles = makeStyles({
	inputLabel: {
		display: "flex",
		marginTop: 8,
		marginBottom: "14px",
		fontSize: 14,
		fontWeight: 700,
	},
});

export function CompanyDetail() {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const [company, setCompany] = useState<ICompany | null>({
		id: 0,
		name: "",
		description: "",
		formatted_address: "",
	});
	const [balance, setBalance] = useState<IBalance | null>(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showContractDocumentsModal, setShowContractDocumentsModal] =
		useState(false);
	const [offsets, setOffsets] = useState<number | string>("");
	const [offsetsDescription, setOffsetsDescription] = useState<string>("");
	const [offsetsPrice, setOffsetsPrice] = useState<number | string>("");
	const [companyOffsets, setCompanyOffsets] = useState<Array<IOffset> | null>(
		null
	);
	const [contractDocuments, setContractDocuments] = useState<
		Array<ICertificationFiles>
	>(Array<ICertificationFiles>());
	const [LCADocuments, setLCADocuments] = useState<Array<ILCA>>(Array<ILCA>());

	const [inputValue, setInputValue] = useState(
		company?.formatted_address || ""
	);

	const [editing, setEditing] = useState(!id);

	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
		usePlacesService({
			apiKey: "AIzaSyBeHWqS_4Rm9ZBIqbOtQ5PwGJIUYsaHxD0",
			options: {
				/*componentRestrictions: {
				country: ["us", "ca"],
			},*/
				types: ["street_address", "route"],
			},
			language: "en",
		});

	const { refetch } = useQuery(
		"companies",
		() => companyActions.getOne(parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompany(res);
				setInputValue(res.formatted_address ?? "");
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		() => saleActions.getBalance([], parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setBalance(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchDocuments } = useQuery(
		"documents",
		() =>
			certificationActions.getDocuments({
				isClimeCo: true,
				id: -1,
				companyId: parseInt(id!),
			}),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setContractDocuments(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchLCADocuments } = useQuery(
		"company-lcas",
		() => lcaActions.list(parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setLCADocuments(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: refetchCompanyOffsets } = useQuery(
		"company-offsets",
		() => offsetActions.companyList(parseInt(id!)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanyOffsets(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { isLoading, mutate } = useMutation(
		"companies",
		() =>
			id
				? companyActions.update({
						id: parseInt(id),
						name: company?.name,
						description: company?.description,
						address: company?.address,
						formatted_address: company?.formatted_address,
						city: company?.city,
						state: company?.state,
						zip_code: company?.zip_code,
						country: company?.country,
						contact_name: company?.contact_name,
						email: company?.email,
						phone: company?.phone,
						historically_approved: company?.historically_approved,
						total_estimations: company?.total_estimations,
						historically_amazon_certified:
							company?.historically_amazon_certified,
				  })
				: companyActions.create({
						name: company?.name,
						description: company?.description,
						address: company?.address,
						formatted_address: company?.formatted_address,
						city: company?.city,
						state: company?.state,
						zip_code: company?.zip_code,
						country: company?.country,
						contact_name: company?.contact_name,
						email: company?.email,
						phone: company?.phone,
						historically_approved: company?.historically_approved,
						total_estimations: company?.total_estimations,
						historically_amazon_certified:
							company?.historically_amazon_certified,
						is_parent_company: user?.company.is_parent_company,
				  }),
		{
			retry: false,
			onSuccess: (res) => {
				if (id) {
					enqueueSnackbar("Changes saved", {
						variant: "success",
					});
					setEditing(false);
				} else {
					navigate("/companies");
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);
	useEffect(() => {
		if (id) {
			refetch();
			refetchBalance();
			refetchDocuments();
			refetchLCADocuments();
			refetchCompanyOffsets();
		}
	}, [
		id,
		refetch,
		refetchBalance,
		refetchDocuments,
		refetchLCADocuments,
		refetchCompanyOffsets,
	]);

	const { isLoading: isLoadingMutateAddOffsets, mutate: mutateAddOffsets } =
		useMutation(
			"certification-add-offsets",
			() =>
				certificationActions.addOffsets({
					isClimeCo: true,
					id: -1,
					companyId: parseInt(id!),
					quantity: parseFloat(offsets!.toString()),
					description: offsetsDescription,
					amount:
						typeof offsetsPrice === "string"
							? parseFloat(offsets!.toString()) * 12.5
							: parseFloat(offsets!.toString()) * offsetsPrice,
				}),
			{
				retry: false,
				onSuccess: (res) => {
					setOffsets("");
					setOffsetsDescription("");
					setOffsetsPrice("");
					refetchBalance();
					refetchCompanyOffsets();
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				},
			}
		);

	return (
		<Box>
			{/*<Typography
				variant="body2"
				fontSize={10}
				marginBottom={2}
				sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
				onClick={() => {
					navigate("/companies");
				}}
			>
				{"BACK"}
			</Typography>*/}
			{id && user?.is_superuser && (
				<Box sx={{ marginTop: 2, marginBottom: 6 }}>
					<CompanyAnalytics
						title={(company && company.name) ?? ""}
						companyId={id ? parseInt(id) : undefined}
					/>
				</Box>
			)}
			<Heading
				title={id ? "Edit Company" : "Create Company"}
				showSearch={false}
				noMarginBottom
			/>
			<FormControl sx={{ width: "100%" }}>
				<span className={classes.inputLabel}>Title</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					id="title"
					value={company?.name}
					placeholder="Title of the company"
					onChange={(e) => {
						setCompany(
							(prevState) =>
								({
									...prevState,
									name: e.target.value,
								} as ICompany)
						);
					}}
					disabled={!editing}
				/>
				<span
					className={classes.inputLabel}
					style={{ display: "block", marginTop: "24px" }}
				>
					Description
				</span>
				<TextField
					InputLabelProps={{ shrink: false }}
					fullWidth
					label=""
					//multilines
					rows={3}
					id="description"
					value={company?.description}
					placeholder="Description of the company"
					onChange={(e) => {
						setCompany(
							(prevState) =>
								({
									...prevState,
									description: e.target.value,
								} as ICompany)
						);
					}}
					disabled={!editing}
				/>
				<Grid container spacing={2} sx={{ marginTop: 0 }}>
					<Grid item xs={12} sm={6} md={6}>
						<span className={classes.inputLabel}>Email</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							id="email"
							placeholder="Email"
							sx={{ marginBottom: 2 }}
							value={company?.email}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											email: e.target.value,
										} as ICompany)
								);
							}}
							disabled={!editing}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<span className={classes.inputLabel}>Phone</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							id="phone"
							placeholder="Phone"
							value={company?.phone}
							sx={{ marginBottom: 2 }}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											phone: e.target.value,
										} as ICompany)
								);
							}}
							disabled={!editing}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={6}>
						<span className={classes.inputLabel}>Address</span>
						{company && (
							<Autocomplete
								id="autocomplete-address"
								value={inputValue}
								noOptionsText={
									isPlacePredictionsLoading ? "Loading..." : "No address found"
								}
								options={placePredictions.map((place) => place.description)}
								sx={{ width: "100%", marginBottom: 2, height: 45 }}
								renderInput={(params) => (
									<TextField
										{...params}
										value={inputValue}
										placeholder="Enter address"
										variant="outlined"
										InputLabelProps={{ shrink: false }}
										onChange={(e) => {
											const value = e.target.value;
											setInputValue(value);
											setCompany((prevState) => ({
												...prevState!,
												formatted_address: value,
											}));
											if (value.trim() !== "") {
												getPlacePredictions({ input: value });
											}
										}}
									/>
								)}
								onChange={(e, newValue) => {
									if (newValue) {
										setInputValue(newValue);

										setCompany((prevState) => ({
											...prevState!,
											formatted_address: newValue,
										}));
									}
								}}
								disabled={!editing}
							/>
						)}
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<span className={classes.inputLabel}>Contact Name</span>
						<TextField
							InputLabelProps={{ shrink: false }}
							fullWidth
							label=""
							id="contact_name"
							placeholder="Contact Name"
							value={company?.contact_name}
							sx={{ marginBottom: 2 }}
							onChange={(e) => {
								setCompany(
									(prevState) =>
										({
											...prevState,
											contact_name: e.target.value,
										} as ICompany)
								);
							}}
							disabled={!editing}
						/>
					</Grid>
				</Grid>

				{user?.is_superuser && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<span className={classes.inputLabel}>Total estimations</span>
							<NumericFormat
								sx={{ marginBottom: 2, width: "100%" }}
								value={company?.total_estimations}
								name={"amount"}
								placeholder="Total Estimations"
								customInput={TextField}
								type="text"
								allowNegative={false}
								decimalScale={0}
								thousandSeparator={","}
								onValueChange={({ value: v }) => {
									setCompany(
										(prevState) =>
											({
												...prevState,
												total_estimations: parseInt(v),
											} as ICompany)
									);
								}}
								disabled={!editing}
							/>
						</Grid>
					</Grid>
				)}
				{user?.is_superuser && (
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12}>
							<FormControlLabel
								control={
									<Switch
										checked={
											company?.historically_amazon_certified ? true : false
										}
										onChange={(e) => {
											setCompany(
												(prevState) =>
													({
														...prevState,
														historically_amazon_certified: e.target.checked
															? true
															: false,
													} as ICompany)
											);
										}}
										name="amazon-historical-data"
										disabled={!editing}
									/>
								}
								label="Amazon certified"
								sx={{
									"& .MuiFormControlLabel-label": {
										fontSize: "14px",
									},
								}}
							/>
						</Grid>
					</Grid>
				)}
				<Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
					{editing && user?.is_superuser && (
						<Button
							variant="outlined"
							onClick={() => {
								setEditing(false);
							}}
							sx={{
								marginTop: 2,
								minHeight: 34,
								maxWidth: 300,
								alignSelf: "end",
							}}
						>
							Cancel
						</Button>
					)}
					<Button
						variant="contained"
						onClick={() => {
							if (id && !editing) {
								setEditing(true);
							} else {
								mutate();
							}
						}}
						sx={{
							marginTop: 2,
							minHeight: 34,
							maxWidth: 300,
							alignSelf: "end",
						}}
						disabled={
							!company?.name ||
							!company?.description ||
							!company.email ||
							!company.formatted_address ||
							!company.contact_name
						}
					>
						{isLoading ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : id ? (
							editing ? (
								"Save"
							) : (
								"Edit"
							)
						) : (
							"Create"
						)}
					</Button>
				</Box>
			</FormControl>
			{id && (
				<>
					<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
					<Box
						sx={{
							display: "flex",
							marginBottom: 2,
							flexDirection: "column",
						}}
					>
						<Typography variant="h4" sx={{ fontWeight: 600, marginBottom: 4 }}>
							Certifications
						</Typography>
						<ClimecoCertifications companyId={parseInt(id!)} />
					</Box>
					<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
					<Box
						sx={{
							display: "flex",
							marginBottom: 2,
							flexDirection: "column",
						}}
					>
						<Typography variant="h4" sx={{ fontWeight: 600, marginBottom: 4 }}>
							External Certifications
						</Typography>
						<Certifications companyId={parseInt(id!)} />
					</Box>

					<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
					<Typography variant="h4" sx={{ fontWeight: 600, marginBottom: 2 }}>
						Offsets Balance
					</Typography>
					<Box
						sx={{
							padding: 2,
							background: "#efefef",
							display: "flex",
							gap: 8,
							justifyContent: "flex-end",
							borderRadius: 1,
							overflowX: "auto",
							flexDirection: {
								xs: "column",
								sm: "row",
							},
							textAlign: {
								xs: "right",
								sm: "left",
							},
							marginBottom: 1,
						}}
					>
						<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Forecasted Emissions{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
								<Tooltip
									sx={{ padding: 0 }}
									title={"Product emissions based on forecasted sales"}
								>
									<IconButton sx={{ height: 12 }}>
										<InfoIcon sx={{ fontSize: 14 }} />
									</IconButton>
								</Tooltip>
							</Typography>
							<Typography fontSize={14}>
								{balance?.estimated_amount
									? Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
									  }).format(Math.ceil(balance.estimated_amount))
									: "--"}
							</Typography>
						</Box>
						<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Actual Emissions{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
								<Tooltip
									sx={{ padding: 0 }}
									title={"Offsets used based on actual sales"}
								>
									<IconButton sx={{ height: 12 }}>
										<InfoIcon sx={{ fontSize: 14 }} />
									</IconButton>
								</Tooltip>
							</Typography>
							<Typography fontSize={14}>
								{balance?.sales_amount
									? Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
									  }).format(Math.ceil(balance.sales_amount))
									: "--"}
							</Typography>
						</Box>

						{/*<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Retired Emissions{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
							</Typography>
							<Typography fontSize={14}>
								{balance?.purchased_offsets
									? Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
									  }).format(Math.ceil(balance.purchased_offsets))
									: "--"}
							</Typography>
						</Box>
						<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Difference{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
							</Typography>
							<Typography fontSize={14}>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
								}).format(
									(() => {
										const num =
											(balance?.purchased_offsets ?? 0) -
											(balance?.sales_amount ?? 0) -
											(balance?.estimated_amount ?? 0);
										return Math.sign(num) * Math.ceil(Math.abs(num));
									})()
								)}
							</Typography>
						</Box>*/}
						<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Purchased Offsets{" "}
								<span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
								<Tooltip
									sx={{ padding: 0 }}
									title={"Total offsets you have purchased"}
								>
									<IconButton sx={{ height: 12 }}>
										<InfoIcon sx={{ fontSize: 14 }} />
									</IconButton>
								</Tooltip>
							</Typography>
							<Typography fontSize={14}>
								{balance?.offsets_amount
									? Intl.NumberFormat("en", {
											maximumFractionDigits: 0,
									  }).format(
											Math.ceil(
												balance.offsets_amount + balance.purchased_offsets
											)
									  )
									: "--"}
							</Typography>
						</Box>
						<Box sx={{ textAlign: "center" }}>
							<Typography fontWeight={"bold"} fontSize={14}>
								Balance <span style={{ fontWeight: "normal" }}>(mtCO2e)</span>
								<Tooltip
									sx={{ padding: 0 }}
									title={
										"Purchased offsets minus your forecasted and actual offsets"
									}
								>
									<IconButton sx={{ height: 12 }}>
										<InfoIcon sx={{ fontSize: 14 }} />
									</IconButton>
								</Tooltip>
							</Typography>
							<Typography
								fontSize={14}
								sx={{
									color:
										Math.ceil(
											(balance?.purchased_offsets ?? 0) -
												(balance?.sales_amount ?? 0) +
												(balance?.offsets_amount ?? 0) -
												(balance?.estimated_amount ?? 0)
										) >= -0
											? "inherit"
											: "red",
								}}
							>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
								}).format(
									(() => {
										const num =
											Math.sign(
												(balance?.purchased_offsets ?? 0) -
													(balance?.sales_amount ?? 0) -
													(balance?.estimated_amount ?? 0)
											) *
												Math.ceil(
													Math.abs(
														(balance?.purchased_offsets ?? 0) -
															(balance?.sales_amount ?? 0) -
															(balance?.estimated_amount ?? 0)
													)
												) +
											Math.ceil(balance?.offsets_amount ?? 0);
										return Math.ceil(num) === -0
											? 0
											: Math.sign(num) * Math.ceil(Math.abs(num));
									})()
								)}
							</Typography>
						</Box>
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							marginTop: 2,
							gap: 2,
							maxWidth: 600,
							margin: "auto",
						}}
					>
						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>Offsets</span>
							<NumericFormat
								value={offsets}
								placeholder="Amount of offsets to add"
								customInput={TextField}
								onValueChange={({ value }) => {
									setOffsets(parseInt(value));
								}}
								style={{}}
								allowNegative={false}
								decimalScale={0}
								thousandSeparator={","}
							/>
						</Box>
						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>Description</span>
							<TextField
								InputLabelProps={{ shrink: false }}
								fullWidth
								label=""
								id="description"
								value={offsetsDescription}
								placeholder="Description"
								onChange={(e) => {
									setOffsetsDescription(e.target.value);
								}}
							/>
						</Box>
						<Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
							<span className={classes.inputLabel}>Price per Offset</span>
							<NumericFormat
								value={offsetsPrice}
								placeholder="Price per Offset"
								customInput={TextField}
								onValueChange={({ value }) => {
									setOffsetsPrice(parseFloat(value));
								}}
								style={{}}
								allowNegative={false}
								decimalScale={2}
								thousandSeparator={","}
							/>
						</Box>
						<Button
							variant="outlined"
							sx={{
								maxWidth: 300,
								alignSelf: "end",
								minHeight: 34,
							}}
							disabled={isLoadingMutateAddOffsets || !offsets || !id}
							onClick={() => {
								mutateAddOffsets();
							}}
						>
							{isLoadingMutateAddOffsets ? (
								<CircularProgress size={12} />
							) : (
								"Add offsets"
							)}
						</Button>
					</Box>
					<Box sx={{ marginTop: 2 }}>
						<span style={{ marginBottom: 8 }} className={classes.inputLabel}>
							All company offsets
						</span>
						<br />
						{companyOffsets && companyOffsets.length > 0 ? (
							<Table sx={{ marginBottom: 2 }}>
								<TableHead>
									<TableCell>Name</TableCell>
									<TableCell>Description</TableCell>
									<TableCell>Quantity</TableCell>
									<TableCell>Payment</TableCell>
									<TableCell>Date</TableCell>
									<TableCell>Added by</TableCell>
								</TableHead>
								{companyOffsets?.map((offset) => (
									<TableRow key={offset.id}>
										<TableCell>{offset.name}</TableCell>
										<TableCell sx={{ maxWidth: 200 }}>
											{offset.description}
										</TableCell>
										<TableCell>
											{(() => {
												const formattedValue = Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
												}).format(offset.value);

												if (offset.name === "Products Offsets") {
													return `${formattedValue} product(s)`;
												} else if (offset.name === "First Time Offsets") {
													return `${formattedValue} unit`;
												} else {
													return `${formattedValue} mtCO2`;
												}
											})()}
										</TableCell>
										<TableCell>
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												style: "currency",
												currency: "USD",
											}).format(offset.amount)}
										</TableCell>
										<TableCell>
											{new Intl.DateTimeFormat("en", {
												day: "2-digit",
												month: "2-digit",
												year: "numeric",
											}).format(new Date(offset.updated_at))}
										</TableCell>
										<TableCell>{offset.admin?.email ?? "--"}</TableCell>
									</TableRow>
								))}
							</Table>
						) : companyOffsets && companyOffsets.length === 0 ? (
							<Typography variant="body2">No offsets yet</Typography>
						) : null}
					</Box>
					<Divider
						sx={{ marginTop: 4, marginBottom: 4, borderColor: "#fff" }}
					/>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: 2,
						}}
					>
						<Typography variant="h4" sx={{ fontWeight: 600 }}>
							Contract Documents
						</Typography>
						<Button
							variant="outlined"
							sx={{
								maxWidth: 300,
								alignSelf: "end",
								marginRight: 2,
							}}
							onClick={() => {
								setShowContractDocumentsModal(true);
							}}
						>
							Add new document
						</Button>
					</Box>

					{contractDocuments && contractDocuments.length > 0 ? (
						<TableContainer>
							<Table sx={{ border: "none", borderBottom: "none" }} size="small">
								<TableBody>
									{contractDocuments.map((document, index) => (
										<TableRow key={document.id}>
											<TableCell sx={{ borderBottom: "none" }}>
												{document.name ? (
													<a
														target="_blank"
														href={
															document.file
																? (document.file as string)
																: document.image
																? (document.image as string)
																: "#"
														}
														rel="noreferrer"
													>
														<Typography variant="body2">
															{document.name}
														</Typography>
													</a>
												) : (
													<a
														href={
															document.file
																? (document.file as string)
																: document.image
																? (document.image as string)
																: "#"
														}
														style={{
															textOverflow: "ellipsis",
															overflow: "hidden",
														}}
														download
													>
														<Typography variant="body2">
															{document.file
																? (document.file as string).substring(
																		(document.file as string).length - 20
																  )
																: document.image
																? (document.image as string).substring(
																		(document.image as string).length - 20
																  )
																: "Empty document (TO BE DELETED)"}
															...
														</Typography>
													</a>
												)}
											</TableCell>
											<TableCell align="center" sx={{ borderBottom: "none" }}>
												<Typography variant="body2" sx={{ textAlign: "left" }}>
													{document.certification_process?.name ?? "--"}
												</Typography>
											</TableCell>
											<TableCell align="center" sx={{ borderBottom: "none" }}>
												<IconButton
													aria-label="delete document"
													onClick={() => {
														setShowDeleteModal(true);
													}}
													sx={{ fontSize: 16 }}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					) : (
						<Typography variant="body2">No contract documents yet</Typography>
					)}

					<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: 2,
						}}
					>
						<Typography variant="h4" sx={{ fontWeight: 600 }}>
							LCA Documents
						</Typography>
					</Box>

					{LCADocuments && LCADocuments.length > 0 ? (
						LCADocuments.map((document) => (
							<Box key={document.id}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
										maxWidth: 1000,
									}}
								>
									<a
										href={
											document.lca_versions &&
											document.lca_versions.length > 0 &&
											(document.lca_versions[0].lca_document as string) !== ""
												? (document.lca_versions[0].lca_document as string)
												: "-"
										}
										onClick={(e) => {
											if (
												!(document?.lca_versions &&
												document.lca_versions.length > 0 &&
												(document.lca_versions[0].lca_document as string) !== ""
													? false
													: true)
											) {
												e.preventDefault();
											}
										}}
										style={{ textOverflow: "ellipsis", overflow: "hidden" }}
										download
									>
										<Typography variant="body2">
											{document.lca_versions && document.lca_versions.length > 0
												? (document.lca_versions[0].lca_document as string)
												: "Empty document (TO BE DELETED)"}
										</Typography>
									</a>
									<IconButton
										aria-label="delete document"
										onClick={() => {
											setShowDeleteModal(true);
										}}
										sx={{ fontSize: 16 }}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							</Box>
						))
					) : (
						<Typography variant="body2">No contract documents yet</Typography>
					)}
					<Divider sx={{ marginTop: 4, marginBottom: 4 }} />
				</>
			)}
			<BasicModal
				width={600}
				showModal={showDeleteModal}
				handleClose={() => {
					setShowDeleteModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="body2"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Are you sure you want to delete this document? (Disabled for now)
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowDeleteModal(false);
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={true}
								onClick={() => {}}
							>
								Delete
							</Button>
						</Box>
					</Box>
				}
			/>
			<BasicModal
				width={600}
				showModal={showContractDocumentsModal}
				handleClose={() => {
					setShowContractDocumentsModal(false);
				}}
				children={
					<ContractDocuments
						companyId={parseInt(id ?? "-1")}
						closeModal={() => {
							setShowContractDocumentsModal(false);
							refetchDocuments();
						}}
					/>
				}
			/>
		</Box>
	);
}
