import { useContext } from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { HiMenuAlt3 as MenuIcon } from "react-icons/hi";
import Background from "../../assets/header-background.png";
import { PropTypes } from "./TopBar.types";
import { UserContext } from "../../contexts/user";
import { connectionsClient } from "../../utils/ConnectionsClient";

export function TopBar(props: PropTypes) {
	const { handleDrawerToggle, mobileOpen, drawerWidth } = props;
	const { user } = useContext(UserContext);
	return (
		<AppBar
			position="fixed"
			elevation={0}
			sx={{
				width: { xs: `calc(100% - ${mobileOpen ? drawerWidth : 0}px)` },
				ml: { xs: `${mobileOpen ? drawerWidth : 0}px` },
				backgroundColor: "#003899",
				alignItems: { md: "flex-end", xs: "space-between" },
				backgroundImage: `url(${Background})`,
				backgroundSize: "contain",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "-40px 20px",
			}}
		>
			<Toolbar
				sx={{
					width: { sx: "calc(100% - 32px)", md: "100%" },
					height: 120,
					"& button": {
						background: "white",
					},
				}}
			>
				<IconButton
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{
						fontSize: 18,
						mr: 2,
						visibility: { xs: mobileOpen ? "hidden" : "visible" },
					}}
				>
					<MenuIcon />
				</IconButton>
				<Typography
					variant="h4"
					sx={{
						fontSize: 32,
						fontWeight: "bold",
					}}
				>
					{connectionsClient.token.parentAccess
						? `You are acting as "${user?.company.name ?? ""}"`
						: user?.first_name
						? "Welcome, " + user?.first_name
						: "Welcome back!"}
				</Typography>
			</Toolbar>
		</AppBar>
	);
}
