import {
	Typography,
	Box,
	Button,
	CircularProgress,
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
} from "@mui/material";
import { PropTypes } from "./CertificationAgree.types";

export function CertificationAgree({
	text,
	button1,
	button1Callback,
	button2,
	button2Callback,
	isLoading,
	certification,
	historicalCertification,
	subscriptions,
	annualFeeToPay,
	lcaFeeToPay,
	lcaFeeToPayMultiplier,
	revisedLCAFeeToPay,
	revisedLCAFeeToPayMultiplier,
	rejectionsLCAFeeToPay,
	balance,
}: PropTypes) {
	return (
		<Box sx={{ width: "100%" }}>
			<Typography variant="body2" sx={{ textAlign: "center", marginBottom: 2 }}>
				{text}
			</Typography>

			{certification && (
				<Box
					sx={{
						backgroundColor: "#FAFDFF",
						padding: 4,
						minHeight: "256px",
						display: "flex",
						flexDirection: "column",
						borderTop: "1px solid #D7EBFF",
						borderBottom: "1px solid #D7EBFF",
					}}
				>
					<Box
						sx={{
							minHeight: "256px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								flexDirection: {
									xs: "column",
									sm: "row",
								},
							}}
						>
							<Typography>Price of offsets to be purchased:</Typography>
							<Typography>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2,
									style: "currency",
									currency: "usd",
								}).format(12.5)}{" "}
								x{" "}
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
									minimumFractionDigits: 0,
								}).format(
									Math.ceil(
										certification?.estimations.reduce((prev, current) => {
											return (
												prev +
												Math.ceil(
													(current.product_lca_version_emission?.emissions ??
														0) *
														(!isNaN(current.amount ?? 0) && current.amount
															? current.amount
															: 0) *
														0.001
												)
											);
										}, 0) ?? 0
									)
								)}{" "}
								={" "}
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2,
									style: "currency",
									currency: "usd",
								}).format(
									Math.ceil(
										certification?.estimations.reduce((prev, current) => {
											return (
												prev +
												Math.ceil(
													(current.product_lca_version_emission?.emissions ??
														0) *
														(!isNaN(current.amount ?? 0) && current.amount
															? current.amount
															: 0) *
														0.001
												)
											);
										}, 0) ?? 0
									) * 12.5
								)}
							</Typography>
						</Box>

						{Math.ceil(
							certification?.estimations.reduce((prev, current) => {
								return (
									prev +
									Math.ceil(
										(current.product_lca_version_emission?.emissions ?? 0) *
											(!isNaN(current.amount ?? 0) && current.amount
												? current.amount
												: 0) *
											0.001
									)
								);
							}, 0) ?? 0
						) *
							12.5 +
							(annualFeeToPay ?? 0) +
							(lcaFeeToPay ?? 0) +
							(revisedLCAFeeToPay ?? 0) /*-
							(balance && (balance ?? 0) > 0 ? balance : 0)*/ <
							5000 &&
							(!certification.company.first_time_fee_paid ||
								(certification.company.first_time_fee_paid &&
									new Date(
										certification.company.first_time_fee_paid
									).getTime() <
										new Date().getTime() - 365 * 24 * 60 * 60 * 1000)) && (
								<>
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											marginTop: 1,
										}}
									>
										<Typography
											sx={{
												maxWidth: 320,
												color: "#EB4B4B",
											}}
										>
											Price of additional offsets you will be purchasing to
											reach minimum:
										</Typography>
										<Typography
											sx={{
												color: "#EB4B4B",
												textAlign: "right",
											}}
										>
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
												style: "currency",
												currency: "usd",
											}).format(12.5)}{" "}
											x{" "}
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(
												(5000 -
													(Math.ceil(
														certification?.estimations.reduce(
															(prev, current) => {
																return (
																	prev +
																	Math.ceil(
																		(current.product_lca_version_emission
																			?.emissions ?? 0) *
																			(!isNaN(current.amount ?? 0) &&
																			current.amount
																				? current.amount
																				: 0) *
																			0.001
																	)
																);
															},
															0
														) ?? 0
													) *
														12.5 +
														(annualFeeToPay ?? 0) +
														(lcaFeeToPay ?? 0) +
														(revisedLCAFeeToPay ?? 0) /*+
														(rejectionsLCAFeeToPay ?? 0)*/ +
														0)) /*-
												(balance && (balance ?? 0) > 0 ? balance : 0)*/ /
													12.5
											)}
											{" = "}
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 2,
												minimumFractionDigits: 2,
												style: "currency",
												currency: "usd",
											}).format(
												5000 -
													(Math.ceil(
														certification?.estimations.reduce(
															(prev, current) => {
																return (
																	prev +
																	Math.ceil(
																		(current.product_lca_version_emission
																			?.emissions ?? 0) *
																			(!isNaN(current.amount ?? 0) &&
																			current.amount
																				? current.amount
																				: 0) *
																			0.001
																	)
																);
															},
															0
														) ?? 0
													) *
														12.5 +
														(annualFeeToPay ?? 0) +
														(lcaFeeToPay ?? 0) +
														(revisedLCAFeeToPay ?? 0) +
														/*(rejectionsLCAFeeToPay ?? 0)*/ +0) /*-
												(balance && (balance ?? 0) > 0 ? balance : 0)*/
											)}
										</Typography>
									</Box>
									<Typography
										sx={{
											fontStyle: "italic",
											marginBottom: 2,
										}}
									>
										<small>
											*Additional offsets will be applied to a company's balance
											sheet which they can leverage in the future.
										</small>
									</Typography>
								</>
							)}

						{(annualFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{
										subscriptions?.find(
											(s) => s.subscription_type === "ANNUAL_FEE"
										)!.title
									}
									:
								</Typography>
								<Typography>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(annualFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}

						{(lcaFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{
										subscriptions?.find(
											(s) => s.subscription_type === "LCA_FEE"
										)!.title
									}
									:
								</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(
										(lcaFeeToPay ?? 0) / (lcaFeeToPayMultiplier ?? 1)
									)}{" "}
									x{" "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 0,
										minimumFractionDigits: 0,
									}).format(lcaFeeToPayMultiplier ?? 0)}
									{" = "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(lcaFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}
						{(revisedLCAFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>
									{
										subscriptions?.find(
											(s) => s.subscription_type === "UPDATED_LCA_FEE"
										)!.title
									}
									:
								</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(
										(revisedLCAFeeToPay ?? 0) /
											(revisedLCAFeeToPayMultiplier ?? 1)
									)}{" "}
									x{" "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 0,
										minimumFractionDigits: 0,
									}).format(revisedLCAFeeToPayMultiplier ?? 0)}
									{" = "}
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(revisedLCAFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}
						{(rejectionsLCAFeeToPay ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>LCA rejection fee:</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(rejectionsLCAFeeToPay ?? 0)}
								</Typography>
							</Box>
						)}
						{false && (balance ?? 0) > 0 && (
							<Box
								sx={{
									display: "flex",
									marginTop: 1,
									justifyContent: "space-between",
									flexDirection: {
										xs: "column",
										sm: "row",
									},
								}}
							>
								<Typography>Current Balance:</Typography>
								<Typography textAlign={"right"}>
									{Intl.NumberFormat("en", {
										maximumFractionDigits: 2,
										minimumFractionDigits: 2,
										style: "currency",
										currency: "usd",
									}).format(0 /*balance ? -balance : 0*/)}
								</Typography>
							</Box>
						)}
						<Box
							sx={{
								display: "flex",
								marginTop: 4,
								padding: 0,
								justifyContent: "space-between",
							}}
						>
							<Typography sx={{ fontWeight: 500 }}>Total Price:</Typography>
							<Typography sx={{ fontWeight: 500, color: "#34918E" }}>
								{Intl.NumberFormat("en", {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2,
									style: "currency",
									currency: "usd",
								}).format(
									(!certification.company.first_time_fee_paid ||
										(certification.company.first_time_fee_paid &&
											new Date(
												certification.company.first_time_fee_paid
											).getTime() <
												new Date().getTime() - 365 * 24 * 60 * 60 * 1000)) &&
										Math.ceil(
											certification?.estimations.reduce((prev, current) => {
												return (
													prev +
													Math.ceil(
														(current.product_lca_version_emission?.emissions ??
															0) *
															(!isNaN(current.amount ?? 0) && current.amount
																? current.amount
																: 0) *
															0.001
													)
												);
											}, 0) ?? 0
										) *
											12.5 +
											(annualFeeToPay ?? 0) +
											(lcaFeeToPay ?? 0) +
											(revisedLCAFeeToPay ?? 0) /*-
										(balance && (balance ?? 0) > 0 ? balance : 0)*/ <
											5000
										? 5000 + (rejectionsLCAFeeToPay ?? 0)
										: Math.ceil(
												certification?.estimations.reduce((prev, current) => {
													return (
														prev +
														Math.ceil(
															(current.product_lca_version_emission
																?.emissions ?? 0) *
																(!isNaN(current.amount ?? 0) && current.amount
																	? current.amount
																	: 0) *
																0.001
														)
													);
												}, 0) ?? 0
										  ) *
												12.5 +
												(annualFeeToPay ?? 0) +
												(lcaFeeToPay ?? 0) +
												(revisedLCAFeeToPay ?? 0) +
												(rejectionsLCAFeeToPay ?? 0) /*-
												(balance && (balance ?? 0) > 0 ? balance : 0)*/
								)}
							</Typography>
						</Box>
					</Box>
				</Box>
			)}
			{historicalCertification && (
				<Box
					sx={{
						padding: 4,
						display: "flex",
						flexDirection: "column",
					}}
				>
					<TableContainer>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Product - ASIN</TableCell>
									<TableCell align="right">Total Historical Sales</TableCell>
									<TableCell align="right">Total Estimated Sales</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{historicalCertification.estimations.map((estimation) => (
									<TableRow
										key={estimation.product.name + estimation.product.asin}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell component="th" scope="row">
											{estimation.product.name +
												" - " +
												estimation.product.asin}
										</TableCell>
										<TableCell align="right">
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(
												estimation.product.sales?.reduce(
													(prev, current) => prev + current.amount,
													0
												) ?? 0
											)}
										</TableCell>
										<TableCell align="right">
											{Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
												minimumFractionDigits: 0,
											}).format(estimation.amount ?? 0)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Button
					variant="outlined"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						marginRight: 2,
					}}
					onClick={button1Callback}
					disabled={isLoading}
				>
					{button1}
				</Button>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						maxWidth: 300,
						alignSelf: "end",
						minHeight: 34,
					}}
					disabled={isLoading}
					onClick={button2Callback}
				>
					{isLoading ? (
						<CircularProgress size={12} style={{ color: "white" }} />
					) : (
						button2
					)}
				</Button>
			</Box>
		</Box>
	);
}
