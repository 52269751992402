import { PropTypes } from "./Updates.types";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	notification as notificationActions,
	product as productActions,
} from "../../controllers";
import { useSnackbar } from "notistack";
import ReactHtmlParser from "react-html-parser";

export function Updates({
	notification,
	callback,
	productsLength,
	hideCheckbox,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [selectedProductId, setSelectedProductId] = useState<number>(0);
	const { enqueueSnackbar } = useSnackbar();

	const { mutate, isLoading } = useMutation(
		"notifications-viewed",
		() => notificationActions.toggleViewed(notification.id),
		{
			retry: false,
			onSuccess: (res) => {
				const index = notification.users.findIndex((u) => u.id === user?.id);
				if (index > -1) {
					notification.users.splice(index, 1);
				} else {
					notification.users.push(user!);
				}
				if (callback) {
					callback(notification.id);
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong", { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingFetchProduct } = useQuery(
		["products-detail-list", selectedProductId],
		() => productActions.getOne(selectedProductId, user!.company.id),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				navigate("/climeco-certifications/create", {
					state: {
						products: [res],
					},
				});
			},
		}
	);

	return (
		<Box
			sx={{
				marginBottom: 1,
				display: "flex",
				flexDirection: "row",
				flex: 1,
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					border: "1px solid #D6E9FF",
					borderRadius: "12px",
					flexDirection: "row",
					flexBasis: "calc(50% - 16px)",
					flexGrow: 1,
					padding: 2,
					maxWidth: "100%",
					marginBottom: 2,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<IconButton
						disabled={isLoading || hideCheckbox}
						onClick={(e) => {
							e.stopPropagation();
							mutate();
						}}
						sx={{
							"&.Mui-disabled": {
								opacity: 0.3,
							},
						}}
					>
						<BsCheckCircle
							color={
								notification.users.find(
									(u) => user && user.id && u.id === user.id
								)
									? "#6ab04c"
									: "#000"
							}
							style={{
								cursor: "pointer",
								color: notification.users.find(
									(u) => user && user.id && u.id === user.id
								)
									? "#6ab04c"
									: "#000",
							}}
							size={18}
						/>
					</IconButton>
					<Typography
						variant="body2"
						sx={{
							fontSize: 14,
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{ReactHtmlParser(notification.title)}
					</Typography>
				</Box>
				<Button
					sx={{
						maxHeight: 34,
						width: 210,
						minWidth: 210,
					}}
					variant="outlined"
					disabled={
						(productsLength === 0 &&
							notification.notification_type === "CERTIFY_PRODUCT") ||
						isLoadingFetchProduct
					}
					onClick={() => {
						if (notification.notification_type === "ADD_LCA") {
							navigate("/lca/");
						} else if (
							notification.notification_type === "REJECTED_LCA" &&
							notification?.lca_version?.lca_id
						) {
							navigate("/lcas/" + notification.lca_version?.lca_id);
						} else if (
							notification.notification_type === "AMAZON_CERTIFICATION"
						) {
							navigate(
								"/certifications/" + notification.certification_process_id
							);
						} else if (
							notification.notification_type === "HISTORICALLY_APPROVED"
						) {
							navigate("/bulk-products/");
						} else if (
							notification.notification_type === "HISTORICAL_DATA_SENT" ||
							notification.notification_type === "REQUEST_DECERTIFYING_PRODUCT"
						) {
							navigate("/products/");
						} else if (
							notification.notification_type === "START_EXTERNAL_CERTIFICATION"
						) {
							navigate("/certifications/");
						} else if (
							notification.notification_type === "REQUEST_HISTORICAL_DATA"
						) {
							navigate("/company/" + notification.extra_company!.id);
						} else if (
							notification.notification_type === "REJECTED_PRODUCT_ADMIN" ||
							notification.notification_type === "REMINDER_SUBMIT_PRODUCTS" ||
							notification.notification_type ===
								"REMINDER_SUBMIT_PRODUCTS_DEADLINE" ||
							notification.notification_type === "CARBON_FOOTPRINT_UPDATED" ||
							notification.notification_type === "RECEIVED_PAID_INVOICE"
						) {
							navigate(
								"/climeco-certificationss/" +
									notification.certification_process_id
							);
						} else if (notification.notification_type === "CERTIFY_PRODUCT") {
							setSelectedProductId(notification.product_id!);
						} else if (notification.product_id) {
						} else if (notification.certification_process_id) {
							if (notification.notification_type === "SIGN_CONTRACT") {
								navigate("/documents/");
							} else {
								navigate(
									"/climeco-certificationss/" +
										notification.certification_process_id
								);
							}
						} else if (notification.sale_id) {
							navigate("/sales/");
						} else if (notification.notification_type === "CREATE_PRODUCT") {
							navigate("/bulk-products/");
						}
					}}
				>
					{isLoadingFetchProduct ? (
						<CircularProgress size={12} />
					) : (
						notification.button_title ?? "Go"
					)}
				</Button>
			</Box>
		</Box>
	);
}
